import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Package, AlertTriangle, ShoppingCart, Search, Plus, Loader2, ArrowRight } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    limit,
    getDocs,
    getFirestore,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import TimeBasedSalesChart from '../components/sales_chart';
import TimePeriodPicker from '../components/time_period_picker';

const SalesDashboard = () => {
    const navigate = useNavigate();
    const [salesData, setSalesData] = useState({
        totalSales: 0,
        salesCount: 0,
        recentSales: [],
        dailyData: [],
        isLoading: true,
        error: null
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTimeLabel, setSelectedTimeLabel] = useState('Today');
    const [dateRange, setDateRange] = useState(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return {
            startDate: today,
            endDate: new Date()
        };
    });
    const [user, setUser] = useState(null);
    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setSalesData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to authenticate user'
                }));
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db]);

    const handleDateRangeChange = (start, end, label) => {
        setDateRange({
            startDate: start,
            endDate: end
        });
        setSelectedTimeLabel(label);
    };

    useEffect(() => {
        const fetchSalesData = async () => {
            if (!user?.storeId) return;

            try {
                const salesQuery = query(
                    collection(db, 'Stores', user.storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(dateRange.startDate)),
                    where('saleDate', '<=', Timestamp.fromDate(dateRange.endDate)),
                    orderBy('saleDate', 'desc'),
                    limit(100)
                );

                const salesSnapshot = await getDocs(salesQuery);
                const sales = [];
                let totalRevenue = 0;
                const dailyTotals = {};

                salesSnapshot.forEach(doc => {
                    const sale = {
                        id: doc.id,
                        ...doc.data(),
                        saleDate: doc.data().saleDate.toDate()
                    };

                    const saleTotal = sale.items.reduce((sum, item) =>
                        sum + (item.price * item.quantity), 0);

                    totalRevenue += saleTotal;
                    sale.totalAmount = saleTotal;

                    const dateKey = sale.saleDate.toISOString().split('T')[0];
                    dailyTotals[dateKey] = (dailyTotals[dateKey] || 0) + saleTotal;

                    sales.push(sale);
                });

                const chartData = Object.entries(dailyTotals)
                    .map(([date, total]) => ({
                        date,
                        amount: total
                    }))
                    .sort((a, b) => new Date(a.date) - new Date(b.date));

                setSalesData({
                    totalSales: totalRevenue,
                    salesCount: sales.length,
                    recentSales: sales,
                    dailyData: chartData,
                    isLoading: false,
                    error: null
                });
            } catch (error) {
                console.error('Error fetching sales data:', error);
                setSalesData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load sales data'
                }));
            }
        };

        fetchSalesData();
    }, [user, db, dateRange]);

    const filteredSales = salesData.recentSales
        .filter(sale => sale.id.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(0, 10);

    const handleAddSale = () => {
        navigate('/sales/add');
    };

    if (salesData.isLoading) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1">
                        <div className="flex flex-col items-center gap-3">
                            <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                            <div className="text-lg">Loading sales data...</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (salesData.error) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1 text-red-500">
                        <AlertTriangle className="mr-2 h-5 w-5" />
                        <div>{salesData.error}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center my-10">
                        <h1 className="text-5xl font-semibold">Sales</h1>
                        <div className="relative flex items-center space-x-2">
                            <TimePeriodPicker
                                onDateRangeChange={handleDateRangeChange}
                                selectedLabel={selectedTimeLabel}
                                onLabelChange={setSelectedTimeLabel}
                            />

                            <button
                                onClick={handleAddSale}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                            >
                                <Plus className="h-5 w-5 mr-2" />
                                Add New Sale
                            </button>
                        </div>
                    </div>

                    <div className="mb-8">
                        <SalesStats salesData={salesData} timeLabel={selectedTimeLabel} />
                    </div>

                    {/* Sales Chart */}
                    <TimeBasedSalesChart sales={salesData.recentSales} dateRange={dateRange} />

                    <div className="mt-8 mb-4 flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Recent Sales</h2>
                        <div className="flex space-x-2">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search sales..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                    <Search className="h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border p-5 pb-0">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Order No.</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Date</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Amount</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Items</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {filteredSales.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                                                No sales found for date range
                                            </td>
                                        </tr>
                                    ) : (
                                        filteredSales.map((sale) => (
                                            <tr key={sale.id} className="hover:bg-gray-50">
                                                <td className="px-6 py-4 text-sm text-gray-900">
                                                    {sale.orderNumber || `ORD${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {sale.saleDate.toLocaleString()}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    <span className="text-xs mr-1">GHS</span>
                                                    <span className="text-gray-900">{sale.totalAmount.toFixed(2)}</span>
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {sale.items.length} items
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="px-6 py-4 border-t">
                            <Link
                                to="/sales/list"
                                className="flex items-center justify-center text-blue-600 hover:text-blue-800 font-medium"
                            >
                                See Full List
                                <ArrowRight className="ml-2 h-4 w-4" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StatCard = ({ title, value, subtitle, icon }) => (
    <div className="bg-white border p-4 w-full">
        <div className="flex items-center justify-between mb-1">
            <h3 className="text-sm text-gray-500">{title}</h3>
            {icon && <div className="text-gray-400">{icon}</div>}
        </div>
        <p className="text-2xl font-bold mb-1 flex items-baseline">{value}</p>
        {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
    </div>
);

const SalesStats = ({ salesData, timeLabel }) => (
    <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <StatCard
                title="Total Sales"
                value={<>
                    <span className="mr-1">GHS</span>
                    <span>{salesData.totalSales.toFixed(2)}</span>
                </>}
                subtitle="Revenue"
                icon={<Package className="h-6 w-6" />}
            />
            <StatCard
                title={`Sales (${timeLabel})`}
                value={salesData.salesCount}
                subtitle="Number of transactions"
                icon={<ShoppingCart className="h-6 w-6" />}
            />
            <StatCard
                title={`Items Sold (${timeLabel})`}
                value={salesData.recentSales.reduce((sum, sale) =>
                    sum + sale.items.reduce((itemSum, item) => itemSum + item.quantity, 0), 0
                )}
                subtitle="Total items"
                icon={<Package className="h-6 w-6" />}
            />
        </div>
    </div>
);

export default SalesDashboard;