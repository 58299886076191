import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import { Loader2, AlertTriangle, Calendar } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import TopProductsSection from '../components/top_products';
import SalesChartsContainer from '../components/sales_quantity_chart';

const Reports = () => {
    const [selectedTab, setSelectedTab] = useState('Overview');
    const [timeRange, setTimeRange] = useState('7days');
    const [user, setUser] = useState(null);
    const [reportData, setReportData] = useState({
        isLoading: true,
        error: null,
        allData: {
            sales: [],
            expenses: []
        }
    });

    const db = getFirestore();
    const auth = getAuth();

    // Fetch user data
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setReportData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to authenticate user'
                }));
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    // Fetch 90 days of data
    useEffect(() => {
        const fetchReportData = async () => {
            if (!user?.storeId) return;

            try {
                const timeFilter = new Date();
                timeFilter.setDate(timeFilter.getDate() - 90);

                // Sales and expenses queries for 90 days
                const salesQuery = query(
                    collection(db, 'Stores', user.storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(timeFilter)),
                    orderBy('saleDate', 'desc')
                );

                const expensesQuery = query(
                    collection(db, 'Stores', user.storeId, 'Expenses'),
                    where('date', '>=', Timestamp.fromDate(timeFilter))
                );

                // Execute queries
                const [salesSnapshot, expensesSnapshot] = await Promise.all([
                    getDocs(salesQuery),
                    getDocs(expensesQuery)
                ]);

                // Store raw data
                const sales = salesSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));

                const expenses = expensesSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));

                setReportData({
                    isLoading: false,
                    error: null,
                    allData: {
                        sales,
                        expenses
                    }
                });

            } catch (error) {
                console.error('Error fetching report data:', error);
                setReportData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load report data'
                }));
            }
        };

        fetchReportData();
    }, [user, db]);

    const processedData = useMemo(() => {
        if (!reportData.allData.sales.length) {
            return {
                metrics: {
                    revenue: 0,
                    grossProfit: 0,
                    expenses: 0,
                    totalQuantity: 0
                },
                salesData: [],
                filteredSales: []
            };
        }

        const now = new Date();
        let timeFilter = new Date();

        switch (timeRange) {
            case '30days':
                timeFilter.setDate(timeFilter.getDate() - 30);
                break;
            case '90days':
                timeFilter.setDate(timeFilter.getDate() - 90);
                break;
            default: // 7days
                timeFilter.setDate(timeFilter.getDate() - 7);
        }

        // Filter sales based on time range
        const filteredSales = reportData.allData.sales.filter(sale =>
            sale.saleDate.toDate() >= timeFilter && sale.saleDate.toDate() <= now
        );

        const filteredExpenses = reportData.allData.expenses.filter(expense =>
            expense.date.toDate() >= timeFilter && expense.date.toDate() <= now
        );

        // Process metrics and daily totals
        const dailyTotals = {};
        let totalRevenue = 0;
        let totalCogs = 0;
        let totalQuantity = 0;

        filteredSales.forEach(sale => {
            const dateKey = sale.saleDate.toDate().toISOString().split('T')[0];

            if (!dailyTotals[dateKey]) {
                dailyTotals[dateKey] = {
                    revenue: 0,
                    profit: 0,
                    quantity: 0
                };
            }

            sale.items.forEach(item => {
                const revenue = item.price * item.quantity;
                const cogs = (item.costPrice || 0) * item.quantity;
                const quantity = item.quantity;

                dailyTotals[dateKey].revenue += revenue;
                dailyTotals[dateKey].profit += (revenue - cogs);
                dailyTotals[dateKey].quantity += quantity;

                totalRevenue += revenue;
                totalCogs += cogs;
                totalQuantity += quantity;
            });
        });

        // Calculate total expenses
        const totalExpenses = filteredExpenses.reduce((sum, expense) =>
            sum + (expense.amount || 0), 0
        );

        // Create sales data array with all dates
        const salesData = [];
        let currentDate = new Date(timeFilter);

        while (currentDate <= now) {
            const dateKey = currentDate.toISOString().split('T')[0];
            salesData.push({
                date: dateKey,
                revenue: dailyTotals[dateKey]?.revenue || 0,
                profit: dailyTotals[dateKey]?.profit || 0,
                quantity: dailyTotals[dateKey]?.quantity || 0,
                formattedDate: currentDate.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric'
                })
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return {
            metrics: {
                revenue: totalRevenue,
                grossProfit: totalRevenue - totalCogs,
                expenses: totalExpenses,
                totalQuantity
            },
            salesData,
            filteredSales
        };
    }, [reportData.allData, timeRange]);

    if (reportData.isLoading) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1">
                        <div className="flex flex-col items-center gap-3">
                            <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                            <div className="text-lg text-gray-600">Loading report data...</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (reportData.error) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1 text-red-500">
                        <AlertTriangle className="mr-2 h-5 w-5" />
                        <div>{reportData.error}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center my-10">
                        <h1 className="text-5xl font-semibold">Reports & Analytics</h1>
                        <div className="flex items-center gap-2 bg-white border rounded-lg px-4 py-2">
                            <Calendar className="h-5 w-5 text-gray-500" />
                            <select
                                className="border-none bg-transparent focus:outline-none text-gray-700"
                                value={timeRange}
                                onChange={(e) => setTimeRange(e.target.value)}
                            >
                                <option value="7days">Last 7 days</option>
                                <option value="30days">Last 30 days</option>
                                <option value="90days">Last 90 days</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex space-x-6 mb-8">
                        {['Overview', 'Sales', 'Top Selling Products'].map((tab) => (
                            <button
                                key={tab}
                                className={`text-lg font-medium ${selectedTab === tab.replace(/ /g, '_')
                                    ? 'text-blue-600 border-b-2 border-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => setSelectedTab(tab.replace(/ /g, '_'))}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    <div className="space-y-8">
                        {selectedTab === 'Overview' && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                                    <MetricCard
                                        title="Revenue"
                                        value={`GHS ${processedData.metrics.revenue.toFixed(2)}`}
                                        subtitle={`Last ${timeRange === '7days' ? '7' : timeRange === '30days' ? '30' : '90'} days`}
                                    />
                                    <MetricCard
                                        title="Gross Profit"
                                        value={`GHS ${processedData.metrics.grossProfit.toFixed(2)}`}
                                        subtitle={`Last ${timeRange === '7days' ? '7' : timeRange === '30days' ? '30' : '90'} days`}
                                    />
                                    <MetricCard
                                        title="Expenses"
                                        value={`GHS ${processedData.metrics.expenses.toFixed(2)}`}
                                        subtitle={`Last ${timeRange === '7days' ? '7' : timeRange === '30days' ? '30' : '90'} days`}
                                    />
                                </div>
                                <div className="min-h-[32rem] bg-white border ">
                                    <SalesChart salesData={processedData.salesData} />
                                </div>
                            </>
                        )}

                        {selectedTab === 'Sales' && (
                            <SalesChartsContainer salesData={processedData.salesData} />
                        )}

                        {selectedTab === 'Top_Selling_Products' && (
                            <TopProductsSection
                                salesData={reportData.allData.sales}
                                timeRange={timeRange}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const MetricCard = ({ title, value, subtitle }) => (
    <div className="bg-white border p-4 ">
        <h3 className="text-sm text-gray-500 mb-1">{title}</h3>
        <p className="text-2xl font-bold mb-1">{value}</p>
        <p className="text-xs text-gray-500">{subtitle}</p>
    </div>
);

const SalesChart = ({ salesData }) => (
    <div className="h-full p-4">
        <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Sales Overview</h2>
        </div>
        <div className="h-[28rem]">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={salesData}>
                    <XAxis
                        dataKey="formattedDate"
                        tickFormatter={(date) => date}
                    />
                    <YAxis />
                    <Tooltip
                        formatter={(value) => [`GHS ${value.toFixed(2)}`, 'Revenue']}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Line
                        type="monotone"
                        dataKey="revenue"
                        stroke="#8884d8"
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 8 }}
                        name="Revenue"
                    />
                    <Line
                        type="monotone"
                        dataKey="profit"
                        stroke="#82ca9d"
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 8 }}
                        name="Profit"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    </div>
);

export default Reports;