import React, { useState } from 'react';
import { Pencil, Trash2 } from 'lucide-react';
import { ProductEditModal } from './inventory_item_edit';
import { Link } from 'react-router-dom';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

export const InventoryListItem = ({ item, onDeleteClick, onItemUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const storeId = localStorage.getItem('storeId');
    const db = getFirestore();

    const handleDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDeleteClick();
    };

    const getStatusStyle = (quantity, reorderPoint) => {
        if (quantity === 0) {
            return "text-red-600";
        } else if (quantity <= reorderPoint) {
            return "text-yellow-600";
        }
        return "text-green-600";
    };

    const getStatusText = (quantity, reorderPoint) => {
        if (quantity === 0) {
            return "Out of Stock";
        } else if (quantity <= reorderPoint) {
            return "Low Stock";
        }
        return "In Stock";
    };

    const handleSave = async (updatedData) => {
        setIsUpdating(true);
        try {
            const itemRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
            const dataToUpdate = {
                ...updatedData,
                updatedAt: serverTimestamp()
            };

            await updateDoc(itemRef, dataToUpdate);

            // Notify parent component of the update
            if (onItemUpdate) {
                onItemUpdate(item.id, updatedData);
            }

            setIsEditing(false);
        } catch (error) {
            console.error('Error updating item:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    const statusStyle = getStatusStyle(item.quantity, item.reorderPoint);
    const statusText = getStatusText(item.quantity, item.reorderPoint);

    return (
        <>
            <tr onClick={() => setIsEditing(true)} className="cursor-pointer hover:bg-gray-50">
                <td className="px-6 py-4 text-sm text-gray-900">
                    {item.itemName}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                    {item.sku || 'N/A'}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                    {item.quantity}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                    <span className="text-xs mr-1">GHS</span>
                    <span className="text-gray-900">{item.sellingPrice?.toFixed(2) || '0.00'}</span>
                </td>
                <td className="px-6 py-4 text-sm">
                    <span className={`font-medium ${statusStyle}`}>
                        {statusText}
                    </span>
                </td>
                <td className="px-6 py-4 ">
                    <div className="flex items-center justify-center space-x-2">
                        <button
                            onClick={handleDelete}
                            className="text-red-500 hover:text-red-700"
                        >
                            <Trash2 className="h-5 w-5" />
                        </button>
                    </div>
                </td>
            </tr>

            <ProductEditModal
                item={item}
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                onSave={handleSave}
                storeId={storeId}
                isUpdating={isUpdating}
            />
        </>
    );
};