import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase-config';
import { signOut } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs, getFirestore } from 'firebase/firestore';
import { Loader2, Store, Users, LogOut, ChevronRight, Building2, UserPlus } from 'lucide-react';
import logo from '../../assets/hotKioskLogocolor.png';

function RoleSelection() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const userId = location.state?.userId || auth.currentUser?.uid;

    useEffect(() => {
        checkUserStatus();
    }, []);

    const checkUserStatus = async () => {
        try {
            // Check existing store assignment
            const assignmentStatus = await checkUserStoreAssignment(userId);

            if (assignmentStatus.isAssigned) {
                // User already has a valid store assignment
                navigate('/home');
            } else if (!assignmentStatus.needsRoleSelection) {
                // User has a pending store assignment
                navigate('/joinstore', { state: { userId } });
            } else {
                // User needs to select a role
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error checking user status:', error);
            setError('Error checking user status. Please try again.');
            setIsLoading(false);
        }
    };

    const checkUserStoreAssignment = async (userId) => {
        try {
            const storeUserQuery = query(
                collection(db, 'StoreUsers'),
                where('userId', '==', userId)
            );
            const querySnapshot = await getDocs(storeUserQuery);

            if (querySnapshot.empty) {
                return { isAssigned: false, needsRoleSelection: true };
            }

            const userData = querySnapshot.docs[0].data();
            const accessLevel = userData.accessLevel || 0;
            const approval = userData.approval;
            const storeId = userData.storeId;

            if (accessLevel === 1 && storeId) {
                // Store owner with assigned store
                return { isAssigned: true, needsRoleSelection: false };
            } else if (accessLevel === 2) {
                if (approval === 'approved' && storeId) {
                    // Approved manager with store
                    return { isAssigned: true, needsRoleSelection: false };
                } else if (approval === 'pending') {
                    // Pending manager approval
                    return { isAssigned: false, needsRoleSelection: false };
                }
            }

            return { isAssigned: false, needsRoleSelection: true };
        } catch (error) {
            console.error('Error checking store assignment:', error);
            return { isAssigned: false, needsRoleSelection: true };
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
            setError('Failed to log out. Please try again.');
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-white">
                <Loader2 className="h-8 w-8 animate-spin text-orange-500" />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <div className="bg-white border-b">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <img
                            src={logo}
                            alt="Hot Kiosk Logo"
                            className="h-12 w-auto"
                        />
                        <button
                            onClick={handleLogout}
                            className="flex items-center text-gray-500 hover:text-gray-700"
                        >
                            <LogOut className="h-5 w-5 mr-2" />
                            <span className="text-sm font-medium">Logout</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        Choose your role
                    </h1>
                    <p className="text-lg text-gray-600 mb-12">
                        Select how you want to use Hot Kiosk for your business
                    </p>

                    {error && (
                        <div className="mb-8 p-4 bg-red-50 rounded-lg border border-red-200 text-red-700 text-sm">
                            {error}
                        </div>
                    )}

                    <div className="grid md:grid-cols-2 gap-6">
                        {/* Store Owner Card */}
                        <button
                            onClick={() => navigate('/addstore', { state: { userId } })}
                            className="bg-white shadow-sm hover:shadow-md transition-all duration-200 border-2 border-transparent hover:border-orange-500 flex flex-col text-left cursor-pointer w-full"
                        >
                            <div className="p-6 flex-grow">
                                <div className="mb-6">
                                    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 text-gray-600">
                                        <Building2 className="h-6 w-6" />
                                    </div>
                                </div>
                                <h2 className="text-xl font-semibold text-gray-900 mb-2">Store Owner</h2>
                                <p className="text-gray-600">Create and manage your own store with complete control over inventory, sales, and staff.</p>
                            </div>
                            <div className="p-6 border-t group w-full">
                                <div className="flex items-center justify-between text-gray-600">
                                    <span className="font-medium">Create your store</span>
                                    <ChevronRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
                                </div>
                            </div>
                        </button>

                        {/* Shop Keeper Card */}
                        <button
                            onClick={() => navigate('/joinstore', { state: { userId } })}
                            className="bg-white shadow-sm hover:shadow-md transition-all duration-200 border-2 border-transparent hover:border-orange-500 flex flex-col text-left cursor-pointer w-full"
                        >
                            <div className="p-6 flex-grow">
                                <div className="mb-6">
                                    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 text-gray-600">
                                        <UserPlus className="h-6 w-6" />
                                    </div>
                                </div>
                                <h2 className="text-xl font-semibold text-gray-900 mb-2">Shop Keeper / Cashier</h2>
                                <p className="text-gray-600">Join an existing store as a staff member and help manage daily operations.</p>
                            </div>
                            <div className="p-6 border-t group w-full">
                                <div className="flex items-center justify-between text-gray-600">
                                    <span className="font-medium">Join a store</span>
                                    <ChevronRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
                                </div>
                            </div>
                        </button>
                    </div>

                    {/* Help Text */}
                    {/* <p className="mt-8 text-center text-sm text-gray-500">
                        Not sure which to choose?
                        <button className="ml-1 text-orange-600 hover:text-orange-700">
                            Learn more about roles
                        </button>
                    </p> */}
                </div>
            </div>
        </div>
    );
}

export default RoleSelection;