import React from 'react';
import { Clock, CheckCircle, XCircle, AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';

const RecurringExpenses = ({ expenses }) => {
    const getPaymentStatus = (expense) => {
        if (!expense.nextDueDate || !expense.lastBilledDate) return { isPaid: true, text: 'Active' };

        const today = new Date();
        const nextDue = new Date(expense.nextDueDate);

        if (today < nextDue) {
            return { isPaid: true, text: 'Active' };
        }

        return { isPaid: false, text: 'Payment needed' };
    };

    const getDueStatus = (nextDueDate) => {
        if (!nextDueDate) return { text: 'Not set', color: 'gray', isOverdue: false };

        const today = new Date();
        const due = new Date(nextDueDate);
        const daysUntilDue = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

        if (daysUntilDue < 0) return { text: `${Math.abs(daysUntilDue)} days overdue`, color: 'red', isOverdue: true };
        if (daysUntilDue === 0) return { text: 'Due today', color: 'orange', isOverdue: false };
        return { text: `Renews in ${daysUntilDue} days`, color: 'blue', isOverdue: false };
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Recurring Expenses</h2>
                <Link to="/expenses/recurring" className="text-sm text-blue-600 hover:text-blue-700">View All</Link>
            </div>
            <div className="divide-y border p-4">
                {expenses.map((expense) => {
                    const paymentStatus = getPaymentStatus(expense);
                    const dueStatus = getDueStatus(expense.nextDueDate);

                    return (
                        <div key={expense.id} className="p-4 hover:bg-gray-50">
                            <div className="flex justify-between items-start gap-4">
                                <div className="flex-1">
                                    <div className="flex items-center gap-2">
                                        <h3 className="font-medium">{expense.description}</h3>
                                        {paymentStatus.isPaid ? (
                                            <span className="flex items-center gap-1 text-emerald-600 text-xs px-2 py-0.5 bg-emerald-50 rounded-full">
                                                <CheckCircle className="w-3 h-3" />
                                                {paymentStatus.text}
                                            </span>
                                        ) : (
                                            <span className="flex items-center gap-1 text-amber-600 text-xs px-2 py-0.5 bg-amber-50 rounded-full">
                                                <XCircle className="w-3 h-3" />
                                                {paymentStatus.text}
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex space-x-2">
                                        <p className="text-sm text-gray-500 capitalize mt-1">
                                            {expense.recurringPeriod === 'custom'
                                                ? `Every ${expense.customPeriod.value} ${expense.customPeriod.unit}`
                                                : expense.recurringPeriod}
                                        </p>
                                        <div className={`flex items-center gap-1 text-${dueStatus.color}-600 text-sm mt-1`}>
                                            <Clock className="w-4 h-4" />
                                            <span>{dueStatus.text}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="text-right flex flex-col items-end">
                                    <span className="font-medium mb-1">
                                        <span className="text-xs mr-1">GHS</span>
                                        {expense.amount.toFixed(2)}
                                    </span>
                                    {dueStatus.isOverdue ? (
                                        <span className="text-xs text-red-600 bg-red-50 px-2 py-0.5 rounded-full">Overdue</span>
                                    ) : paymentStatus.isPaid ? (
                                        <span className="text-xs text-emerald-600 bg-emerald-50 px-2 py-0.5 rounded-full">Paid</span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default RecurringExpenses;