import React from 'react';
import { X } from 'lucide-react';
import { collection, doc, updateDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const ReturnDetailModal = ({ isOpen, onClose, returnItem }) => {
    const db = getFirestore();
    const auth = getAuth();

    const handleInventoryAction = async (action) => {
        try {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                throw new Error('No authenticated user found');
            }

            const storeId = returnItem.storeId;

            if (action === 'add_to_inventory') {
                const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', returnItem.itemId);
                await updateDoc(inventoryRef, {
                    quantity: returnItem.quantity
                });
            }

            const returnRef = doc(db, 'Stores', storeId, 'Returns', returnItem.returnId);
            await updateDoc(returnRef, {
                status: action === 'add_to_inventory' ? 'added_to_inventory' : 'bad_stock',
                processedDate: new Date(),
                processedBy: currentUser.email
            });

            onClose();
        } catch (error) {
            console.error('Error processing return:', error);
        }
    };

    if (!isOpen || !returnItem) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-full max-w-2xl mx-4">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-xl font-semibold">Return Details</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X className="h-5 w-5" />
                    </button>
                </div>

                <div className="p-6">
                    {/* Order Information */}
                    <div className="mb-6">
                        <h3 className="text-lg font-medium mb-4">Order Information</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-sm text-gray-500">Order Number</p>
                                <p className="text-sm font-medium">{returnItem.orderNumber || 'N/A'}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Return Date</p>
                                <p className="text-sm font-medium">
                                    {returnItem.returnDate.toLocaleString()}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Item Details */}
                    <div className="mb-6">
                        <h3 className="text-lg font-medium mb-4">Item Details</h3>
                        <div className="space-y-4">
                            <div>
                                <p className="text-sm text-gray-500">Item Name</p>
                                <p className="text-sm font-medium">{returnItem.itemName}</p>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <p className="text-sm text-gray-500">Quantity</p>
                                    <p className="text-sm font-medium">{returnItem.quantity}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Price</p>
                                    <p className="text-sm font-medium">
                                        GHS {returnItem.price.toFixed(2)}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Total Refunded</p>
                                    <p className="text-sm font-medium">
                                        GHS {(returnItem.price * returnItem.quantity).toFixed(2)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Return Reason */}
                    <div className="mb-6">
                        <h3 className="text-lg font-medium mb-4">Return Reason</h3>
                        <p className="text-sm bg-gray-50 p-3 rounded-lg">
                            {returnItem.reason || 'No reason provided'}
                        </p>
                    </div>

                    {/* Status/Processing History */}
                    {returnItem.status && (
                        <div className="mb-6">
                            <h3 className="text-lg font-medium mb-4">Processing History</h3>
                            <div className="bg-gray-50 p-3 rounded-lg">
                                <p className="text-sm">
                                    <span className="font-medium">Status: </span>
                                    <span className="capitalize">{returnItem.status.replace('_', ' ')}</span>
                                </p>
                                {returnItem.processedDate && (
                                    <p className="text-sm">
                                        <span className="font-medium">Processed: </span>
                                        {new Date(returnItem.processedDate).toLocaleString()}
                                    </p>
                                )}
                                {returnItem.processedBy && (
                                    <p className="text-sm">
                                        <span className="font-medium">Processed by: </span>
                                        {returnItem.processedBy}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    {/* Action Buttons */}
                    {!returnItem.status && (
                        <div className="grid grid-cols-3 gap-2 mt-6">
                            <button
                                onClick={() => handleInventoryAction('add_to_inventory')}
                                className="p-2 rounded-lg text-sm font-medium text-center transition-colors bg-green-100 text-green-700 border-2 border-green-500"
                            >
                                Return to Stock
                            </button>
                            <button
                                onClick={() => handleInventoryAction('bad_stock')}
                                className="p-2 rounded-lg text-sm font-medium text-center transition-colors bg-red-100 text-red-700 border-2 border-red-500"
                            >
                                Discard Item
                            </button>
                            <button
                                onClick={onClose}
                                className="p-2 rounded-lg text-sm font-medium text-center transition-colors bg-yellow-100 text-yellow-700 border-2 border-yellow-500"
                            >
                                Decide Later
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReturnDetailModal;