import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../firebase-config';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Loader2, Mail, Lock, AlertCircle } from 'lucide-react';
import businessOwner from '../../assets/business-owner.png';
import logo from '../../assets/hotKioskLogocolor.png';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const db = getFirestore();

    const saveUserData = async (userId) => {
        try {
            const userDoc = await getDoc(doc(db, 'Users', userId));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                localStorage.setItem('userId', userId);
                localStorage.setItem('storeId', userData.storeId || '');
                console.log(userId)
                console.log(userData.storeId)
            } else {
                console.error('User document not found');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await saveUserData(userCredential.user.uid);
            navigate('/dashboard');
        } catch (error) {
            setError(getErrorMessage(error.code));
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        setIsLoading(true);
        setError('');
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            await saveUserData(result.user.uid);
            navigate('/dashboard');
        } catch (error) {
            setError(getErrorMessage(error.code));
        } finally {
            setIsLoading(false);
        }
    };

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/user-not-found':
            case 'auth/wrong-password':
                return 'Invalid email or password';
            case 'auth/too-many-requests':
                return 'Too many failed login attempts. Please try again later';
            case 'auth/network-request-failed':
                return 'Network error. Please check your internet connection';
            default:
                return 'Check your credentials and try again';
        }
    };

    return (
        <div className="min-h-screen bg-white flex">
            {/* Left side - Form */}
            <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="mx-auto w-full max-w-md">
                    <div className="text-center mb-8">
                        <img
                            src={logo}
                            alt="Hot Kiosk Logo"
                            className="mx-auto h-12 w-auto mb-6"
                        />
                        <h2 className="text-3xl font-semibold text-gray-900">Welcome back</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            Sign in to manage your store
                        </p>
                    </div>

                    {error && (
                        <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-start gap-3">
                            <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
                            <p className="text-sm text-red-700">{error}</p>
                        </div>
                    )}

                    <form onSubmit={handleLogin} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    id="email"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 pl-10 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    id="password"
                                    type="password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 pl-10 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`w-full flex justify-center items-center py-2.5 px-4 rounded-lg text-white text-sm font-medium transition duration-150 ease-in-out ${isLoading
                                    ? 'bg-blue-400 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                    }`}
                            >
                                {isLoading ? (
                                    <>
                                        <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                                        Signing in...
                                    </>
                                ) : (
                                    'Sign in'
                                )}
                            </button>
                        </div>

                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">Or continue with</span>
                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={handleGoogleLogin}
                            disabled={isLoading}
                            className={`w-full flex items-center justify-center py-2.5 px-4 rounded-lg text-gray-700 bg-white border border-gray-300 text-sm font-medium transition duration-150 ease-in-out ${isLoading
                                ? 'opacity-50 cursor-not-allowed'
                                : 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                                }`}
                        >
                            <img
                                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                alt="Google logo"
                                className="w-5 h-5 mr-2"
                            />
                            Sign in with Google
                        </button>

                        <p className="text-center text-sm text-gray-600">
                            Don't have an account?{' '}
                            <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-500">
                                Sign up
                            </Link>
                        </p>
                    </form>
                </div>
            </div>

            {/* Right side - Image/Pattern */}
            <div className="hidden lg:block relative w-0 flex-1 bg-gradient-to-br from-blue-50 to-indigo-50">
                <div className="absolute inset-0 flex items-center justify-center p-12">
                    <div className="max-w-lg">
                        <img
                            src={businessOwner}
                            alt="Store management illustration"
                            className="w-full h-auto rounded-lg shadow-lg"
                        />
                        <div className="mt-8 text-center">
                            <h3 className="text-2xl font-semibold text-gray-900">Manage Your Store</h3>
                            <p className="mt-4 text-gray-600">
                                Complete business management with inventory, sales, POS, advanced financial reports, AI and more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;