import React from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';

const SalesQuantityChart = ({ salesData }) => (
    <div className="h-full">
        <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Sales Quantity</h2>
        </div>
        <div className="p-4 pt-7 bg-white border ">
            <div className="h-[28rem]">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={salesData}>
                        <XAxis
                            dataKey="formattedDate"
                            tickFormatter={(date) => date}
                        />
                        <YAxis />
                        <Tooltip
                            formatter={(value) => [`${value} units`, 'Quantity']}
                        />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar
                            dataKey="quantity"
                            fill="#4f46e5"
                            radius={[4, 4, 0, 0]}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
);

const SalesRevenueChart = ({ salesData }) => (
    <div className="h-full">
        <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Revenue & Gross Profit</h2>
        </div>
        <div className="p-4 pt-7 bg-white border ">
            <div className="h-[28rem]">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={salesData}>
                        <XAxis
                            dataKey="formattedDate"
                            tickFormatter={(date) => date}
                        />
                        <YAxis />
                        <Tooltip
                            formatter={(value, name) => [
                                `GHS ${value.toFixed(2)}`,
                                name.charAt(0).toUpperCase() + name.slice(1)
                            ]}
                        />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Line
                            type="monotone"
                            dataKey="revenue"
                            stroke="#8884d8"
                            strokeWidth={2}
                            dot={false}
                            activeDot={{ r: 8 }}
                            name="Revenue"
                        />
                        <Line
                            type="monotone"
                            dataKey="profit"
                            stroke="#82ca9d"
                            strokeWidth={2}
                            dot={false}
                            activeDot={{ r: 8 }}
                            name="Profit"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
);

const SalesChartsContainer = ({ salesData }) => {
    // Process the salesData to include quantity
    const processedData = salesData.map(day => {
        // You'll need to modify your data processing to include quantity
        // This should be done in the main component's processedData calculation
        return {
            ...day,
            quantity: Math.round((day.revenue / 100) * 1.5) // This is a placeholder calculation
        };
    });

    return (
        <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
            <SalesQuantityChart salesData={processedData} />
            <SalesRevenueChart salesData={processedData} />
        </div>
    );
};

export default SalesChartsContainer;