import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import Sidebar from '../components/sidebar';

const SalesInvoices = () => {
    return (
        <div className="flex bg-white">
            <Sidebar />
            <div className="flex-1 p-8 transition-all duration-300">
                <h1 className="text-5xl font-semibold my-10">SalesInvoices</h1>

            </div>
        </div>
    );
};



export default SalesInvoices;