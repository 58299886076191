import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const TimeBasedSalesChart = ({ sales, timePeriod }) => {
    const [chartData, setChartData] = useState([]);
    const [key, setKey] = useState(0); // Add key to force remount

    useEffect(() => {
        if (timePeriod === 'today') {
            // Hourly breakdown for today
            const hourlyTotals = {};
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // Initialize all hours with 0
            for (let i = 0; i < 24; i++) {
                const hour = i.toString().padStart(2, '0');
                hourlyTotals[hour] = 0;
            }

            // Aggregate sales by hour for today
            sales.forEach(sale => {
                const saleDate = sale.saleDate;
                if (saleDate >= today) {
                    const hour = saleDate.getHours().toString().padStart(2, '0');
                    hourlyTotals[hour] += sale.totalAmount;
                }
            });

            // Convert to chart format
            const hourlyData = Object.entries(hourlyTotals)
                .map(([hour, amount]) => ({
                    date: `${hour}:00`,
                    amount: amount
                }))
                .sort((a, b) => a.date.localeCompare(b.date));

            setChartData(hourlyData);
        } else {
            // Use daily totals for week and month views
            const dailyTotals = {};

            sales.forEach(sale => {
                const dateKey = sale.saleDate.toISOString().split('T')[0];
                dailyTotals[dateKey] = (dailyTotals[dateKey] || 0) + sale.totalAmount;
            });

            const dailyData = Object.entries(dailyTotals)
                .map(([date, total]) => ({
                    date,
                    amount: total
                }))
                .sort((a, b) => new Date(a.date) - new Date(b.date));

            setChartData(dailyData);
        }
        // Force remount of chart when time period changes
        setKey(prev => prev + 1);
    }, [sales, timePeriod]);

    return (
        <div className="pt-2">
            <h2 className="text-xl font-semibold mb-6">
                Sales Overview {timePeriod === 'today' ? '(Hourly)' : ''}
            </h2>
            <div className="pt-8 p-4 h-[28rem] min-h-[32rem] bg-white border">
                <ResponsiveContainer width="100%" height="100%" key={key}>
                    <LineChart data={chartData}>
                        <XAxis
                            dataKey="date"
                            tickFormatter={(value) => {
                                if (timePeriod === 'today') {
                                    return value; // Show as HH:00
                                }
                                return new Date(value).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                            }}
                            interval={timePeriod === 'today' ? 3 : 'preserveStartEnd'}
                            tick={{ fontSize: 12 }}
                        />
                        <YAxis />
                        <Tooltip
                            formatter={(value) => [`GHS ${value.toFixed(2)}`, 'Sales']}
                            labelFormatter={(label) => {
                                if (timePeriod === 'today') {
                                    return `Time: ${label}`;
                                }
                                return new Date(label).toLocaleDateString('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric'
                                });
                            }}
                        />
                        <Line
                            type="monotone"
                            dataKey="amount"
                            stroke="#8884d8"
                            strokeWidth={2}
                            dot={false}
                            activeDot={{ r: 8 }}
                            animationDuration={1500}
                            animationBegin={0}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default TimeBasedSalesChart;