import React, { useState } from 'react';
import { MoreVertical, Edit, Trash } from 'lucide-react';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

const QuoteActionsDropdown = ({ quote, onEdit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    const handleDelete = async (e) => {
        e.stopPropagation(); // Prevent row click event

        if (window.confirm('Are you sure you want to delete this quote?')) {
            try {
                const quoteRef = doc(db, 'Stores', storeId, 'SalesQuotes', quote.id);
                await deleteDoc(quoteRef);
            } catch (error) {
                console.error('Error deleting quote:', error);
                alert('Failed to delete quote. Please try again.');
            }
        }
        setIsOpen(false);
    };

    const handleEdit = (e) => {
        e.stopPropagation(); // Prevent row click event
        onEdit(quote);
        setIsOpen(false);
    };

    const toggleDropdown = (e) => {
        e.stopPropagation(); // Prevent row click event
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                className="hover:bg-gray-100 p-1 rounded"
            >
                <MoreVertical className="h-5 w-5" />
            </button>

            {isOpen && (
                <>
                    {/* Overlay to close dropdown when clicking outside */}
                    <div
                        className="fixed inset-0 z-10"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(false);
                        }}
                    />

                    {/* Dropdown menu */}
                    <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg z-20 py-1 border">
                        <button
                            onClick={handleEdit}
                            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                            <Edit className="h-4 w-4 mr-2" />
                            Edit Quote
                        </button>
                        <button
                            onClick={handleDelete}
                            className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                        >
                            <Trash className="h-4 w-4 mr-2" />
                            Delete Quote
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default QuoteActionsDropdown;