import React, { forwardRef, useState } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';
import SingleExpenseModal from './single_expense_modal';

const ExpenseRow = forwardRef(({ expense, onUpdatePaymentStatus, onDelete, loading, deleting }, ref) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <tr
                ref={ref}
                className="hover:bg-gray-50 cursor-pointer"
                onClick={() => setShowModal(true)}
            >
                <td className="px-6 py-3">
                    <div className="font-normal text-sm">{expense.description}</div>
                </td>
                <td className="px-6 py-3">
                    <div className="text-sm text-gray-600 capitalize">{expense.category || 'Uncategorized'}</div>
                </td>
                <td className="px-6 py-3">
                    <div className="text-sm text-gray-600">{expense.date?.toLocaleDateString()}</div>
                </td>
                <td className="px-6 py-3">
                    <span className="font-medium">
                        <span className="text-xs mr-1 text-gray-500">GHS</span>
                        <span className="text-sm mr-1 text-gray-800">{expense.amount.toFixed(2)}</span>
                    </span>
                </td>
                <td className="px-6 py-3">
                    <span className={`flex items-center gap-1 text-xs px-2 py-0.5 rounded-full w-fit
                        ${expense.paid ? 'text-green-600 bg-green-50' : 'text-red-600 bg-red-50'}`}
                    >
                        {expense.paid ? (
                            <CheckCircle className="w-3 h-3" />
                        ) : (
                            <XCircle className="w-3 h-3" />
                        )}
                        {expense.paid ? 'Paid' : 'Unpaid'}
                    </span>
                </td>
                <td className="px-6 py-3">
                    <div className="text-sm text-gray-500">
                        {expense.paidDate ? expense.paidDate.toLocaleDateString() : '-'}
                    </div>
                </td>
            </tr>

            {showModal && (
                <SingleExpenseModal
                    expense={expense}
                    onClose={() => setShowModal(false)}
                    onUpdatePaymentStatus={onUpdatePaymentStatus}
                    onDelete={onDelete}
                    loading={loading}
                    deleting={deleting}
                />
            )}
        </>
    );
});

export default ExpenseRow;