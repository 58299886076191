import React, { useState } from 'react';
import { Box, BoxIcon, ImageOff, Tag, Trash2 } from 'lucide-react';
import { ProductEditModal } from './inventory_item_edit';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

export const ProductCard = ({ item, onDeleteClick, onItemUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const storeId = localStorage.getItem('storeId');
    const db = getFirestore();

    const handleDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (typeof onDeleteClick === 'function') {
            onDeleteClick(item.id);
        }
    };

    const getStatusStyle = (quantity, reorderPoint) => {
        if (quantity === 0) {
            return "bg-red-100 text-red-600";
        } else if (quantity <= reorderPoint) {
            return "bg-yellow-100 text-yellow-600";
        }
        return "bg-green-100 text-green-600";
    };

    const getStatusText = (quantity, reorderPoint) => {
        if (quantity === 0) {
            return "Out of Stock";
        } else if (quantity <= reorderPoint) {
            return "Low Stock";
        }
        return "In Stock";
    };

    const handleCardClick = () => {
        setIsEditing(true);
    };

    const handleSave = async (updatedData) => {
        setIsUpdating(true);
        try {
            const itemRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
            const dataToUpdate = {
                ...updatedData,
                updatedAt: serverTimestamp()
            };

            await updateDoc(itemRef, dataToUpdate);

            // Notify parent component of the update
            if (onItemUpdate) {
                onItemUpdate(item.id, updatedData);
            }

            setIsEditing(false);
        } catch (error) {
            console.error('Error updating item:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    const statusStyle = getStatusStyle(item.quantity, item.reorderPoint);
    const statusText = getStatusText(item.quantity, item.reorderPoint);

    return (
        <>
            <div
                className="bg-white border shadow-sm hover:shadow-md transition-shadow duration-200 cursor-pointer relative"
                onClick={handleCardClick}
            >
                <button
                    onClick={handleDelete}
                    className="absolute bottom-3 right-3 flex space-x-2 text-red-500 hover:text-red-700"
                >
                    <Trash2 className="h-4 w-4" />
                </button>
                <div className="flex p-4">
                    <div className="w-32 h-32 flex-shrink-0 rounded-lg overflow-hidden bg-white">
                        {item.imageUrl ? (
                            <img
                                src={item.imageUrl}
                                alt={item.itemName}
                                className="w-full h-full object-contain"
                            />
                        ) : (
                            <div className="flex items-center justify-center w-full h-full">
                                <BoxIcon className="h-8 w-8 text-gray-400" />
                            </div>
                        )}
                    </div>

                    <div className="flex-1 ml-4">
                        <div className="flex justify-between">
                            <h3 className="text-base font-semibold text-gray-900 line-clamp-2">
                                {item.itemName}
                            </h3>
                        </div>

                        <div className="flex items-center mt-2 text-sm text-gray-500">
                            <Tag className="h-4 w-4 mr-1 flex-shrink-0" />
                            <span className="truncate">{item.sku || 'N/A'}</span>
                        </div>

                        <div className="flex items-center justify-between mt-2">
                            <span className="text-sm text-gray-500">
                                {item.quantity} left
                            </span>
                            <span className="text-sm font-medium text-gray-900">
                                GHS {item.sellingPrice?.toFixed(2) || '0.00'}
                            </span>
                        </div>

                        <div className="mt-2 flex items-center justify-between">
                            <span className={`text-xs px-2 py-1 ${statusStyle}`}>
                                {statusText}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <ProductEditModal
                item={item}
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                onSave={handleSave}
                storeId={storeId}
                isUpdating={isUpdating}
            />
        </>
    );
};