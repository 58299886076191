import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Home,
    BarChart2,
    Package,
    ShoppingCart,
    DollarSign,
    Settings,
    Users,
    ChevronLeft,
    ChevronRight,
    ChevronDown,
    Plus,
    LogOut,
    CreditCard,
    Receipt,
    UserCircle,
    Building,
    Calendar,
    Mail,
    FileText,
    Clock,
    Archive,
    Wrench,    // Changed from Tool to Wrench
    AlertCircle,
    Crown,
    ReceiptIcon,
    ReceiptText,
    LucideReceiptCent,
    ShoppingBag
} from 'lucide-react';
import { auth } from '../../../firebase-config';
import { signOut } from 'firebase/auth';
import logo from '../../../assets/hotKioskLogocolor.png';


const SubMenu = ({ to, icon, text, isCollapsed, children, isActive }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isActive && !isOpen && !isCollapsed) {
            setIsOpen(true);
        }
    }, [isActive, location.pathname]);

    const handleClick = (e) => {
        e.preventDefault();
        if (!isCollapsed) {
            setIsOpen(!isOpen);
            if (location.pathname !== to) {
                navigate(to);
            }
        } else {
            navigate(to);
        }
    };

    return (
        <div className="mb-1">
            <button
                onClick={handleClick}
                className={`w-full flex items-center py-2 px-4 rounded transition-colors duration-200 ${isActive
                    ? 'bg-orange-600/10 text-orange-500'
                    : 'hover:bg-orange-600/5 hover:text-orange-400'
                    } ${isCollapsed ? 'justify-center' : ''}`}
                title={isCollapsed ? text : ''}
            >
                <span className={`${isActive ? 'text-orange-500' : 'text-gray-300 group-hover:text-orange-400'}`}>
                    {icon}
                </span>
                {!isCollapsed && (
                    <>
                        <span className="ml-3 flex-grow text-left">{text}</span>
                        <ChevronDown
                            size={16}
                            className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                        />
                    </>
                )}
            </button>
            {isOpen && !isCollapsed && (
                <div className="ml-4 mt-1 space-y-1">
                    {React.Children.map(children, child =>
                        React.cloneElement(child, {
                            isSubmenuItem: true
                        })
                    )}
                </div>
            )}
        </div>
    );
};

const SidebarLink = ({ to, icon, text, isActive, isCollapsed, isSubmenuItem }) => (
    <Link
        to={to}
        className={`group flex items-center py-2 px-4 rounded mb-1 transition-colors duration-200 ${isActive
            ? 'bg-orange-600/10 text-orange-500'
            : 'hover:bg-orange-600/5 hover:text-orange-400'
            } ${isCollapsed ? 'justify-center' : ''}`}
        title={isCollapsed ? text : ''}
    >
        <span className={`${isActive
            ? 'text-orange-500'
            : 'text-gray-300 group-hover:text-orange-400'
            }`}>
            {icon}
        </span>
        {!isCollapsed && (
            <span className={`ml-3 ${isSubmenuItem ? 'text-sm' : ''
                } ${isActive
                    ? 'text-orange-500'
                    : 'text-gray-300 group-hover:text-orange-400'
                }`}>
                {text}
            </span>
        )}
    </Link>
);

const Sidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        return localStorage.getItem('sidebarCollapsed') === 'true';
    });
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('sidebarCollapsed', isCollapsed);
    }, [isCollapsed]);

    const toggleSidebar = () => {
        setIsCollapsed(prevState => !prevState);
    };

    const handleNewSale = () => {
        navigate('/sales/add');
    };

    const handleLogout = async () => {
        try {
            // Clear all items from localStorage
            localStorage.clear();

            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className={`bg-black text-white ${isCollapsed ? 'w-16' : 'w-64'} min-h-screen p-4 transition-all duration-300 relative flex flex-col`}>
            {/* Logo and Brand */}
            <div className={`flex items-center mb-8 ${isCollapsed ? 'justify-center' : ''}`}>
                <img
                    src={logo}
                    alt="HotKiosk Logo"
                    className={`${isCollapsed ? 'mr-0' : 'mr-2'}`}
                />
            </div>

            {/* New Sale Button */}
            <button
                onClick={handleNewSale}
                className={`bg-orange-600 text-white font-semibold w-full py-4 rounded mb-6 flex items-center justify-center hover:bg-orange-700 transition-colors duration-200 ${isCollapsed ? 'p-2' : ''
                    }`}
            >
                {isCollapsed ? <Plus size={20} /> : "Make Sales / POS"}
            </button>

            {/* Navigation Menu */}
            <nav className="flex-grow overflow-y-auto">
                {/* Dashboard */}
                <SidebarLink
                    to="/dashboard"
                    icon={<Home size={20} />}
                    text="Dashboard"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/dashboard'}
                />

                {/* Sales Section */}
                <SubMenu
                    to="/sales"
                    icon={<ShoppingCart size={20} />}
                    text="Sales"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname.startsWith('/sales')}
                >
                    <SidebarLink
                        to="/sales/list"
                        icon={<ShoppingCart size={16} />}
                        text="All Sales"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/sales/list'}
                    />
                    <SidebarLink
                        to="/sales/quotes"
                        icon={<FileText size={16} />}
                        text="Quotes"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/sales/quotes'}
                    />
                    <SidebarLink
                        to="/sales/receipts"
                        icon={<ReceiptText size={16} />}
                        text="Receipts"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/sales/receipts'}
                    />
                    {/* <SidebarLink
                        to="/sales/invoices"
                        icon={<LucideReceiptCent size={16} />}
                        text="Invoices"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/sales/invoices'}
                    /> */}
                    <SidebarLink
                        to="/sales/returns"
                        icon={<CreditCard size={16} />}
                        text="Returns"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/sales/returns'}
                    />
                </SubMenu>

                {/* Inventory Section */}
                <SubMenu
                    to="/inventory"
                    icon={<Package size={20} />}
                    text="Inventory"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname.startsWith('/inventory')}
                >
                    <SidebarLink
                        to="/inventory/list"
                        icon={<Package size={16} />}
                        text="All Items"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/inventory/list'}
                    />
                    <SidebarLink
                        to="/inventory/categories"
                        icon={<Archive size={16} />}
                        text="Categories"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/inventory/categories'}
                    />
                    <SidebarLink
                        to="/inventory/stock"
                        icon={<Package size={16} />}
                        text="Stock Management"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/inventory/stock'}
                    />
                </SubMenu>

                {/* Clients Section */}
                <SubMenu
                    to="/clients"
                    icon={<Users size={20} />}
                    text="Clients"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname.startsWith('/clients')}
                >
                    <SidebarLink
                        to="/clients/customers"
                        icon={<UserCircle size={16} />}
                        text="Customers"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/clients/customers'}
                    />
                    <SidebarLink
                        to="/clients/suppliers"
                        icon={<Users size={16} />}
                        text="Suppliers"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/clients/suppliers'}
                    />
                </SubMenu>

                {/* Expenses Section */}
                {/* <SidebarLink
                    to="/payments"
                    icon={<LucideReceiptCent size={20} />}
                    text="Payments"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/payments'}
                /> */}

                <SidebarLink
                    to=""
                    icon={<LucideReceiptCent size={20} />}
                    text="Payments"
                    isCollapsed={isCollapsed}
                    isActive={''}
                />

                {/* Expenses Section */}
                <SubMenu
                    to="/expenses"
                    icon={<ShoppingBag size={20} />}
                    text="Expenses"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname.startsWith('/expenses')}
                >
                    <SidebarLink
                        to="/expenses/list"
                        icon={<LucideReceiptCent size={16} />}
                        text="All Expenses"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/expenses/list'}
                    />
                    <SidebarLink
                        to="/expenses/categories"
                        icon={<Archive size={16} />}
                        text="Categories"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/expenses/categories'}
                    />
                    {/* <SidebarLink
                        to="/expenses/recurring"
                        icon={<Clock size={16} />}
                        text="Recurring"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/expenses/recurring'}
                    /> */}
                </SubMenu>

                {/* Reports Section */}
                <SidebarLink
                    to=""
                    icon={<BarChart2 size={20} />}
                    text="Reports"
                    isCollapsed={isCollapsed}
                    isActive={''}
                />

                {/* Reports Section */}
                {/* <SubMenu
                    to="/reports"
                    icon={<BarChart2 size={20} />}
                    text="Reports"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname.startsWith('/reports')}
                >
                    <SidebarLink
                        to="/reports/analytics"
                        icon={<BarChart2 size={16} />}
                        text="Analytic Reports"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/reports/analytics'}
                    />
                    <SidebarLink
                        to="/reports/finance"
                        icon={<DollarSign size={16} />}
                        text="Financial Reports"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/reports/finance'}
                    />
                    <SidebarLink
                        to="/reports/clients"
                        icon={<Users size={16} />}
                        text="Client Reports"
                        isCollapsed={isCollapsed}
                        isActive={location.pathname === '/reports/clients'}
                    />
                </SubMenu> */}

                {/* Settings Section */}
                <SidebarLink
                    to="/settings"
                    icon={<Settings size={20} />}
                    text="Settings"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/settings'}
                />

            </nav>

            {/* Logout Button */}
            <button
                onClick={handleLogout}
                className={`mt-auto flex items-center py-2 px-4 rounded hover:bg-orange-600/5 hover:text-orange-400 transition-colors duration-200 ${isCollapsed ? 'justify-center' : ''
                    }`}
                title={isCollapsed ? "Logout" : ''}
            >
                <span className="text-gray-300 group-hover:text-orange-400">
                    <LogOut size={20} />
                </span>
                {!isCollapsed && <span className="ml-3 text-gray-300">Logout</span>}
            </button>

            {/* Collapse Toggle Button */}
            <button
                onClick={toggleSidebar}
                className="absolute -right-3 top-1/2 bg-black text-gray-300 hover:text-orange-400 p-1 rounded-full transition-colors duration-200"
            >
                {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
            </button>
        </div>
    );
};

export default Sidebar;