import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC00-byMIYTyChwdl1nSGOxkc5-P-DKweA",
    authDomain: "hot-kiosk.firebaseapp.com",
    projectId: "hot-kiosk",
    storageBucket: "hot-kiosk.appspot.com",
    messagingSenderId: "945449480793",
    appId: "1:945449480793:web:c9409e7c374c6b8856b748"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, provider, db, storage };