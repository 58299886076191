import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

// Reduced to 5 colors since we only need 5 now
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const TopProductsSection = ({ salesData, timeRange }) => {
    const { topByQuantity, topByProfit } = useMemo(() => {
        const now = new Date();
        let timeFilter = new Date();

        switch (timeRange) {
            case '30days':
                timeFilter.setDate(timeFilter.getDate() - 30);
                break;
            case '90days':
                timeFilter.setDate(timeFilter.getDate() - 90);
                break;
            default: // 7days
                timeFilter.setDate(timeFilter.getDate() - 7);
        }

        const productMetrics = {};

        salesData.forEach(sale => {
            const saleDate = sale.saleDate.toDate();

            if (saleDate >= timeFilter && saleDate <= now) {
                sale.items.forEach(item => {
                    const itemName = item.itemName;
                    const quantity = item.quantity;
                    const revenue = item.price * quantity;
                    const cost = (item.costPrice || 0) * quantity;
                    const profit = revenue - cost;

                    if (!productMetrics[itemName]) {
                        productMetrics[itemName] = {
                            name: itemName,
                            quantity: 0,
                            profit: 0
                        };
                    }

                    productMetrics[itemName].quantity += quantity;
                    productMetrics[itemName].profit += profit;
                });
            }
        });

        const allProducts = Object.values(productMetrics);

        return {
            topByQuantity: [...allProducts]
                .sort((a, b) => b.quantity - a.quantity)
                .slice(0, 5), // Changed to top 5
            topByProfit: [...allProducts]
                .sort((a, b) => b.profit - a.profit)
                .slice(0, 5) // Changed to top 5
        };
    }, [salesData, timeRange]);

    const formatCurrency = (value) => {
        return `GHS ${value.toFixed(2)}`;
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white border border-gray-200 p-3 shadow-lg rounded-lg">
                    <p className="font-medium text-sm mb-1">{payload[0].name}</p>
                    <p className="text-gray-600 font-semibold">
                        {payload[0].dataKey === 'quantity'
                            ? payload[0].value.toLocaleString() + ' sold'
                            : formatCurrency(payload[0].value)
                        }
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="mt-10">
            {/* Top Products by Quantity */}
            <h2 className="text-xl font-semibold mb-6">Top 5 Products by Quantity</h2>
            <div className="bg-white border border-gray-200 p-8">
                <div className="flex items-start gap-5">
                    <div className="w-96 h-96">
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    data={topByQuantity}
                                    dataKey="quantity"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={80}
                                    outerRadius={140}
                                    paddingAngle={3} // Slightly increased padding angle for 5 segments
                                >
                                    {topByQuantity.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index]}
                                            strokeWidth={1}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="flex-1 min-w-0">
                        {topByQuantity.length > 0 ? (
                            <div className="space-y-2"> {/* Increased spacing between items */}
                                {topByQuantity.map((product, index) => (
                                    <div
                                        key={product.name}
                                        className="flex items-center justify-between py-4 border-b border-gray-100 last:border-0"
                                    >
                                        <div className="flex items-center min-w-0">
                                            <span className="flex-shrink-0 w-8 h-8 rounded-full mr-4"
                                                style={{ backgroundColor: COLORS[index] }}
                                            />
                                            <span className="font-medium text-lg truncate">{product.name}</span>
                                        </div>
                                        <span className="text-gray-600 ml-4 flex-shrink-0 text-lg">
                                            {product.quantity.toLocaleString()} sold
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-gray-500 text-center py-4">
                                No sales data for this period
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Top Products by Profit */}
            <h2 className="text-xl font-semibold mt-8 mb-6">Top 5 Products by Profit</h2>
            <div className="bg-white border border-gray-200 p-8">
                <div className="flex items-start gap-5">
                    <div className="w-96 h-96">
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    data={topByProfit}
                                    dataKey="profit"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={80}
                                    outerRadius={140}
                                    paddingAngle={3}
                                >
                                    {topByProfit.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index]}
                                            strokeWidth={1}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="flex-1 min-w-0">
                        {topByProfit.length > 0 ? (
                            <div className="space-y-2">
                                {topByProfit.map((product, index) => (
                                    <div
                                        key={product.name}
                                        className="flex items-center justify-between py-4 border-b border-gray-100 last:border-0"
                                    >
                                        <div className="flex items-center min-w-0">
                                            <span className="flex-shrink-0 w-8 h-8 rounded-full mr-4"
                                                style={{ backgroundColor: COLORS[index] }}
                                            />
                                            <span className="font-medium text-lg truncate">{product.name}</span>
                                        </div>
                                        <span className="text-gray-600 ml-4 flex-shrink-0 text-lg">
                                            {formatCurrency(product.profit)}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-gray-500 text-center py-4">
                                No sales data for this period
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopProductsSection;