import React, { useState, useEffect } from 'react';
import { Search, ArrowLeft, Download, Filter, Pencil, Trash2, ImageOff, Tag, X, BarcodeIcon, Plus, Loader2 } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    addDoc,
    deleteDoc,
    doc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import _ from 'lodash';
import Sidebar from '../components/sidebar';
import { InventoryListItem } from '../components/inventory/inventory_list_items';
import { ProductCard } from '../components/inventory/inventory_product_card';
import ProductAddModal from '../components/inventory/inventory_item_add';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, isDeleting }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
                <h2 className="text-xl font-semibold mb-4">Delete Item</h2>
                <p className="text-gray-600 mb-6">
                    Are you sure you want to delete this item? This action cannot be undone.
                </p>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                        disabled={isDeleting}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded-lg disabled:bg-red-300"
                        disabled={isDeleting}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete'}
                    </button>
                </div>
            </div>
        </div>
    );
};

const InventoryItems = () => {
    const [viewMode, setViewMode] = useState('list');
    const [inventoryData, setInventoryData] = useState({
        inventory: [],
        isLoading: true,
        error: null
    });
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const location = useLocation();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [user, setUser] = useState(null);
    const itemsPerPage = viewMode === 'list' ? 10 : 12;

    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        // Check for the openAddModal query parameter
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('openAddModal') === 'true') {
            setIsAddModalOpen(true);
            // Clean up the URL
            window.history.replaceState({}, '', location.pathname);
        }
    }, [location]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setInventoryData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to authenticate user'
                }));
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    useEffect(() => {
        const fetchInventoryData = async () => {
            if (!user?.storeId) return;

            try {
                const inventoryQuery = query(
                    collection(db, 'Stores', user.storeId, 'Inventory'),
                    orderBy('updatedAt', 'desc')
                );

                const inventorySnapshot = await getDocs(inventoryQuery);
                const inventory = [];

                inventorySnapshot.forEach(doc => {
                    inventory.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                setInventoryData({
                    inventory,
                    isLoading: false,
                    error: null
                });
            } catch (error) {
                console.error('Error fetching inventory data:', error);
                setInventoryData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load inventory data'
                }));
            }
        };

        fetchInventoryData();
    }, [user, db]);

    const handleAddItem = async (newItem) => {
        try {
            const docRef = await addDoc(collection(db, 'Stores', user.storeId, 'Inventory'), newItem);
            // Refresh inventory data or add to local state
            setInventoryData(prev => ({
                ...prev,
                inventory: [{ id: docRef.id, ...newItem }, ...prev.inventory]
            }));
        } catch (error) {
            console.error('Error adding item:', error);
        }
    };

    const handleDeleteClick = (itemId) => {
        setDeleteItemId(itemId);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!deleteItemId || !user?.storeId) return;

        setIsDeleting(true);
        try {
            await deleteDoc(doc(db, 'Stores', user.storeId, 'Inventory', deleteItemId));

            // Update local state to remove the deleted item
            setInventoryData(prev => ({
                ...prev,
                inventory: prev.inventory.filter(item => item.id !== deleteItemId)
            }));

            // Reset delete state
            setDeleteItemId(null);
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error('Error deleting item:', error);
            // You might want to show an error message to the user here
        } finally {
            setIsDeleting(false);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteItemId(null);
        setIsDeleteModalOpen(false);
    };

    const handleItemUpdate = (itemId, updatedData) => {
        setInventoryData(prev => ({
            ...prev,
            inventory: prev.inventory.map(item =>
                item.id === itemId
                    ? { ...item, ...updatedData }
                    : item
            )
        }));
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? '↑' : '↓';
        }
        return '';
    };

    const sortedInventory = React.useMemo(() => {
        let sortableItems = [...inventoryData.inventory];
        if (sortConfig.key) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [inventoryData.inventory, sortConfig]);

    const filteredInventory = sortedInventory.filter(item =>
        item.itemName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.sku?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);
    const currentItems = filteredInventory.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleExport = () => {
        const csvContent = [
            ['Product', 'SKU', 'Quantity', 'Price', 'Status'],
            ...filteredInventory.map(item => [
                item.itemName,
                item.sku || 'N/A',
                item.quantity,
                item.sellingPrice?.toFixed(2) || '0.00',
                item.quantity === 0 ? 'Out of Stock' :
                    item.quantity <= item.reorderPoint ? 'Low Stock' : 'In Stock'
            ])
        ].map(row => row.join(',')).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'inventory_export.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    if (inventoryData.isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1">
                        <div className="flex flex-col items-center gap-3">
                            <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                            <div className="text-lg">Loading inventory list...</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (inventoryData.error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 p-8">
                    <div className="flex items-center justify-center text-red-500">
                        <div className="text-lg">{inventoryData.error}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-8">
                        <div className="flex items-center space-x-4">
                            <Link
                                to="/inventory"
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <ArrowLeft className="h-6 w-6" />
                            </Link>
                            <h1 className="text-3xl font-semibold">Inventory Items</h1>
                        </div>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors" onClick={() => setIsAddModalOpen(true)}>
                            <Plus className="h-5 w-5 mr-2" />
                            Add New Item
                        </button>
                    </div>

                    <div className="flex justify-between items-center mb-8">
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search by name or SKU..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 w-96 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                            </div>


                        </div>

                        <div className="flex space-x-4">
                            <div className="flex rounded-lg border">
                                <button
                                    onClick={() => setViewMode('list')}
                                    className={`px-4 py-2 text-sm ${viewMode === 'list'
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-600 hover:bg-gray-50'
                                        }`}
                                >
                                    List View
                                </button>
                                <button
                                    onClick={() => setViewMode('grid')}
                                    className={`px-4 py-2 text-sm ${viewMode === 'grid'
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-600 hover:bg-gray-50'
                                        }`}
                                >
                                    Products View
                                </button>
                            </div>
                            <button
                                onClick={handleExport}
                                className="inline-flex items-center px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
                            >
                                <Download className="h-4 w-4 mr-2" />
                                Export
                            </button>
                        </div>
                    </div>

                    {viewMode === 'list' ? (
                        <div className="border p-4">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th
                                                onClick={() => handleSort('itemName')}
                                                className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase cursor-pointer"
                                            >
                                                Product {getSortIcon('itemName')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('sku')}
                                                className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase cursor-pointer"
                                            >
                                                SKU {getSortIcon('sku')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('quantity')}
                                                className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase cursor-pointer"
                                            >
                                                Quantity {getSortIcon('quantity')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('sellingPrice')}
                                                className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase cursor-pointer"
                                            >
                                                Price {getSortIcon('sellingPrice')}
                                            </th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase">
                                                Status
                                            </th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y">
                                        {currentItems.length === 0 ? (
                                            <tr>
                                                <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                                    No inventory items found
                                                </td>
                                            </tr>
                                        ) : (
                                            currentItems.map((item) => (
                                                <InventoryListItem
                                                    key={item.id}
                                                    item={item}
                                                    onDeleteClick={() => handleDeleteClick(item.id)}
                                                    onItemUpdate={handleItemUpdate}
                                                />
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {currentItems.length === 0 ? (
                                <div className="col-span-full text-center text-gray-500 py-8">
                                    No inventory items found
                                </div>
                            ) : (
                                currentItems.map((item) => (
                                    <ProductCard
                                        key={item.id}
                                        item={item}
                                        onDeleteClick={() => handleDeleteClick(item.id)}
                                        onItemUpdate={handleItemUpdate}
                                    />
                                ))
                            )}
                        </div>
                    )}

                    <div className="mt-6 flex items-center justify-between border-t pt-4">
                        <div className="text-sm text-gray-500">
                            Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredInventory.length)} of {filteredInventory.length} items
                        </div>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className="px-4 py-2 border rounded-lg text-sm text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
                            >
                                Previous
                            </button>
                            <button
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className="px-4 py-2 border rounded-lg text-sm text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ProductAddModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSave={handleAddItem}
                storeId={user?.storeId}
            />

            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                isDeleting={isDeleting}
            />
        </div>
    );
};

export default InventoryItems;