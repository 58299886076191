import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { Search, ShoppingCart, Plus, Minus, X, Barcode, User, Clock, ArrowLeft, Grid, List, Lock, Unlock, User2, Package, Loader2 } from 'lucide-react';
import {
    query,
    collection,
    orderBy,
    getDocs,
    getDoc,
    doc,
    getFirestore,
    limit,
    serverTimestamp,
    updateDoc,
    setDoc,
    writeBatch,
    increment,
    arrayUnion
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import CustomerSection from '../components/customer/customer_section_pos';

// Constants
const ITEMS_PER_PAGE = 20;
const NOTIFICATION_DURATION = 3000;

const AddSale = () => {
    // Core state
    const [saleItems, setSaleItems] = useState([]);
    const [allInventoryItems, setAllInventoryItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isScannerEnabled, setIsScannerEnabled] = useState(false);
    const [scanBuffer, setScanBuffer] = useState('');
    const [isTestMode, setIsTestMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [viewMode, setViewMode] = useState('list');
    const [isLocked, setIsLocked] = useState(false);
    const [showExitPrompt, setShowExitPrompt] = useState(false);

    const [allItems, setAllItems] = useState([]);

    // Customer and payment state
    const [customer, setCustomer] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [amountReceived, setAmountReceived] = useState('');
    const [change, setChange] = useState(0);

    // UI state
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    // Filter state
    const [categories] = useState(['All']);
    const [selectedCategory, setSelectedCategory] = useState('All');

    // Notifications state
    const [notifications, setNotifications] = useState([]);
    const notificationIdCounter = useRef(0);
    const scanTimeout = useRef(null);
    const amountReceivedInputRef = useRef(null);

    const [storeId, setStoreId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isInitializing, setIsInitializing] = useState(true);

    const [currentTime, setCurrentTime] = useState(new Date());

    // Firebase setup
    const db = getFirestore();
    const auth = getAuth();

    // Modified intersection observer setup
    const observer = useRef();
    const lastItemRef = useCallback(node => {
        if (isLoadingMore || !hasMore) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setIsLoadingMore(true);
                setCurrentPage(prev => prev + 1);
            }
        });

        if (node) observer.current.observe(node);
    }, [isLoadingMore, hasMore]);

    // Notifications
    const addNotification = (message, type = 'error') => {
        const id = notificationIdCounter.current++;
        setNotifications(prev => [...prev, { id, message, type }]);
        setTimeout(() => {
            setNotifications(prev => prev.filter(n => n.id !== id));
        }, NOTIFICATION_DURATION);
    };

    const showError = (message) => addNotification(message, 'error');
    const showSuccess = (message) => addNotification(message, 'success');
    const [imageLoadStatus, setImageLoadStatus] = useState({});

    // Function to handle image loading errors
    const handleImageError = (itemId) => {
        setImageLoadStatus(prev => ({
            ...prev,
            [itemId]: 'error'
        }));
    };

    // Function to handle successful image loads
    const handleImageLoad = (itemId) => {
        setImageLoadStatus(prev => ({
            ...prev,
            [itemId]: 'loaded'
        }));
    };

    // Load user and store data on component mount
    useEffect(() => {
        const initializeData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error('No authenticated user found');

                const userDocRef = doc(db, 'Users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);

                if (!userDoc.exists()) throw new Error('User document not found');

                const userStoreId = userDoc.data().storeId;
                if (!userStoreId) throw new Error('No store ID found for user');

                setUserId(currentUser.uid);
                setStoreId(userStoreId);
            } catch (error) {
                console.error('Initialization error:', error);
                showError(error.message);
                // Could redirect to login or show error state
            } finally {
                setIsInitializing(false);
            }
        };

        initializeData();
    }, []);

    useEffect(() => {
        if (!allInventoryItems.length) return;

        const filterItems = () => {
            let filtered = [...allInventoryItems];

            if (searchQuery) {
                const searchLower = searchQuery.toLowerCase().trim();
                filtered = filtered.filter(item =>
                    (item.itemName?.toLowerCase() || '').includes(searchLower) ||
                    (item.sku?.toLowerCase() || '').includes(searchLower) ||
                    (item.barcode?.toLowerCase() || '').includes(searchLower)
                );
            }

            if (selectedCategory !== 'All') {
                filtered = filtered.filter(item =>
                    (item.category || 'Uncategorized') === selectedCategory
                );
            }

            const startIndex = 0;
            const endIndex = currentPage * ITEMS_PER_PAGE;
            return filtered.slice(startIndex, endIndex);
        };

        const newDisplayedItems = filterItems();
        if (JSON.stringify(newDisplayedItems) !== JSON.stringify(displayedItems)) {
            setDisplayedItems(newDisplayedItems);
            setHasMore(newDisplayedItems.length < allInventoryItems.length);
        }
        setIsLoadingMore(false);
    }, [searchQuery, selectedCategory, currentPage, allInventoryItems]);

    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
        setDisplayedItems([]);
    }, [searchQuery, selectedCategory]);

    // Load initial data
    useEffect(() => {
        const loadInventory = async () => {
            try {
                // Check cache first
                const cachedInventory = localStorage.getItem('inventoryItems');
                const cachedTimestamp = localStorage.getItem('inventoryTimestamp');

                let items = [];
                if (cachedInventory && cachedTimestamp) {
                    const isStale = Date.now() - parseInt(cachedTimestamp) > 3600000; // 1 hour
                    if (!isStale) {
                        items = JSON.parse(cachedInventory);
                        console.log('Loading from cache:', items.length, 'items');
                    }
                }

                // If no cache or stale, fetch from Firestore
                if (items.length === 0) {
                    const userId = auth.currentUser?.uid;
                    if (!userId) throw new Error('No authenticated user found');

                    const userDoc = await getDoc(doc(db, 'Users', userId));
                    if (!userDoc.exists()) throw new Error('User document not found');

                    const storeId = userDoc.data().storeId;
                    if (!storeId) throw new Error('No store ID found for user');

                    const snapshot = await getDocs(collection(db, 'Stores', storeId, 'Inventory'));
                    items = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            ...data,
                            hasImage: !!data.imageUrl,
                            imageUrl: data.imageUrl || null
                        };
                    });

                    // Cache the fresh data
                    localStorage.setItem('inventoryItems', JSON.stringify(items));
                    localStorage.setItem('inventoryTimestamp', Date.now().toString());
                }

                setAllItems(items);
                setDisplayedItems(items);
                console.log('Items loaded:', items.length);
            } catch (error) {
                console.error('Error loading inventory:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadInventory();
    }, []);

    // Handle search
    useEffect(() => {
        if (!searchQuery.trim()) {
            setDisplayedItems(allItems);
            return;
        }

        const query = searchQuery.toLowerCase().trim();
        const filtered = allItems.filter(item =>
            (item.itemName?.toLowerCase() || '').includes(query) ||
            (item.sku?.toLowerCase() || '').includes(query) ||
            (item.barcode?.toLowerCase() || '').includes(query)
        );

        setDisplayedItems(filtered);
        console.log('Filtered items:', filtered.length);
    }, [searchQuery, allItems]);

    // Clock update
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    // Calculate totals whenever cart changes
    useEffect(() => {
        const total = saleItems.reduce((sum, item) => sum + (item.sellingPrice * item.quantity), 0);
        setTotalCost(total);

        if (amountReceived && paymentMethod === 'cash') {
            setChange(Math.max(0, amountReceived - total));
        }
    }, [saleItems, amountReceived]);

    // Modify the existing scanner useEffect
    useEffect(() => {
        if (!isScannerEnabled) return;

        const handleKeyPress = (e) => {
            // Existing scanner code...

            // Add this new Enter key handler
            if (e.key === 'Enter' && saleItems.length > 0 && !showPaymentModal) {
                setShowPaymentModal(true);
                e.preventDefault();
            }
        };

        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
            if (scanTimeout.current) clearTimeout(scanTimeout.current);
        };
    }, [isScannerEnabled, scanBuffer, allInventoryItems, isTestMode, saleItems.length, showPaymentModal]);

    useEffect(() => {
        if (showPaymentModal && amountReceivedInputRef.current) {
            amountReceivedInputRef.current.focus();
        }
    }, [showPaymentModal]);

    useEffect(() => {
        const shouldPreventNavigation = isLocked || saleItems.length > 0;

        if (shouldPreventNavigation) {
            const handleBeforeUnload = (e) => {
                e.preventDefault();
                e.returnValue = '';
                return '';
            };

            const handlePopState = (e) => {
                e.preventDefault();
                setShowExitPrompt(true);
                // Push a new state to prevent immediate navigation
                window.history.pushState(null, '', window.location.pathname);
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            // Push initial state
            window.history.pushState(null, '', window.location.pathname);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
            };
        }
    }, [isLocked, saleItems.length]);

    const ItemImage = ({ item, className }) => {
        const shouldShowPlaceholder = !item.imageUrl || imageLoadStatus[item.id] === 'error';

        if (shouldShowPlaceholder) {
            return (
                <div className={`${className} bg-gray-100 flex items-center justify-center`}>
                    <Package className="text-gray-400" size={28} />
                </div>
            );
        }

        return (
            <div className={`relative ${className}`}>
                <img
                    src={item.imageUrl}
                    alt={item.itemName}
                    className="w-full h-full object-cover"
                    onError={() => handleImageError(item.id)}
                    onLoad={() => handleImageLoad(item.id)}
                />
            </div>
        );
    };

    const handleExit = () => {
        if (window.confirm('Are you sure you want to exit? Any unsaved changes will be lost.')) {
            setIsLocked(false);
            window.history.back();
        }
    };

    // Cart functions
    const addToSale = useCallback((item) => {
        setSaleItems(prevItems => {
            const existingItem = prevItems.find(i => i.id === item.id);
            if (existingItem) {
                return prevItems.map(i =>
                    i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
                );
            }
            return [...prevItems, { ...item, quantity: 1 }];
        });
    }, []);

    const updateQuantity = (itemId, newQuantity, event) => {
        if (event) {
            event.stopPropagation();
        }

        setSaleItems(prevItems => {
            return prevItems.map(item => {
                if (item.id !== itemId) return item;

                // Only validate that quantity is not negative
                const validatedQuantity = Math.max(0, newQuantity);

                if (validatedQuantity === 0) return null;

                return {
                    ...item,
                    quantity: validatedQuantity
                };
            }).filter(Boolean);
        });
    };

    const removeFromSale = (itemId, event) => {
        if (event) {
            event.stopPropagation();
        }
        setSaleItems(items => items.filter(item => item.id !== itemId));
    };

    const updateCustomerRecord = async (storeId, customerId, saleId, orderNumber) => {
        try {
            const customerRef = doc(db, 'Stores', storeId, 'Customers', customerId);
            await updateDoc(customerRef, {
                totalPurchases: increment(1),
                totalSpent: increment(totalCost),
                lastPurchaseDate: serverTimestamp(),
                lastPurchaseAmount: totalCost,
                purchases: arrayUnion({
                    saleId,
                    orderNumber,
                    amount: totalCost,
                    itemCount: saleItems.reduce((sum, item) => sum + item.quantity, 0),
                    date: new Date()
                })
            });
        } catch (error) {
            console.error('Error updating customer record:', error);
        }
    };

    // Separate function for non-critical inventory updates
    const updateInventory = async (storeId, items) => {
        try {
            // First get all current quantities
            const inventorySnapshots = await Promise.all(
                items.map(item =>
                    getDoc(doc(db, 'Stores', storeId, 'Inventory', item.inventoryItemId))
                )
            );

            const batch = writeBatch(db);

            // Process each item
            items.forEach((item, index) => {
                const inventoryDoc = inventorySnapshots[index];
                if (!inventoryDoc.exists()) {
                    console.error(`Inventory item ${item.inventoryItemId} not found`);
                    return;
                }

                const currentQuantity = inventoryDoc.data().quantity || 0;
                const newQuantity = Math.max(0, currentQuantity - item.quantity);

                // If we're trying to reduce below 0, log it
                if (currentQuantity < item.quantity) {
                    console.warn(`Warning: Attempted to reduce inventory below 0 for item ${item.inventoryItemId}. Current: ${currentQuantity}, Requested: ${item.quantity}`);
                }

                const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', item.inventoryItemId);
                batch.update(inventoryRef, {
                    quantity: newQuantity
                });
            });

            await batch.commit();
        } catch (error) {
            console.error('Error updating inventory:', error);
            // Here you could add proper error reporting
            // reportInventoryError({ items, error, storeId });
        }
    };

    const handlePayment = async () => {
        // Return early if not properly initialized
        if (!storeId || !userId) {
            showError('System not properly initialized');
            return;
        }

        setIsProcessing(true);
        try {
            const orderNumber = await generateOrderNumber(storeId);

            // Create the sale transaction
            const saleTransaction = {
                orderNumber,
                items: saleItems.map(item => ({
                    costPrice: item.costPrice,
                    inventoryItemId: item.id,
                    itemName: item.itemName,
                    price: item.sellingPrice,
                    quantity: item.quantity,
                    sku: item.sku
                })),
                totalCost,
                saleDate: new Date(),
                createdAt: serverTimestamp(),
                userId,
                isCreditSale: false,
                isPaid: true,
                paymentMethod,
                amountReceived: parseFloat(amountReceived),
                change,
                cashierId: userId,
                storeId,
                ...(customer && {
                    customerId: customer.id,
                    customerName: customer.name
                })
            };

            // Create the sale record
            const saleRef = doc(collection(db, 'Stores', storeId, 'Sales'));
            await setDoc(saleRef, saleTransaction);

            // Reset UI state immediately
            setShowPaymentModal(false);
            setSaleItems([]);
            setAmountReceived('');
            setChange(0);
            setCustomer(null);

            showSuccess('Sale completed successfully!');

            // Trigger non-critical updates asynchronously
            Promise.allSettled([
                updateInventory(
                    storeId,
                    saleTransaction.items
                ),
                customer && updateCustomerRecord(
                    storeId,
                    customer.id,
                    saleRef.id,
                    orderNumber
                )
            ]).catch(console.error);

        } catch (err) {
            console.error('Payment error:', err);
            showError(`Failed to process payment: ${err.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    // Helper function to generate order number
    const generateOrderNumber = async (storeId) => {
        const salesRef = collection(db, 'Stores', storeId, 'Sales');
        const q = query(salesRef, orderBy('createdAt', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return 'ORD-0001';
        } else {
            const lastDoc = querySnapshot.docs[0];
            const lastOrderNumber = lastDoc.data().orderNumber;
            const lastNumber = parseInt(lastOrderNumber.split('-')[1]);
            const newNumber = lastNumber + 1;
            return `ORD-${newNumber.toString().padStart(4, '0')}`;
        }
    };

    // const handlePayment = async () => {
    //     setIsProcessing(true);
    //     try {
    //         const userId = auth.currentUser?.uid;
    //         if (!userId) {
    //             throw new Error('No authenticated user found');
    //         }

    //         const userDoc = await getDoc(doc(db, 'Users', userId));
    //         if (!userDoc.exists()) {
    //             throw new Error('User document not found');
    //         }

    //         const storeId = userDoc.data().storeId;
    //         if (!storeId) {
    //             throw new Error('No store ID found for user');
    //         }

    //         const orderNumber = await generateOrderNumber(storeId);

    //         const saleTransaction = {
    //             orderNumber,
    //             items: saleItems.map(item => ({
    //                 costPrice: item.costPrice,
    //                 inventoryItemId: item.id,
    //                 itemName: item.itemName,
    //                 price: item.sellingPrice,
    //                 quantity: item.quantity,
    //                 sku: item.sku
    //             })),
    //             totalCost,
    //             saleDate: new Date(),
    //             createdAt: serverTimestamp(),
    //             userId,
    //             isCreditSale: false,
    //             isPaid: true,
    //             paymentMethod,
    //             amountReceived: parseFloat(amountReceived),
    //             change,
    //             cashierId: userId,
    //             storeId
    //         };

    //         // Perform transaction with zero-floor inventory handling
    //         const result = await runTransaction(db, async (transaction) => {
    //             // Get all inventory documents first
    //             const inventoryDocs = await Promise.all(
    //                 saleItems.map(async item => {
    //                     const docRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
    //                     const docSnap = await transaction.get(docRef);
    //                     if (!docSnap.exists()) {
    //                         throw new Error(`Inventory item ${item.id} not found`);
    //                     }
    //                     return docSnap;
    //                 })
    //             );

    //             // Create the sale document
    //             const saleRef = doc(collection(db, 'Stores', storeId, 'Sales'));
    //             transaction.set(saleRef, saleTransaction);

    //             // Update inventory quantities with zero floor
    //             saleItems.forEach((item, index) => {
    //                 const inventoryDoc = inventoryDocs[index];
    //                 const currentQuantity = inventoryDoc.data().quantity;
    //                 const soldQuantity = item.quantity;

    //                 // Ensure quantity never goes below 0
    //                 const newQuantity = Math.max(0, currentQuantity - soldQuantity);

    //                 // If current quantity was insufficient, log it in the transaction
    //                 if (currentQuantity < soldQuantity) {
    //                     const shortageAmount = soldQuantity - currentQuantity;
    //                     console.warn(`Inventory shortage for item ${item.itemName}: ${shortageAmount} units`);
    //                 }

    //                 const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
    //                 transaction.update(inventoryRef, { quantity: newQuantity });
    //             });

    //             return { saleRef, saleTransaction };
    //         });

    //         // Verify the sale was created
    //         const verifyDoc = await getDoc(result.saleRef);
    //         if (!verifyDoc.exists()) {
    //             throw new Error('Sale document was not created');
    //         }

    //         showSuccess('Sale completed successfully!');

    //         // Reset state
    //         setShowPaymentModal(false);
    //         setSaleItems([]);
    //         setAmountReceived('');
    //         setChange(0);

    //     } catch (err) {
    //         console.error('Payment error:', err);
    //         showError(`Failed to process payment: ${err.message}`);
    //     } finally {
    //         setIsProcessing(false);
    //     }
    // };

    const estimateCardHeight = (item) => {
        const baseHeight = 200; // Base height for image and padding
        const nameLength = item.itemName.length;
        const lineHeight = 20;
        const charsPerLine = 20;
        const estimatedLines = Math.ceil(nameLength / charsPerLine);
        return baseHeight + (estimatedLines * lineHeight);
    };

    // Modify the header section to include lock toggle
    const Header = (
        <div className="bg-white border-b px-6 py-4">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    {!isLocked && (
                        <Link
                            to="/dashboard"
                            className="mr-4 text-gray-500 hover:text-gray-700 transition-colors"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </Link>
                    )}
                    <h1 className="text-2xl font-bold">Point of Sale</h1>
                </div>
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => setIsLocked(!isLocked)}
                        className={`flex items-center space-x-1 px-3 py-1.5 rounded-lg transition-colors ${isLocked
                            ? 'bg-red-100 text-red-700 hover:bg-red-200'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                    >
                        {isLocked ? <Lock size={16} /> : <Unlock size={16} />}
                        <span>{isLocked ? 'Unlock' : 'Lock'}</span>
                    </button>
                    <div className="flex items-center space-x-2">
                        <Clock className="text-gray-400" size={20} />
                        <span className="text-sm">
                            {currentTime.toLocaleTimeString()}
                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <User className="text-gray-400" size={20} />
                        <span className="text-sm">{auth.currentUser?.email}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    // Add exit confirmation modal
    const ExitPromptModal = showExitPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Exit</h2>
                <p className="mb-6">Are you sure you want to exit? Any unsaved changes will be lost.</p>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={() => setShowExitPrompt(false)}
                        className="px-4 py-2 border border-gray-300 rounded-lg"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleExit}
                        className="px-4 py-2 bg-red-500 text-white rounded-lg"
                    >
                        Exit
                    </button>
                </div>
            </div>
        </div>
    );

    const ListItem = memo(({ item, lastItemRef, onAddToSale }) => (
        <div
            ref={lastItemRef}
            onClick={() => onAddToSale(item)}
            className="bg-white p-3 rounded-lg border hover:ring-1 hover:ring-orange-400 cursor-pointer transition-all flex space-x-3"
        >
            <ItemImage item={item} className="w-12 h-12 rounded-lg overflow-hidden flex-shrink-0" />
            <div className="flex-1 min-w-0">
                <h3 className="font-semibold text-sm leading-tight break-words">{item.itemName}</h3>
                <div className="text-xs text-gray-500 mt-0.5">SKU: {item.sku}</div>
                <div className="flex items-center justify-between mt-1">
                    <div className="text-xs text-orange-500">{item.quantity} in stock</div>
                    <div className="font-bold text-sm">GHS {item.sellingPrice.toFixed(2)}</div>
                </div>
            </div>
        </div>
    ));

    // Modified list view rendering
    const renderListView = useCallback(() => (
        <div className="space-y-2 p-1">
            {displayedItems.map((item, index) => (
                <ListItem
                    key={item.id}
                    item={item}
                    lastItemRef={index === displayedItems.length - 1 ? lastItemRef : null}
                    onAddToSale={addToSale}
                />
            ))}
        </div>
    ), [displayedItems, lastItemRef, addToSale]);

    const GridItem = memo(({ item, lastItemRef, onAddToSale }) => (
        <div
            ref={lastItemRef}
            onClick={() => onAddToSale(item)}
            className="bg-white rounded-lg border hover:ring-1 hover:ring-orange-400 cursor-pointer transition-all"
        >
            <div className="p-3">
                <ItemImage
                    item={item}
                    className="aspect-square w-full mb-3 rounded-lg overflow-hidden"
                />
                <div className="flex flex-col mt-1">
                    <h3 className="font-semibold text-sm leading-tight break-words">
                        {item.itemName}
                    </h3>
                    <div className="flex items-center justify-between mt-4">
                        <div className="text-xs text-orange-500">
                            {item.quantity} left
                        </div>
                        <div className="font-bold text-sm">
                            GHS {item.sellingPrice.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));

    // Memoized grid view rendering
    const renderGridView = useCallback(() => (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
            {displayedItems.map((item, index) => (
                <GridItem
                    key={item.id}
                    item={item}
                    lastItemRef={index === displayedItems.length - 1 ? lastItemRef : null}
                    onAddToSale={addToSale}
                />
            ))}
        </div>
    ), [displayedItems, lastItemRef, addToSale]);

    // Show loading state while initializing
    if (isInitializing) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="text-center flex flex-col items-center ">
                    <Loader2 className="animate-spin rounded-full h-12 w-12" />
                    <p className="mt-2 text-gray-600">Initializing...</p>
                </div>
            </div>
        );
    }

    // Show error state if initialization failed
    if (!storeId || !userId) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-xl font-bold text-red-600 mb-2">Initialization Error</h2>
                    <p className="text-gray-600">Unable to load required data. Please refresh or contact support.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            {Header}

            {/* Main Content Area */}
            <div className="flex-1 flex overflow-hidden">
                {/* Left Side - Products */}
                <div className="w-2/3 p-6 overflow-hidden flex flex-col">
                    <div className="flex justify-between items-center mb-3 px-1.5">
                        <div className="mb-4 mr-7 w-full">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search products..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full pl-10 pr-4 py-3 border-2 border-gray-500 rounded-lg"
                                />
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                            </div>
                        </div>
                        <div className="inline-flex border border-gray-300 mb-4">
                            <button
                                onClick={() => setViewMode('list')}
                                className={`p-1.5 rounded-l-lg ${viewMode === 'list'
                                    ? 'bg-gray-100 text-orange-500'
                                    : 'hover:bg-gray-50'
                                    }`}
                            >
                                <List size={16} />
                            </button>
                            <button
                                onClick={() => setViewMode('grid')}
                                className={`p-1.5 rounded-r-lg border-l border-gray-300 ${viewMode === 'grid'
                                    ? 'bg-gray-100 text-orange-500'
                                    : 'hover:bg-gray-50'
                                    }`}
                            >
                                <Grid size={16} />
                            </button>
                        </div>
                    </div>

                    <div className="flex-1 overflow-y-auto">
                        {isLoading ? (
                            <div className="flex items-center justify-center h-full">
                                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                            </div>
                        ) : viewMode === 'list' ? (
                            renderListView()
                        ) : (
                            renderGridView()
                        )}

                    </div>
                </div>

                {/* Right Side - Cart */}
                <div className="w-1/3 border-l bg-white p-6 flex flex-col">
                    <div className="mb-4 flex items-center justify-between">
                        <h2 className="text-xl font-bold">Current Sale</h2>
                        <div className="flex items-center space-x-4">
                            <div className="">
                                <CustomerSection customer={customer} setCustomer={setCustomer} />
                            </div>
                            <div className="flex items-center space-x-2">
                                <button
                                    onClick={() => setIsScannerEnabled(!isScannerEnabled)}
                                    className={`flex items-center space-x-2 px-3 py-1.5 rounded ${isScannerEnabled ? 'bg-blue-100 text-blue-500' : 'bg-gray-100 text-gray-400'
                                        }`}
                                >
                                    <Barcode size={20} />
                                    <span className="text-sm">
                                        {isScannerEnabled ? 'Scanner On' : 'Scanner Off'}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 overflow-y-auto mb-4">
                        {saleItems.length === 0 ? (
                            <div className="text-center text-gray-500 py-8">
                                <ShoppingCart size={48} className="mx-auto mb-4" />
                                <p className="text-lg mb-2">No items in cart</p>
                                <p className="text-sm text-gray-400">
                                    Add items from the inventory or scan barcode
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-2">
                                {saleItems.map(item => (
                                    <div
                                        key={item.id}
                                        className="flex items-center justify-between p-3 bg-gray-50 rounded-lg border border-gray-200"
                                    >
                                        <div className="flex-1">
                                            <h4 className="font-medium">{item.itemName}</h4>
                                            <div className="text-sm text-gray-500">
                                                GHS {item.sellingPrice.toFixed(2)} each
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <button
                                                onClick={(e) => updateQuantity(item.id, item.quantity - 1, e)}
                                                className="p-1 rounded-full hover:bg-gray-200 transition-colors"
                                            >
                                                <Minus size={16} />
                                            </button>
                                            <span className="w-8 text-center">{item.quantity}</span>
                                            <button
                                                onClick={(e) => updateQuantity(item.id, item.quantity + 1, e)}
                                                className="p-1 rounded-full hover:bg-gray-200 transition-colors"
                                            >
                                                <Plus size={16} />
                                            </button>
                                            <button
                                                onClick={(e) => removeFromSale(item.id, e)}
                                                className="p-1 text-red-500 hover:bg-red-50 rounded-full transition-colors"
                                            >
                                                <X size={16} />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    {saleItems.length > 0 && (
                        <div className="border-t pt-4 space-y-4">
                            <div className="space-y-2">
                                <div className="flex justify-between items-center text-sm text-gray-600">
                                    <span>Subtotal</span>
                                    <span>GHS {totalCost.toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between items-center text-sm text-gray-600">
                                    <span>Total Items</span>
                                    <span>{saleItems.reduce((sum, item) => sum + item.quantity, 0)} items</span>
                                </div>
                                <div className="flex justify-between items-center text-lg font-bold">
                                    <span>Total</span>
                                    <span>GHS {totalCost.toFixed(2)}</span>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-2">
                                <button
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to clear the cart?')) {
                                            setSaleItems([]);
                                        }
                                    }}
                                    className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                                >
                                    Clear Sale
                                </button>
                                <button
                                    onClick={() => setShowPaymentModal(true)}
                                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                                >
                                    Complete Sale
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Payment Modal */}
            {showPaymentModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-xl font-bold">Complete Payment</h2>
                            <button
                                onClick={() => setShowPaymentModal(false)}
                                className="text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <div className="space-y-6">
                            <div className="text-lg font-semibold flex justify-between">
                                <span>Total Amount:</span>
                                <span>GHS {totalCost.toFixed(2)}</span>
                            </div>

                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700">Payment Method</label>
                                <div className="grid grid-cols-3 gap-2">
                                    {['cash', 'card', 'mobile_money'].map(method => (
                                        <button
                                            key={method}
                                            onClick={() => setPaymentMethod(method)}
                                            className={`p-2 rounded-lg border transition-colors ${paymentMethod === method
                                                ? 'bg-blue-500 text-white border-blue-500'
                                                : 'bg-white border-gray-300 hover:bg-gray-50'
                                                }`}
                                        >
                                            {method.replace('_', ' ').toUpperCase()}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {paymentMethod === 'cash' && (
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Amount Received
                                    </label>
                                    <input
                                        type="number"
                                        value={amountReceived}
                                        onChange={(e) => setAmountReceived(e.target.value)}
                                        className="w-full p-4 border text-xl rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter amount"
                                        ref={amountReceivedInputRef}
                                    />
                                    {Number(amountReceived) > totalCost && (
                                        <div className="mt-4 p-3 bg-green-50 border-2 border-green-200 rounded-lg">
                                            <div className="flex justify-between items-center">
                                                <span className="text-lg font-semibold text-green-700">Change Due:</span>
                                                <span className="text-xl font-bold text-green-700">
                                                    GHS {(Number(amountReceived) - totalCost).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <button
                                onClick={handlePayment}
                                disabled={isProcessing || (paymentMethod === 'cash' && Number(amountReceived) < totalCost)}
                                className={`w-full p-3 rounded-lg text-white transition-colors ${isProcessing || (paymentMethod === 'cash' && Number(amountReceived) < totalCost)
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-green-500 hover:bg-green-600'
                                    }`}
                            >
                                {isProcessing ? 'Processing...' : 'Complete Payment'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Notifications */}
            <div className="fixed top-4 right-4 space-y-2">
                {notifications.map(({ id, message, type }) => (
                    <div key={id}
                        className={`px-4 py-3 rounded-lg shadow-lg max-w-md animate-fade-in ${type === 'error'
                            ? 'bg-red-100 border border-red-400 text-red-700'
                            : 'bg-green-100 border border-green-400 text-green-700'
                            }`}
                    >
                        {message}
                    </div>
                ))}
            </div>

            {ExitPromptModal}
        </div>
    );
};

export default AddSale;