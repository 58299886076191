import React, { useState } from 'react';
import { ArrowLeft, Send, HelpCircle } from 'lucide-react';

const Support = () => {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('general');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            // Simulated API call
            console.log('Support ticket submitted:', {
                email,
                subject,
                description,
                category,
                timestamp: new Date()
            });
            setSubmitted(true);
        } catch (error) {
            console.error('Error submitting ticket:', error);
            setError('Failed to submit support request. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            {/* Header */}
            <header className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg fixed w-full z-10">
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    <a href="/" className="flex items-center text-red-400 hover:text-red-300 transition duration-300">
                        <ArrowLeft className="w-5 h-5 mr-2" />
                        Back to Home
                    </a>
                    <h1 className="text-2xl font-bold">HotKiosk Support</h1>
                </div>
            </header>

            {/* Main Content */}
            <main className="container mx-auto pt-24 pb-12 px-4">
                <section className="max-w-3xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8">
                    <div className="flex items-center mb-6">
                        <HelpCircle className="w-8 h-8 text-red-400 mr-3" />
                        <h2 className="text-3xl font-bold">Submit Support Request</h2>
                    </div>

                    {!submitted ? (
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {error && (
                                <div className="p-4 bg-[#FF6B35] bg-opacity-20 border border-[#FF6B35] rounded-md text-red-400">
                                    {error}
                                </div>
                            )}
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium mb-2">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                                    placeholder="Enter your email"
                                />
                            </div>
                            <div>
                                <label htmlFor="category" className="block text-sm font-medium mb-2">Request Category</label>
                                <select
                                    id="category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                                >
                                    <option value="general">General Inquiry</option>
                                    <option value="technical">Technical Issue</option>
                                    <option value="billing">Billing Question</option>
                                    <option value="feature">Feature Request</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="subject" className="block text-sm font-medium mb-2">Subject</label>
                                <input
                                    type="text"
                                    id="subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                                    placeholder="Brief description of your issue"
                                />
                            </div>
                            <div>
                                <label htmlFor="description" className="block text-sm font-medium mb-2">Detailed Description</label>
                                <textarea
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                                    rows="6"
                                    placeholder="Please provide as much detail as possible about your issue or request"
                                ></textarea>
                            </div>
                            <div>
                                <button type="submit" className="flex items-center justify-center w-full px-4 py-2 bg-[#FF6B35] text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 transition duration-300">
                                    <Send className="w-5 h-5 mr-2" />
                                    Submit Support Request
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className="text-center">
                            <h3 className="text-2xl font-semibold mb-4 text-green-400">Support Request Submitted Successfully</h3>
                            <p>We've received your request and will respond to your email address shortly. Thank you for your patience.</p>
                        </div>
                    )}

                    <div className="mt-8 p-4 bg-gray-700 rounded-md">
                        <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">What to Expect</h3>
                        <ul className="list-disc list-inside space-y-2">
                            <li>We typically respond within 24 hours during business days</li>
                            <li>Complex technical issues may require additional follow-up</li>
                            <li>Please check your spam folder if you don't receive our response</li>
                        </ul>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto text-center text-gray-400">
                    <p>&copy; 2024 HotKiosk. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Support;