import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Package, AlertTriangle, ShoppingCart, Search, Plus, ArrowRight, Loader2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import {
    collection,
    query,
    where,
    orderBy,
    limit,
    getDocs,
    getFirestore,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';

const Inventory = () => {
    const navigate = useNavigate();
    const [inventoryData, setInventoryData] = useState({
        totalItems: 0,
        inventory: [],
        isLoading: true,
        error: null,
        stats: {
            totalItems: 0,
            lowStockItems: 0,
            outOfStockItems: 0,
            totalValue: 0
        }
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [user, setUser] = useState(null);
    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setInventoryData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to authenticate user'
                }));
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db]);

    useEffect(() => {
        const fetchInventoryData = async () => {
            if (!user?.storeId) return;

            try {
                // First, fetch all inventory for stats
                const fullInventoryQuery = query(
                    collection(db, 'Stores', user.storeId, 'Inventory')
                );

                const fullInventorySnapshot = await getDocs(fullInventoryQuery);
                let totalValue = 0;
                let lowStockCount = 0;
                let outOfStockCount = 0;
                let totalItems = 0;

                fullInventorySnapshot.forEach(doc => {
                    const item = doc.data();
                    totalItems++;

                    // Calculate item value
                    const itemValue = item.quantity * item.sellingPrice || 0;
                    totalValue += itemValue;

                    // Check stock status
                    if (item.quantity === 0) {
                        outOfStockCount++;
                    } else if (item.quantity <= item.reorderPoint) {
                        lowStockCount++;
                    }
                });

                // Then, fetch recent items for display
                const recentInventoryQuery = query(
                    collection(db, 'Stores', user.storeId, 'Inventory'),
                    orderBy('updatedAt', 'desc'),
                    limit(7)
                );

                const recentInventorySnapshot = await getDocs(recentInventoryQuery);
                const recentInventory = recentInventorySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setInventoryData({
                    inventory: recentInventory,
                    isLoading: false,
                    error: null,
                    stats: {
                        totalItems,
                        lowStockItems: lowStockCount,
                        outOfStockItems: outOfStockCount,
                        totalValue
                    }
                });
            } catch (error) {
                console.error('Error fetching inventory data:', error);
                setInventoryData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load inventory data'
                }));
            }
        };

        fetchInventoryData();
    }, [user, db]);

    const filteredInventory = inventoryData.inventory
        .filter(item =>
            item.itemName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.sku?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(0, 7);

    if (inventoryData.isLoading) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1">
                        <div className="flex flex-col items-center gap-3">
                            <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                            <div className="text-lg text-gray-600">Loading inventory data...</div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    if (inventoryData.error) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1 text-red-500">
                        <AlertTriangle className="mr-2 h-5 w-5" />
                        <div>{inventoryData.error}</div>
                    </div>
                </div>
            </div>
        );
    }

    const handleAddNewItem = () => {
        // Navigate to inventory list with query parameter
        navigate('/inventory/list?openAddModal=true');
    };

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center">
                        <h1 className="text-5xl font-semibold my-10">Inventory</h1>

                        <button onClick={handleAddNewItem}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors">
                            <Plus className="h-5 w-5 mr-2" />
                            Add New Item
                        </button>
                    </div>

                    <div className="mb-8">
                        <InventoryStats stats={inventoryData.stats} />
                    </div>

                    <div className="mb-4 flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Recent Items</h2>
                        <div className="flex space-x-2">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search inventory..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                    <Search className="h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border p-5">
                        <div className="overflow-x-auto">
                            <table className="w-full ">
                                <thead>
                                    <tr className=" bg-gray-100">
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Product</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">SKU</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Quantity</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Price</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Status</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {filteredInventory.length === 0 ? (
                                        <tr>
                                            <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                                No inventory items found
                                            </td>
                                        </tr>
                                    ) : (
                                        filteredInventory.map((item) => (
                                            <InventoryItem key={item.id} item={item} />
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="px-6 py-4 border-t">
                            <Link
                                to="/inventory/list"
                                className="flex items-center justify-center text-blue-600 hover:text-blue-800 font-medium"
                            >
                                See Full List
                                <ArrowRight className="ml-2 h-4 w-4" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StatCard = ({ title, value, subtitle, icon }) => (
    <div className="bg-white border p-4 w-full">
        <div className="flex items-center justify-between mb-1">
            <h3 className="text-sm text-gray-500">{title}</h3>
            {icon && <div className="text-gray-400">{icon}</div>}
        </div>
        <p className="text-2xl font-bold mb-1 flex items-baseline">{value}</p>
        {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
    </div>
);

const InventoryStats = ({ stats }) => (
    <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <StatCard
                title="Total Items"
                value={stats.totalItems}
                subtitle="In inventory"
                icon={<Package className="h-6 w-6" />}
            />
            <StatCard
                title="Low Stock"
                value={stats.lowStockItems}
                subtitle="Below threshold"
                icon={<AlertTriangle className="h-6 w-6" />}
            />
            <StatCard
                title="Out of Stock"
                value={stats.outOfStockItems}
                subtitle="Need reorder"
                icon={<ShoppingCart className="h-6 w-6" />}
            />
            <StatCard
                title="Total Value"
                value={<>
                    <span className="mr-1">GHS</span>
                    <span>{stats.totalValue.toFixed(2)}</span>
                </>}
                subtitle="Inventory worth"
                icon={<Package className="h-6 w-6" />}
            />
        </div>
    </div>
);


const InventoryItem = ({ item }) => {
    let statusColor = "bg-green-100 text-green-800";
    let status = "In Stock";

    if (item.quantity === 0) {
        statusColor = "bg-red-100 text-red-800";
        status = "Out of Stock";
    } else if (item.quantity <= item.reorderPoint) {
        statusColor = "bg-yellow-100 text-yellow-800";
        status = "Low Stock";
    }

    return (
        <tr className="hover:bg-gray-50">
            <td className="px-6 py-4 text-sm text-gray-900">
                {item.itemName}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500">
                {item.sku || 'N/A'}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500">
                {item.quantity}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500">
                <span className="text-xs mr-1">GHS</span>
                <span className="text-gray-900">{item.sellingPrice?.toFixed(2) || '0.00'}</span>
            </td>
            <td className="px-6 py-4">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColor}`}>
                    {status}
                </span>
            </td>
        </tr>
    );
};

export default Inventory;