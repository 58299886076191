import React, { useState } from 'react';
import { Package, BarChart2, DollarSign } from 'lucide-react';

// Import all screen images
import screen1 from '../../assets/screen2.png';
import screen2 from '../../assets/screen3.png';
import screen3 from '../../assets/screen4.png';

const PhoneMockup = () => {
    const [currentScreen, setCurrentScreen] = useState(0);

    const screens = [
        {
            image: screen1,
            title: "Hasle-Free Sales",
            description: "Quickly record your sales without expensive POS equipment or time consuming spreadsheets.",
            icon: <BarChart2 className="w-6 h-6 text-[#FF6B35]" />
        },
        {
            image: screen2,
            title: "Easy Stock Management",
            description: "Never run out of products that customers need. Track your inventory levels in real-time and keep your stock levels in check.",
            icon: <Package className="w-6 h-6 text-[#FF6B35]" />
        },
        {
            image: screen3,
            title: "Detailed Reports",
            description: "Generate comprehensive reports to make informed business decisions and track your store's financial performance.",
            icon: <DollarSign className="w-6 h-6 text-[#FF6B35]" />
        }
    ];

    return (
        <section id="mockups" className="py-20 bg-black">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Every Feature You Need</h2>

                <div className="flex flex-col lg:flex-row items-center justify-between max-w-6xl mx-auto">
                    {/* Phone Mockup */}
                    <div className="lg:w-1/2 mb-12 lg:mb-0">
                        <div className="relative mx-auto w-72 h-[630px] bg-black rounded-[3rem] border-8 border-gray-800 shadow-2xl overflow-hidden">
                            <img
                                src={screens[currentScreen].image}
                                alt="App Interface"
                                className="w-full h-full object-cover transition-all duration-500"
                            />
                        </div>
                    </div>

                    {/* Feature List */}
                    <div className="lg:w-1/2 space-y-8">
                        {screens.map((screen, index) => (
                            <div
                                key={index}
                                onClick={() => setCurrentScreen(index)}
                                className={`p-6 rounded-xl cursor-pointer transition-all duration-300 transform hover:scale-105 bg-gray-900
                                    ${currentScreen === index ? 'border-2 border-[#FF6B35] shadow-lg shadow-[#FF6B35]/10' : 'border-2 border-transparent'}`}
                            >
                                <div className="flex items-start space-x-4">
                                    <div className="flex-shrink-0">
                                        {screen.icon}
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-semibold mb-2">{screen.title}</h3>
                                        <p className="text-gray-400">{screen.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PhoneMockup;