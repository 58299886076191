import React, { useState } from 'react';
import { Settings as SettingsIcon, Store, Users, Printer, CreditCard } from 'lucide-react';
import Sidebar from '../components/sidebar';

// Import components
import AccountSettings from '../components/settings/account_settings';
import StoreManagement from '../components/settings/store_management';
import UserPermissions from '../components/settings/user_permissions';
import PrinterSettings from '../components/settings/printer_settings';
import SubscriptionSettings from '../components/settings/subscription';

const Settings = () => {
    const [selectedTab, setSelectedTab] = useState('Account');

    const tabs = [
        { id: 'Account', name: 'Account', icon: SettingsIcon, component: <AccountSettings /> },
        { id: 'Store_Management', name: 'Store Management', icon: Store, component: <StoreManagement /> },
        { id: 'User_Permissions', name: 'User Permissions', icon: Users, component: <UserPermissions /> },
        { id: 'Printer_Settings', name: 'Printer Settings', icon: Printer, component: <PrinterSettings /> },
        { id: 'Subscription', name: 'Subscription', icon: CreditCard, component: <SubscriptionSettings /> },
    ];

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <h1 className="text-5xl font-semibold my-10">Settings</h1>

                    <div className="flex space-x-6 mb-8">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`text-lg font-medium ${selectedTab === tab.id
                                    ? 'text-orange-600 border-b-2 border-orange-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => setSelectedTab(tab.id)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>

                    {tabs.find(tab => tab.id === selectedTab)?.component}
                </div>
            </div>
        </div>
    );
};

export default Settings;