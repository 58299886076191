import React from 'react';

// Import store badge SVGs
import playStoreBadge from '../../assets/svgs/google-play-button-light.svg';
import appStoreBadge from '../../assets/svgs/app-store-button-light.svg';

const CallToAction = () => {
    return (
        <section className="py-24 bg-gray-900 relative overflow-hidden">
            {/* Background gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-[#FF6B35] opacity-40"></div>

            {/* Content container */}
            <div className="relative container mx-auto px-4 text-center">
                <h2 className="text-4xl md:text-5xl font-bold mb-6">
                    Get started with HotKiosk today
                </h2>
                <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
                    It takes less than a minute to get started. Download the app and create your account to transform your business management instantly.
                </p>

                {/* Download buttons container */}
                <div className="flex flex-col sm:flex-row justify-center items-center gap-6 max-w-lg mx-auto">


                    {/* App Store Badge */}
                    <a
                        href="https://apps.apple.com/us/app/hotkiosk/id6738089148"
                        className="transform hover:scale-105 transition duration-300"
                    >
                        <img
                            src={appStoreBadge}
                            alt="Download on the App Store"
                            className="h-16"
                        />
                    </a>

                    {/* Play Store Badge */}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.jaydaylabs.hot_kiosk"
                        className="transform hover:scale-105 transition duration-300"
                    >
                        <img
                            src={playStoreBadge}
                            alt="Get it on Google Play"
                            className="h-16"
                        />
                    </a>
                </div>

                {/* Optional: Add a note about availability */}
                <p className="text-gray-400 mt-8 text-sm">
                    Available for iOS and Android devices
                </p>
            </div>
        </section>
    );
};

export default CallToAction;