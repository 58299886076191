import React, { useState, useRef, useEffect } from 'react';
import { Tag, ChevronDown, Loader2, Check, Plus, X, PackagePlus } from 'lucide-react';

const StockItem = ({ item, onStockUpdate, onCategorySelect, categories }) => {
    const [quantity, setQuantity] = useState(item.quantity);
    const [pendingQuantity, setPendingQuantity] = useState(item.quantity);
    const [isUpdating, setIsUpdating] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAddStock, setShowAddStock] = useState(false);
    const [addAmount, setAddAmount] = useState('');
    const dropdownRef = useRef(null);
    const addInputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (showAddStock && addInputRef.current) {
            addInputRef.current.focus();
        }
    }, [showAddStock]);

    const handleQuantityChange = (newValue) => {
        const validQuantity = Math.max(0, parseInt(newValue) || 0);
        setPendingQuantity(validQuantity);
    };

    const handleConfirmUpdate = async () => {
        if (pendingQuantity === quantity) return;

        setIsUpdating(true);
        try {
            await onStockUpdate(item.id, pendingQuantity);
            setQuantity(pendingQuantity);
        } catch (error) {
            setPendingQuantity(quantity); // Reset to last known good value
            console.error('Error updating stock:', error);
        }
        setIsUpdating(false);
    };

    const handleAddStock = () => {
        if (!addAmount || parseInt(addAmount) <= 0) return;
        setPendingQuantity(quantity + parseInt(addAmount));
        setShowAddStock(false);
        setAddAmount('');
    };

    const handleAddKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddStock();
        } else if (e.key === 'Escape') {
            setShowAddStock(false);
            setAddAmount('');
        }
    };

    const cancelChanges = () => {
        setPendingQuantity(quantity);
    };

    const getStatusStyle = (currentQuantity, reorderPoint) => {
        if (currentQuantity === 0) {
            return "text-red-600 bg-red-50";
        } else if (currentQuantity <= reorderPoint) {
            return "text-yellow-600 bg-yellow-50";
        }
        return "text-green-600 bg-green-50";
    };

    const getStatusText = (currentQuantity, reorderPoint) => {
        if (currentQuantity === 0) {
            return "Out of Stock";
        } else if (currentQuantity <= reorderPoint) {
            return "Low Stock";
        }
        return "In Stock";
    };

    return (
        <div className="flex items-center p-4 border rounded-lg mb-2 bg-white hover:ring-1 hover:ring-orange-500">
            <div className="flex-1">
                <div className="flex items-center space-x-2">
                    <span className="font-medium">{item.itemName}</span>
                    {item.sku && <span className="text-gray-300">|</span>}
                    <span className="text-sm text-gray-500">{item.sku}</span>
                </div>
                <div className="mt-1">
                    <span className={`text-xs px-2 py-1 rounded-full ${getStatusStyle(pendingQuantity, item.reorderPoint)}`}>
                        {getStatusText(pendingQuantity, item.reorderPoint)}
                    </span>
                </div>
            </div>

            <div className="flex items-center space-x-4">
                {/* Category dropdown */}
                <div className="relative" ref={dropdownRef}>
                    <button
                        type="button"
                        onClick={() => setShowDropdown(!showDropdown)}
                        className="px-3 py-2 border rounded-lg flex items-center space-x-2 hover:bg-gray-50"
                    >
                        <Tag className="h-4 w-4" />
                        <span className="text-sm">
                            {item.categoryId ? categories.find(c => c.id === item.categoryId)?.name : 'Uncategorized'}
                        </span>
                        <ChevronDown className="h-4 w-4" />
                    </button>

                    {showDropdown && (
                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-50">
                            {categories.map(category => (
                                <button
                                    key={category.id}
                                    type="button"
                                    onClick={() => {
                                        onCategorySelect(item.id, category.id);
                                        setShowDropdown(false);
                                    }}
                                    className="w-full px-4 py-2 text-left hover:bg-gray-50 text-sm"
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                    )}
                </div>

                {/* Quantity controls */}
                <div className="w-[164px] flex items-center space-x-2">
                    {showAddStock ? (
                        <div className="flex-1 relative">
                            <input
                                ref={addInputRef}
                                type="number"
                                value={addAmount}
                                onChange={(e) => setAddAmount(e.target.value)}
                                onKeyDown={handleAddKeyPress}
                                placeholder="Add stock..."
                                className="w-full text-xs px-3 py-2.5 pr-16 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <div className="absolute right-1 top-1/2 -translate-y-1/2 flex items-center gap-1">
                                <button
                                    type="button"
                                    onClick={handleAddStock}
                                    className="p-1 text-blue-500 hover:text-blue-600"
                                    disabled={!addAmount || parseInt(addAmount) <= 0}
                                >
                                    <Check className="h-4 w-4" />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowAddStock(false);
                                        setAddAmount('');
                                    }}
                                    className="p-1 text-gray-400 hover:text-gray-500"
                                >
                                    <X className="h-4 w-4" />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center space-x-2 w-full">
                            <button
                                type="button"
                                onClick={() => handleQuantityChange(pendingQuantity - 1)}
                                className="px-3 py-1 border rounded-lg hover:bg-gray-50"
                            >
                                -
                            </button>
                            <input
                                type="number"
                                value={pendingQuantity}
                                onChange={(e) => handleQuantityChange(e.target.value)}
                                className="w-20 px-3 py-1 border rounded-lg text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            />
                            <button
                                type="button"
                                onClick={() => handleQuantityChange(pendingQuantity + 1)}
                                className="px-3 py-1 border rounded-lg hover:bg-gray-50"
                            >
                                +
                            </button>
                            <button
                                type="button"
                                onClick={() => setShowAddStock(true)}
                                className="px-2 py-2 text-sm bg-green-500 text-white rounded-lg hover:bg-green-600 z-10"
                            >
                                <PackagePlus className="h-4 w-4" />
                            </button>
                        </div>
                    )}
                </div>

                {/* Action buttons */}
                {!showAddStock && (
                    <div className="flex items-center justify-end min-w-[88px] space-x-2">
                        {pendingQuantity !== quantity ? (
                            <>
                                <button
                                    type="button"
                                    onClick={cancelChanges}
                                    className="p-1 text-gray-400 hover:text-gray-500"
                                >
                                    <X className="h-4 w-4" />
                                </button>
                                <button
                                    type="button"
                                    onClick={handleConfirmUpdate}
                                    disabled={isUpdating}
                                    className="p-1 text-green-500 hover:text-green-600"
                                >
                                    {isUpdating ? (
                                        <Loader2 className="h-4 w-4 animate-spin" />
                                    ) : (
                                        <Check className="h-4 w-4" />
                                    )}
                                </button>
                            </>
                        ) : (
                            <button
                                type="button"
                                onClick={handleConfirmUpdate}
                                disabled={true}
                                className="p-1 text-gray-300"
                            >
                                <Check className="h-4 w-4" />
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StockItem;