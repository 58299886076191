import React from 'react';
import {
    ChartBar,
    Package,
    FileText,
    Users,
    Wallet,
    Bell
} from 'lucide-react';

const FeaturesGrid = () => {
    const features = [
        {
            icon: <ChartBar className="w-8 h-8 text-[#FF6B35]" />,
            title: "Sales Tracking",
            description: "Monitor your store's daily transactions and revenue streams right from your phone"
        },
        {
            icon: <Package className="w-8 h-8 text-[#FF6B35]" />,
            title: "Inventory Control",
            description: "Keep track of your stock levels and get alerts when items need replenishing."
        },
        {
            icon: <FileText className="w-8 h-8 text-[#FF6B35]" />,
            title: "Financial Reports",
            description: "Generate detailed reports to understand your business performance and growth."
        },
        {
            icon: <Users className="w-8 h-8 text-[#FF6B35]" />,
            title: "Customer Credit",
            description: "Manage customer credit accounts and track payment histories effectively."
        },
        {
            icon: <Wallet className="w-8 h-8 text-[#FF6B35]" />,
            title: "Payment Plans",
            description: "Track part payments and flexible payment arrangements of your customers."
        },
        {
            icon: <Bell className="w-8 h-8 text-[#FF6B35]" />,
            title: "Smart Notifications",
            description: "Get timely alerts about low stock, sales performance and other business insights."
        }
    ];

    return (
        <section className="py-20 bg-gray-900">
            <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-4xl font-bold mb-4">Heat Up Your Business</h2>
                    <p className="text-gray-400 text-xl">
                        Everything you need to manage your business efficiently in one place.
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className="bg-gray-800 rounded-xl p-6 hover:bg-gray-700 hover:scale-105 transition-all duration-300"
                        >
                            <div className="mb-4 inline-block bg-gray-900 p-3 rounded-lg">
                                {feature.icon}
                            </div>
                            <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                            <p className="text-gray-400">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FeaturesGrid;