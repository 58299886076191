import React, { useState, useEffect } from 'react';
import { Search, ArrowLeft, Download, Plus } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    Timestamp,
    addDoc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import { Link } from 'react-router-dom';
import ReturnModal from '../components/add_returns_modal';
import ReturnDetailModal from '../components/returns_detail';
import TimePeriodPicker from '../components/time_period_picker';

const SalesReturns = () => {
    const [returnsData, setReturnsData] = useState({
        returns: [],
        isLoading: true,
        error: null
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
    const [selectedReturn, setSelectedReturn] = useState(null);
    const [selectedTimeLabel, setSelectedTimeLabel] = useState('Today');
    const [dateRange, setDateRange] = useState(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return {
            startDate: today,
            endDate: new Date()
        };
    });

    const returnsPerPage = 20;
    const db = getFirestore();
    const auth = getAuth();

    const fetchReturnsData = async () => {
        try {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                throw new Error('No authenticated user found');
            }

            const userDoc = await getDocs(
                query(
                    collection(db, 'Users'),
                    where('email', '==', currentUser.email)
                )
            );

            if (userDoc.empty) {
                throw new Error('User data not found');
            }

            const userData = userDoc.docs[0].data();

            const returnsQuery = query(
                collection(db, 'Stores', userData.storeId, 'Returns'),
                where('returnDate', '>=', Timestamp.fromDate(dateRange.startDate)),
                where('returnDate', '<=', Timestamp.fromDate(dateRange.endDate)),
                orderBy('returnDate', 'desc')
            );

            const returnsSnapshot = await getDocs(returnsQuery);
            const returns = returnsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                returnDate: doc.data().returnDate.toDate()
            }));

            setReturnsData({
                returns,
                isLoading: false,
                error: null
            });
        } catch (error) {
            console.error('Error fetching returns data:', error);
            setReturnsData(prev => ({
                ...prev,
                isLoading: false,
                error: 'Failed to load returns data'
            }));
        }
    };

    const handleDateRangeChange = (start, end, label) => {
        setDateRange({
            startDate: start,
            endDate: end
        });
        setSelectedTimeLabel(label);
    };

    const handleReturnSubmit = async (returnData) => {
        try {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                throw new Error('No authenticated user found');
            }

            const userDoc = await getDocs(
                query(
                    collection(db, 'Users'),
                    where('email', '==', currentUser.email)
                )
            );

            if (userDoc.empty) {
                throw new Error('User data not found');
            }

            const userData = userDoc.docs[0].data();
            const returnRef = collection(db, 'Stores', userData.storeId, 'Returns');

            await addDoc(returnRef, {
                ...returnData,
                returnDate: Timestamp.now()
            });

            setIsReturnModalOpen(false);
            await fetchReturnsData();

        } catch (error) {
            console.error('Error submitting return:', error);
        }
    };

    useEffect(() => {
        fetchReturnsData();
    }, [db, dateRange]);

    const getFilteredData = () => {
        const allItems = returnsData.returns.flatMap(returnItem =>
            returnItem.items.map(item => ({
                ...item,
                returnId: returnItem.id,
                orderNumber: returnItem.orderNumber,
                returnDate: returnItem.returnDate,
                reason: returnItem.reason,
                quantity: item.returnedQuantity,
                stockDecision: item.stockDecision || 'pending'
            }))
        );
        return allItems.filter(item =>
            item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.orderNumber && item.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    };

    const filteredData = getFilteredData();
    const totalPages = Math.ceil(filteredData.length / returnsPerPage);
    const indexOfLastItem = currentPage * returnsPerPage;
    const indexOfFirstItem = indexOfLastItem - returnsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const exportToCSV = () => {
        const headers = ['Return No.', 'Order No.', 'Date', 'Item Name', 'Quantity', 'Price', 'Total', 'Reason'];
        const csvContent = [
            headers.join(','),
            ...filteredData.map(item => [
                `RTN${String(parseInt(item.returnId.slice(-6), 16)).padStart(6, '0')}`,
                item.orderNumber || 'N/A',
                item.returnDate.toLocaleString(),
                item.itemName,
                item.quantity,
                item.price.toFixed(2),
                (item.price * item.quantity).toFixed(2),
                item.reason
            ].join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'returns_items_report.csv';
        link.click();
    };

    if (returnsData.isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="text-lg">Loading returns data...</div>
                </div>
            </div>
        );
    }

    if (returnsData.error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center text-red-500">
                    <div>{returnsData.error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 p-8 overflow-y-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <Link
                            to="/dashboard"
                            className="mr-4 text-gray-500 hover:text-gray-700 transition-colors"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </Link>
                        <h1 className="text-3xl font-semibold">Sales Returns</h1>
                    </div>
                    <div className="flex items-center space-x-4">
                        <button
                            onClick={() => setIsReturnModalOpen(true)}
                            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            <Plus className="h-4 w-4 mr-2" />
                            Return Item
                        </button>

                        <TimePeriodPicker
                            onDateRangeChange={handleDateRangeChange}
                            selectedLabel={selectedTimeLabel}
                            onLabelChange={setSelectedTimeLabel}
                        />
                    </div>
                </div>

                <div className="flex items-center justify-between mb-5">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search items..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                            <Search className="h-5 w-5 text-gray-400" />
                        </div>
                    </div>
                    <button
                        onClick={exportToCSV}
                        className="inline-flex items-center px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
                    >
                        <Download className="h-4 w-4 mr-2" />
                        Export
                    </button>
                </div>

                {/* Rest of the component (table, pagination) remains the same */}
                {/* ... table and pagination code ... */}
                <div className="bg-white overflow-hidden border p-5">
                    <table className="w-full">
                        <thead className="w-full">
                            <tr className="bg-gray-100">
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Order No.
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Item Name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Quantity
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Price
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Stock
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Reason
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentItems.length === 0 ? (
                                <tr>
                                    <td colSpan={8} className="px-6 py-4 text-center text-gray-500">
                                        No items found
                                    </td>
                                </tr>
                            ) : (
                                currentItems.map((item, index) => (
                                    <tr
                                        key={`${item.returnId}-${index}`}
                                        className="hover:bg-gray-50 cursor-pointer"
                                        onClick={() => setSelectedReturn(item)}
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {item.orderNumber || 'N/A'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {item.returnDate.toLocaleString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {item.itemName}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {item.returnedQuantity || item.quantity}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className="text-xs mr-1">GHS</span>
                                            <span className="text-gray-900">{item.price.toFixed(2)}</span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className="text-xs mr-1">GHS</span>
                                            <span className="text-gray-900">
                                                {(item.price * (item.returnedQuantity || item.quantity)).toFixed(2)}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                                ${item.stockDecision === 'restock'
                                                    ? 'bg-green-100 text-green-800'
                                                    : item.stockDecision === 'discard'
                                                        ? 'bg-red-100 text-red-800'
                                                        : 'bg-yellow-100 text-yellow-800'
                                                }`}
                                            >
                                                {item.stockDecision === 'restock'
                                                    ? 'Restock'
                                                    : item.stockDecision === 'discard'
                                                        ? 'Discard'
                                                        : 'Pending'
                                                }
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {item.reason}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                        <div className="flex items-center justify-between">
                            <div className="text-sm text-gray-700">
                                Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of{' '}
                                {filteredData.length} results
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                    className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReturnModal
                isOpen={isReturnModalOpen}
                onClose={() => setIsReturnModalOpen(false)}
                onSubmit={handleReturnSubmit}
            />

            <ReturnDetailModal
                isOpen={!!selectedReturn}
                onClose={() => setSelectedReturn(null)}
                returnItem={selectedReturn}
            />
        </div>
    );
};

export default SalesReturns;