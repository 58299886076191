import React, { useState, useEffect } from 'react';
import { BarChart2, Package, DollarSign, Coffee, Smartphone, Menu, X } from 'lucide-react';

// Import images
import logo from '../../assets/hotKioskLogocolor.png';
import PhoneMockup from './PhoneMockup';
import FeaturesGrid from './FeaturesGrid';
import CallToAction from './CallToAction';
import Hero from './Hero';
// import androidMockup from '../assets/android-mockup.png';
// import iosMockup from '../assets/ios-mockup.png';



const Home = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            {/* Header */}
            <header className={`bg-black bg-opacity-${scrollPosition > 50 ? '90' : '50'} backdrop-filter backdrop-blur-lg fixed w-full z-20 transition-all duration-300`}>
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    <div className="flex items-center">
                        <img
                            src={logo}
                            alt="HotKiosk Logo"
                            className="h-12 mr-2"
                        />
                    </div>
                    <nav className="hidden md:block">
                        <ul className="flex space-x-6">
                            {/* <li><a href="#features" className="hover:text-red-400 transition duration-300">Features</a></li>
                            <li><a href="#mockups" className="hover:text-red-400 transition duration-300">App Preview</a></li> */}
                            <li><a href="/support" className="hover:text-red-400 transition duration-300">Support</a></li>
                        </ul>
                    </nav>
                    <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                    </button>
                </div>
                {isMenuOpen && (
                    <nav className="md:hidden bg-black bg-opacity-90 backdrop-filter backdrop-blur-lg">
                        <ul className="flex flex-col items-center py-4 space-y-4">
                            {/* <li><a href="#features" className="hover:text-red-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>Features</a></li>
                            <li><a href="#mockups" className="hover:text-red-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>App Preview</a></li> */}
                            <li><a href="/support" className="hover:text-red-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>Support</a></li>
                        </ul>
                    </nav>
                )}
            </header>

            {/* Hero Section */}
            <Hero />

            {/* App Mockup Section */}
            <PhoneMockup />

            {/* Features Section */}
            <FeaturesGrid />

            {/* Mockup Section */}
            <CallToAction />

            {/* Footer */}
            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto px-4">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <p className="text-gray-400 mb-4 md:mb-0">&copy; 2024 HotKiosk. All rights reserved.</p>
                        <div className="flex space-x-4">
                            <a href="/privacy" className="text-gray-400 hover:text-white transition duration-300">Privacy Policy</a>
                            <a href="/terms" className="text-gray-400 hover:text-white transition duration-300">Terms of Service</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Home;