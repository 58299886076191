import React from 'react';
import { ArrowLeft } from 'lucide-react';

const Privacy = () => {
    return (
        <div className="min-h-screen bg-gray-900 text-white">
            {/* Header */}
            <header className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg fixed w-full z-10">
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    {/* <a href="/" className="flex items-center text-[#FF6B35] hover:text-red-300 transition duration-300">
                        <ArrowLeft className="w-5 h-5 mr-2" />
                        Back to Home
                    </a> */}
                    <h1 className="text-2xl font-bold">HotKiosk Privacy Policy</h1>
                </div>
            </header>

            {/* Main Content */}
            <main className="container mx-auto pt-24 pb-12 px-4">
                <section className="max-w-3xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8">
                    <h2 className="text-3xl font-bold mb-6">Privacy Policy</h2>

                    <p className="mb-6">Last updated: September 26, 2024</p>

                    <div className="space-y-8">
                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">1. Introduction</h3>
                            <p>HotKiosk ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application and website (collectively, the "Service").</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">2. Information We Collect</h3>
                            <p>We collect information that you provide directly to us, such as when you create an account, use our Service, or communicate with us. This may include:</p>
                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>Personal information (e.g., name, email address, phone number)</li>
                                <li>Business information (e.g., business name, address)</li>
                                <li>Financial information (e.g., sales data, inventory data)</li>
                                <li>Usage data (e.g., how you interact with our Service)</li>
                            </ul>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">3. How We Use Your Information</h3>
                            <p>We use the information we collect to:</p>
                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>Provide, maintain, and improve our Service</li>
                                <li>Process transaction data and send related information</li>
                                <li>Send you technical notices, updates, security alerts, and support messages</li>
                                <li>Respond to your comments, questions, and customer service requests</li>
                                <li>Analyze usage patterns and improve user experience</li>
                            </ul>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">4. Data Security</h3>
                            <p>We implement appropriate technical and organizational measures to protect the security of your personal information. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">5. Data Retention</h3>
                            <p>We will retain your information for as long as your account is active or as needed to provide you with our Service. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">6. Your Rights</h3>
                            <p>Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete the personal information we have about you.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">7. Changes to This Privacy Policy</h3>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-[#FF6B35]">8. Contact Us</h3>
                            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                            <p className="mt-2">
                                Email: <a href="mailto:privacy@hotkiosk.com" className="text-[#FF6B35] hover:text-red-300 transition duration-300">privacy@hotkiosk.com</a>
                            </p>
                        </section>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto text-center text-gray-400">
                    <p>&copy; 2024 HotKiosk. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Privacy;