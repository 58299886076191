import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from '../../../../firebase-config';
import { Check, ArrowRight } from 'lucide-react';

const functions = getFunctions();

const TIER_NAMES = {
    0: 'Basic',
    1: 'Pro',
    2: 'Enterprise'
};

const PLAN_FEATURES = {
    0: [ // Basic
        "Record sales with inbuilt POS system",
        "Advanced inventory management",
        "Notifications & Low Stock Alerts",
        "Up to 250 products in inventory",
        "Basic analytics & reports",
        "Up to 3 users",
    ],
    1: [ // Pro
        "Record sales with inbuilt POS system",
        "Advanced inventory management",
        "Notifications & Low Stock Alerts",
        'Unlimited products in inventory',
        'Standard analytics & reports',
        'Up to 10 users',
        'Expenses tracking',
    ],
    2: [ // Enterprise
        "Record sales with inbuilt POS system",
        "Advanced inventory management",
        "Notifications & Low Stock Alerts",
        'Unlimited products in inventory',
        'Advanced sales analytics ',
        'Unlimited users',
        'Expenses tracking',
        'Advanced financial reports',
        'Quotes, Purchase Orders & Invoices',
        'Supplier & Customer Management',
    ]
};

const BillingToggle = ({ billingPeriod, onToggle, disabled }) => (
    <div className="flex items-center justify-center gap-6 mb-6">
        <button
            onClick={() => onToggle('monthly')}
            className={`text-sm px-3 py-1.5 rounded-lg ${billingPeriod === 'monthly'
                ? 'bg-gray-100 text-orange-600 font-medium'
                : 'text-gray-500 hover:text-gray-700'
                }`}
            disabled={disabled}
        >
            Monthly
        </button>
        <button
            onClick={() => onToggle('quarterly')}
            className={`text-sm px-3 py-1.5 rounded-lg ${billingPeriod === 'quarterly'
                ? 'bg-gray-100 text-orange-600 font-medium'
                : 'text-gray-500 hover:text-gray-700'
                }`}
            disabled={disabled}
        >
            3 Months (Quarterly)
            {/* <span className="ml-1 text-xs text-green-600">Save 10%</span> */}
        </button>
        <button
            onClick={() => onToggle('annually')}
            className={`text-sm px-3 py-1.5 rounded-lg ${billingPeriod === 'annually'
                ? 'bg-gray-100 text-orange-600 font-medium'
                : 'text-gray-500 hover:text-gray-700'
                }`}
            disabled={disabled}
        >
            Yearly
            <span className="ml-1 text-xs text-green-600">Save 20%</span>
        </button>
    </div>
);

const SubscriptionDetails = ({ subscription, onChangePlan }) => (
    <>
        <div className="p-4 bg-orange-50 rounded mb-6">
            <h3 className="font-medium text-orange-800">
                Current Plan: {TIER_NAMES[subscription.tier]}
            </h3>
            <p className="text-sm text-orange-500">
                GHS {subscription.price}/{subscription.interval}
            </p>
            <p className="text-sm text-orange-500 mt-2">
                Next billing date: {subscription.nextBillingDate}
            </p>
        </div>

        <div className="bg-gray-50 rounded-lg p-6 border border-gray-200 mt-8">
            <div className="flex items-start justify-between mb-6">
                <div>
                    <h2 className="text-xl font-bold text-gray-900 mb-2">
                        {TIER_NAMES[subscription.tier]}
                    </h2>
                    <p className="text-gray-600">Your current activated features</p>
                </div>
                <button
                    onClick={() => onChangePlan()}
                    className="px-4 py-2 bg-orange-500 hover:bg-orange-600 text-white rounded-lg transition-colors"
                >
                    Change Plan
                </button>
            </div>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                {PLAN_FEATURES[subscription.tier]?.map((feature, index) => (
                    <div key={index} className="flex items-center text-gray-600">
                        <Check className="w-5 h-5 text-orange-500 mr-3 flex-shrink-0" />
                        <span>{feature}</span>
                    </div>
                ))}
            </div>
        </div>
    </>
);

const PricingPlans = ({ plans, onSubscribe, isLoading, billingPeriod }) => (
    <>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            {plans.map(plan => (
                <div
                    key={plan.id}
                    className={`border p-4 rounded flex flex-col h-full ${plan.current ? 'bg-orange-50 border-orange-200' : ''}`}
                >
                    <div className="flex-grow">
                        <h3 className="font-medium mb-2">{plan.name}</h3>
                        <p className="text-2xl font-bold mb-2">
                            GHS {plan.price}
                            <span className="text-sm text-gray-500">
                                {billingPeriod === 'monthly' && '/mo'}
                                {billingPeriod === 'quarterly' && '/3mo'}
                                {billingPeriod === 'annually' && '/yr'}
                            </span>
                        </p>
                        <ul className="text-sm text-gray-600 space-y-2 mb-4">
                            {PLAN_FEATURES[plan.tier].map((feature, index) => (
                                <li key={index} className="flex items-center">
                                    <Check className="w-4 h-4 mr-2 text-orange-500" />
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <button
                        onClick={() => onSubscribe(plan.planId)}
                        className="w-full bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700 transition-colors mt-auto"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Processing...' : 'Get Started'}
                    </button>
                </div>
            ))}
        </div>
    </>
);

const SubscriptionSettings = () => {
    const [billingPeriod, setBillingPeriod] = useState('monthly');
    const [plansLoading, setPlansLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentStore, setCurrentStore] = useState(null);
    const [authChecked, setAuthChecked] = useState(false);
    const [showPlans, setShowPlans] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setCurrentUser(user);
                const userDoc = await getDoc(doc(db, 'Users', user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.storeId) {
                        const storeDoc = await getDoc(doc(db, 'Stores', userData.storeId));
                        if (storeDoc.exists()) {
                            setCurrentStore({
                                id: storeDoc.id,
                                ...storeDoc.data()
                            });
                        }
                    }
                }
            }
            setAuthChecked(true);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (currentStore?.id) {
            fetchData();
        }
    }, [currentStore, billingPeriod]);

    const fetchData = async () => {
        try {
            // Clear existing plans before fetching new ones
            setPlans([]);
            const [plansData, subscriptionResponse] = await Promise.all([
                fetchPlans(),
                fetchCurrentSubscription()
            ]);
            setPlans(plansData);
            if (subscriptionResponse.hasSubscription) {
                setSubscription(subscriptionResponse.data);
                setHasSubscription(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setPlans([]); // Also clear on error
        }
    };

    const fetchPlans = async () => {
        try {
            const plansCollectionRef = collection(db, 'Plans');
            const plansQuery = query(
                plansCollectionRef,
                where('interval', '==', billingPeriod),
                where('status', '==', true)
            );

            const plansSnapshot = await getDocs(plansQuery);
            return plansSnapshot.docs
                .map(doc => {
                    const plan = doc.data();
                    return {
                        id: plan.planId,
                        name: TIER_NAMES[plan.tier],
                        price: plan.amount,
                        features: plan.features || [],
                        tier: plan.tier,
                        planId: plan.planId,
                        interval: plan.interval,
                        current: plan.tier === (subscription?.tier || 1)
                    };
                })
                .sort((a, b) => a.tier - b.tier);
        } catch (error) {
            console.error('Error fetching plans:', error);
            return [];
        }
    };

    const fetchCurrentSubscription = async () => {
        if (!currentStore?.id) return { hasSubscription: false };
        try {
            const getSubscriptionFunction = httpsCallable(functions, 'getSubscriptionDetails');
            const response = await getSubscriptionFunction({ storeId: currentStore.id });
            return response.data;
        } catch (error) {
            console.error('Error fetching subscription:', error);
            return { hasSubscription: false };
        }
    };

    const handleUpgrade = async (planId) => {
        if (!currentStore?.id) return;
        setPlansLoading(true);
        try {
            const initializeSubscriptionFunction = httpsCallable(functions, 'initializeSubscription');
            await initializeSubscriptionFunction({
                planId,
                storeId: currentStore.id,
                interval: billingPeriod
            });
            await fetchData();
        } catch (error) {
            console.error('Error upgrading:', error);
        } finally {
            setPlansLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        if (!currentStore?.id) return;
        try {
            const cancelSubscriptionFunction = httpsCallable(functions, 'cancelSubscription');
            await cancelSubscriptionFunction({ storeId: currentStore.id });
            await fetchData();
        } catch (error) {
            console.error('Error cancelling:', error);
        }
    };

    if (!authChecked || !currentUser || !currentStore) {
        return (
            <div className="space-y-6">
                <div className="bg-white border p-6">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-200 rounded"></div>
                                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="bg-white border p-6">
                <h2 className="text-xl font-semibold mb-6">Subscription Management</h2>

                {(!hasSubscription || showPlans) && (
                    <>
                        <BillingToggle
                            billingPeriod={billingPeriod}
                            onToggle={(period) => setBillingPeriod(period)}
                            disabled={plansLoading}
                        />
                        <PricingPlans
                            plans={plans}
                            onSubscribe={handleUpgrade}
                            isLoading={plansLoading}
                            billingPeriod={billingPeriod}
                        />
                    </>
                )}

                {hasSubscription && !showPlans && (
                    <>
                        <SubscriptionDetails
                            subscription={subscription}
                            onChangePlan={() => setShowPlans(true)}
                        />

                        <div className="space-y-4 mt-8">
                            <div className="border-t pt-4">
                                <h3 className="font-medium mb-2">Billing History</h3>
                                {subscription.billingHistory?.map((bill, index) => (
                                    <div key={index} className="flex justify-between py-2 text-sm text-gray-600">
                                        <span>{bill.date}</span>
                                        <span>GHS {bill.amount}</span>
                                    </div>
                                ))}
                            </div>

                            <div className="border-t pt-4">
                                <h3 className="font-medium mb-2">Payment Method</h3>
                                <div className="flex justify-between items-center">
                                    <div className="text-sm text-gray-600">
                                        {subscription.paymentMethod || '•••• •••• •••• ####'}
                                    </div>
                                    <button className="text-orange-500 hover:text-orange-700">
                                        Update
                                    </button>
                                </div>
                            </div>

                            <div className="border-t pt-4">
                                <button
                                    onClick={handleCancelSubscription}
                                    className="text-red-600 hover:text-red-700 text-sm"
                                >
                                    Cancel subscription
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SubscriptionSettings;