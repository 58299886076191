import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { ShoppingCart, Package, Loader2, AlertTriangle, Store, Users, FileText, Settings, Printer } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TabButton = ({ active, children, onClick }) => (
    <button
        onClick={onClick}
        className={`px-4 py-2 font-medium rounded-lg transition-colors duration-200 ${active
            ? 'bg-blue-100 text-blue-700'
            : 'text-gray-600 hover:bg-gray-100'
            }`}
    >
        {children}
    </button>
);

const ActionButton = ({ icon: Icon, title, description, onClick }) => (
    <button
        onClick={onClick}
        className="flex items-center gap-4 w-full p-6 bg-white border rounded-lg hover:bg-gray-50 transition-all duration-200 group"
    >
        <div className="p-3 bg-blue-50 rounded-lg text-blue-600 group-hover:bg-blue-100 transition-colors duration-200">
            <Icon size={24} />
        </div>
        <div className="text-left">
            <h3 className="text-lg font-semibold mb-1">{title}</h3>
            <p className="text-gray-500">{description}</p>
        </div>
    </button>
);

const TabContent = ({ activeTab, navigate, lobbyData }) => {
    const currentMonthData = useMemo(() => {
        // Get the current date
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();

        // Get the number of days in the current month
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        // Create an array with all days of the month
        const allDays = Array.from({ length: daysInMonth }, (_, index) => {
            const day = index + 1;
            const date = new Date(year, month, day);

            // Find matching sales data or default to 0
            const matchingData = lobbyData.salesChart.find(
                item => new Date(item.date).getDate() === day
            );

            return {
                date: date.toISOString(),
                sales: matchingData?.sales || 0
            };
        });

        return allDays;
    }, [lobbyData.salesChart]);

    if (activeTab === 'actions') {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <ActionButton
                    icon={ShoppingCart}
                    title="New Sale / POS"
                    description="Process a new sales transaction"
                    onClick={() => navigate('/sales/add')}
                />
                <ActionButton
                    icon={Package}
                    title="Manage Inventory"
                    description="Update your stock levels and products"
                    onClick={() => navigate('/inventory')}
                />
                <ActionButton
                    icon={Users}
                    title="Manage Staff"
                    description="Handle employee accounts and permissions"
                    onClick={() => navigate('/staff')}
                />
                <ActionButton
                    icon={FileText}
                    title="View Reports"
                    description="Access detailed sales and inventory reports"
                    onClick={() => navigate('/reports')}
                />
                <ActionButton
                    icon={Settings}
                    title="Store Settings"
                    description="Configure your store preferences"
                    onClick={() => navigate('/settings')}
                />
                <ActionButton
                    icon={Printer}
                    title="Print Receipt"
                    description="Quickly print a sales receipt"
                    onClick={() => navigate('/sales/receipts')}
                />
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <MetricCard
                    title="Monthly Sales"
                    value={`GHS ${lobbyData.monthlySales.toFixed(2)}`}
                    subtitle="This month"
                />
                <MetricCard
                    title="Items Sold"
                    value={lobbyData.itemsSold}
                    subtitle="This month"
                />
                <MetricCard
                    title="Low Stock Items"
                    value={lobbyData.lowStockItems}
                    subtitle="Need attention"
                />
                <MetricCard
                    title="Total Revenue"
                    value={`GHS ${lobbyData.yearToDateRevenue.toFixed(2)}`}
                    subtitle="Year to date"
                />
            </div>

            <div className="">
                <h2 className="text-xl font-semibold pb-4">Monthly Sales Overview</h2>
                <div className="bg-white border p-6">
                    <div className="mb-4 flex justify-end items-center">
                        <div className="text-sm text-gray-500">
                            {new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                        </div>
                    </div>

                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={currentMonthData}
                                margin={{ left: 40, right: 40, bottom: 20 }}
                            >
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(date) => new Date(date).getDate()}
                                    interval={0}
                                    angle={0}
                                    textAnchor="end"
                                    height={50}
                                />
                                <YAxis />
                                <Tooltip
                                    formatter={(value) => [`GHS ${value.toFixed(2)}`, 'Sales']}
                                    labelFormatter={(label) => new Date(label).toLocaleDateString('en-US', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: 'numeric'
                                    })}
                                />
                                <Bar
                                    dataKey="sales"
                                    fill="#2563eb"
                                    radius={[4, 4, 0, 0]}
                                    maxBarSize={50}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Dashboard = ({ lobbyData, storeData }) => {
    const [activeTab, setActiveTab] = useState('actions');
    const navigate = useNavigate();

    if (lobbyData.isLoading) {
        return (
            <div className="flex items-center justify-center flex-1">
                <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                    <div className="text-lg text-gray-600">Loading dashboard data...</div>
                </div>
            </div>
        );
    }

    if (lobbyData.error) {
        return (
            <div className="flex items-center justify-center flex-1 text-red-500">
                <AlertTriangle className="mr-2 h-5 w-5" />
                <div>{lobbyData.error}</div>
            </div>
        );
    }

    return (
        <div className="flex-1 flex flex-col overflow-hidden">
            <div className="flex-1 overflow-y-auto p-8">
                <div className="flex items-center gap-4 mb-8">
                    <div className="p-4 bg-blue-50 rounded-full">
                        <Store className="h-12 w-12 text-blue-600" />
                    </div>
                    <div>
                        <h1 className="text-4xl font-semibold">
                            {storeData?.name || 'Loading store...'}
                        </h1>
                        {storeData?.location && (
                            <p className="text-gray-500 mt-1">
                                {storeData.location}
                            </p>
                        )}
                    </div>
                </div>

                <div className="flex gap-2 mb-6">
                    <TabButton
                        active={activeTab === 'actions'}
                        onClick={() => setActiveTab('actions')}
                    >
                        Quick Actions
                    </TabButton>
                    <TabButton
                        active={activeTab === 'stats'}
                        onClick={() => setActiveTab('stats')}
                    >
                        Quick Stats
                    </TabButton>
                </div>

                <TabContent
                    activeTab={activeTab}
                    navigate={navigate}
                    lobbyData={lobbyData}
                />
            </div>
        </div>
    );
};

const MetricCard = ({ title, value, subtitle }) => (
    <div className="bg-white border p-4">
        <h3 className="text-sm text-gray-500 mb-1">{title}</h3>
        <p className="text-2xl font-bold mb-1">{value}</p>
        <p className="text-xs text-gray-500">{subtitle}</p>
    </div>
);

export default Dashboard;