import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const AccountSettings = () => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', isError: false });
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        address: ''
    });

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        loadUserData();
    }, []);

    const loadUserData = async () => {
        try {
            const user = auth.currentUser;
            if (!user) return;

            const userDoc = await getDoc(doc(db, 'Users', user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setFormData({
                    fullName: userData.displayName || '',
                    email: userData.email || '',
                    phone: userData.phone || '',
                    address: userData.address || ''
                });
            }
        } catch (error) {
            console.error('Error loading user data:', error);
            showNotification(true, 'Error loading user data');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);

        try {
            const user = auth.currentUser;
            if (!user) throw new Error('No authenticated user');

            await updateDoc(doc(db, 'Users', user.uid), {
                displayName: formData.fullName,
                phone: formData.phone,
                address: formData.address,
                updatedAt: new Date()
            });

            showNotification(false, 'Profile updated successfully');
        } catch (error) {
            console.error('Error updating profile:', error);
            showNotification(true, 'Error updating profile');
        } finally {
            setSaving(false);
        }
    };

    const showNotification = (isError, message) => {
        setAlert({ show: true, message, isError });
        setTimeout(() => setAlert({ show: false, message: '', isError: false }), 5000);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-[200px]">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-orange-500"></div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            {alert.show && (
                <div className={`p-4 rounded ${alert.isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                    {alert.message}
                </div>
            )}

            <div className="bg-white border p-6 rounded-lg max-w-4xl">
                <h2 className="text-xl font-semibold mb-6">Profile Information</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm text-gray-500 mb-1">Full Name</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                            required
                            minLength={2}
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-500 mb-1">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            disabled
                            className="w-full p-2 border rounded bg-gray-50 text-gray-500"
                        />
                        <p className="mt-1 text-sm text-gray-500">Email cannot be changed</p>
                    </div>
                    <div>
                        <label className="block text-sm text-gray-500 mb-1">Phone Number</label>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                            pattern="[0-9+\s-]*"
                            title="Please enter a valid phone number"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-500 mb-1">Address</label>
                        <textarea
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                            rows={3}
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={saving}
                        className="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700 transition-colors disabled:bg-gray-400"
                    >
                        {saving ? 'Saving...' : 'Save Changes'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AccountSettings;