import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Search, ArrowLeft, Printer, FileText, Download } from 'lucide-react';
import {
    collection,
    query,
    orderBy,
    getDocs,
    getFirestore,
    limit,
    startAfter,
    where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Sidebar from '../components/sidebar';

const SalesReceipts = () => {
    const [sales, setSales] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false); // New state for loading more
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [lastDoc, setLastDoc] = useState(null);

    const observer = useRef();
    const db = getFirestore();
    const auth = getAuth();
    const SALES_PER_PAGE = 20;

    const lastSaleElementRef = useCallback(node => {
        if (loading || loadingMore) return; // Check both loading states
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                fetchMoreSales();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, loadingMore, hasMore]); // Add loadingMore to dependencies

    const fetchSales = async () => {
        try {
            setLoading(true);
            const currentUser = auth.currentUser;
            if (!currentUser) {
                throw new Error('No authenticated user found');
            }

            const userDoc = await getDocs(
                query(
                    collection(db, 'Users'),
                    where('email', '==', currentUser.email)
                )
            );

            if (userDoc.empty) {
                throw new Error('User data not found');
            }

            const userData = userDoc.docs[0].data();

            const salesQuery = query(
                collection(db, 'Stores', userData.storeId, 'Sales'),
                orderBy('saleDate', 'desc'),
                limit(SALES_PER_PAGE)
            );

            const salesSnapshot = await getDocs(salesQuery);
            const salesData = salesSnapshot.docs.map(doc => {
                const saleData = doc.data();
                const totalAmount = saleData.items.reduce(
                    (sum, item) => sum + (item.price * item.quantity),
                    0
                );
                return {
                    id: doc.id,
                    ...saleData,
                    saleDate: saleData.saleDate.toDate(),
                    totalAmount
                };
            });

            setLastDoc(salesSnapshot.docs[salesSnapshot.docs.length - 1] || null);
            setSales(salesData);
            setHasMore(salesSnapshot.docs.length === SALES_PER_PAGE);
        } catch (error) {
            console.error('Error fetching sales:', error);
            setError('Failed to load sales data');
        } finally {
            setLoading(false);
        }
    };

    const fetchMoreSales = async () => {
        if (!lastDoc || loadingMore) return; // Prevent multiple simultaneous calls

        try {
            setLoadingMore(true);
            const currentUser = auth.currentUser;
            const userDoc = await getDocs(
                query(collection(db, 'Users'), where('email', '==', currentUser.email))
            );
            const userData = userDoc.docs[0].data();

            const salesQuery = query(
                collection(db, 'Stores', userData.storeId, 'Sales'),
                orderBy('saleDate', 'desc'),
                startAfter(lastDoc),
                limit(SALES_PER_PAGE)
            );

            const salesSnapshot = await getDocs(salesQuery);

            // Check if we got any new documents
            if (salesSnapshot.empty) {
                setHasMore(false);
                return;
            }

            const newSales = salesSnapshot.docs.map(doc => {
                const saleData = doc.data();
                const totalAmount = saleData.items.reduce(
                    (sum, item) => sum + (item.price * item.quantity),
                    0
                );
                return {
                    id: doc.id,
                    ...saleData,
                    saleDate: saleData.saleDate.toDate(),
                    totalAmount
                };
            });

            // Update lastDoc only if we got new documents
            setLastDoc(salesSnapshot.docs[salesSnapshot.docs.length - 1]);

            // Use a function to update sales to ensure we have the latest state
            setSales(prevSales => {
                // Create a Map of existing sales to prevent duplicates
                const salesMap = new Map(prevSales.map(sale => [sale.id, sale]));

                // Add new sales, avoiding duplicates
                newSales.forEach(sale => {
                    if (!salesMap.has(sale.id)) {
                        salesMap.set(sale.id, sale);
                    }
                });

                // Convert back to array and sort by date
                return Array.from(salesMap.values())
                    .sort((a, b) => b.saleDate - a.saleDate);
            });

            setHasMore(salesSnapshot.docs.length === SALES_PER_PAGE);
        } catch (error) {
            console.error('Error fetching more sales:', error);
        } finally {
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        fetchSales();
    }, []);

    const generateReceipt = async (sale) => {
        try {
            // Implementation for generating receipt
            console.log('Generating receipt for sale:', sale);
        } catch (error) {
            console.error('Error generating receipt:', error);
        }
    };

    const printReceipt = async (sale) => {
        try {
            // Implementation for printing receipt
            console.log('Printing receipt for sale:', sale);
        } catch (error) {
            console.error('Error printing receipt:', error);
        }
    };

    const downloadPDF = async (sale) => {
        try {
            // Implementation for downloading PDF receipt
            console.log('Downloading PDF receipt for sale:', sale);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const filteredSales = sales.filter(sale =>
        sale.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (sale.orderNumber && sale.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    if (error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center text-red-500">
                    <div>{error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 p-8 overflow-y-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <Link
                            to="/dashboard"
                            className="mr-4 text-gray-500 hover:text-gray-700 transition-colors"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </Link>
                        <h1 className="text-3xl font-semibold">Sales Receipts</h1>
                    </div>
                    <div className="relative flex items-center">
                        <input
                            type="text"
                            placeholder="Search sales..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 w-64"
                        />
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                            <Search className="h-5 w-5 text-gray-400" />
                        </div>
                    </div>
                </div>



                <div className="bg-white overflow-hidden border p-4">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Sale No.
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Items
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total Amount
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredSales.map((sale, index) => (
                                <tr
                                    key={sale.id}
                                    ref={index === filteredSales.length - 1 ? lastSaleElementRef : null}
                                    className="hover:bg-gray-50"
                                >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {sale.orderNumber || `SR${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {sale.saleDate.toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {sale.items.length} items
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <span className="text-xs mr-1">GHS</span>
                                        <span className="text-gray-900">{sale.totalAmount.toFixed(2)}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <div className="flex space-x-5">
                                            <button
                                                onClick={() => downloadPDF(sale)}
                                                className="text-red-600 hover:text-red-800"
                                                title="Download PDF"
                                            >
                                                <Download className="h-5 w-5" />
                                            </button>
                                            <button
                                                onClick={() => generateReceipt(sale)}
                                                className="text-blue-600 hover:text-blue-800"
                                                title="Generate Receipt"
                                            >
                                                <FileText className="h-5 w-5" />
                                            </button>
                                            <button
                                                onClick={() => printReceipt(sale)}
                                                className="text-green-600 hover:text-green-800"
                                                title="Print Receipt"
                                            >
                                                <Printer className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {(loading || loadingMore) && (
                        <div className="px-6 py-4 text-center text-gray-500">
                            {loading ? 'Loading sales...' : 'Loading more sales...'}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SalesReceipts;