import React, { useState } from 'react';

const PrinterSettings = () => {
    const [activeTab, setActiveTab] = useState('designer');
    const [printerSettings, setPrinterSettings] = useState({
        // Printer settings
        printerName: '',
        paperWidth: '80mm',
        printDensity: 50,
        defaultCopies: 1,
        enableAutocut: true,
        // Receipt design
        showLogo: true,
        showAddress: true,
        showPhone: true,
        showFooter: true,
        fontSize: 'normal',
        headerText: 'My Store',
        addressText: '123 Business Street',
        phoneText: '+233 XX XXX XXXX',
        footerText: 'Thank you for your purchase!'
    });

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox'
            ? e.target.checked
            : e.target.value;

        setPrinterSettings({
            ...printerSettings,
            [e.target.name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Printer settings:', printerSettings);
    };

    // Mock receipt data for preview
    const receiptData = {
        items: [
            { name: "Item 1", qty: 2, price: 10.00 },
            { name: "Item 2", qty: 1, price: 15.50 },
        ],
        total: 35.50
    };

    const renderDesignerTab = () => (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label className="block text-sm text-gray-500 mb-1">Header Text</label>
                <input
                    type="text"
                    name="headerText"
                    value={printerSettings.headerText}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                />
            </div>

            <div className="space-y-3">
                <div>
                    <div className="flex items-center justify-between mb-1">
                        <label className="text-sm text-gray-500">Store Address</label>
                        <input
                            type="checkbox"
                            name="showAddress"
                            checked={printerSettings.showAddress}
                            onChange={handleChange}
                            className="h-4 w-4"
                        />
                    </div>
                    <input
                        type="text"
                        name="addressText"
                        value={printerSettings.addressText}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                        disabled={!printerSettings.showAddress}
                    />
                </div>

                <div>
                    <div className="flex items-center justify-between mb-1">
                        <label className="text-sm text-gray-500">Phone Number</label>
                        <input
                            type="checkbox"
                            name="showPhone"
                            checked={printerSettings.showPhone}
                            onChange={handleChange}
                            className="h-4 w-4"
                        />
                    </div>
                    <input
                        type="text"
                        name="phoneText"
                        value={printerSettings.phoneText}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                        disabled={!printerSettings.showPhone}
                    />
                </div>

                <div>
                    <div className="flex items-center justify-between mb-1">
                        <label className="text-sm text-gray-500">Footer Message</label>
                        <input
                            type="checkbox"
                            name="showFooter"
                            checked={printerSettings.showFooter}
                            onChange={handleChange}
                            className="h-4 w-4"
                        />
                    </div>
                    <input
                        type="text"
                        name="footerText"
                        value={printerSettings.footerText}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                        disabled={!printerSettings.showFooter}
                    />
                </div>
            </div>

            <div>
                <label className="block text-sm text-gray-500 mb-1">Font Size</label>
                <select
                    name="fontSize"
                    value={printerSettings.fontSize}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                >
                    <option value="small">Small</option>
                    <option value="normal">Normal</option>
                    <option value="large">Large</option>
                </select>
            </div>

            <button
                type="submit"
                className="w-full bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700 transition-colors"
            >
                Save Design
            </button>
        </form>
    );

    const renderSettingsTab = () => (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label className="block text-sm text-gray-500 mb-1">Printer Name</label>
                <input
                    type="text"
                    name="printerName"
                    value={printerSettings.printerName}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                    placeholder="Select printer..."
                />
            </div>

            <div>
                <label className="block text-sm text-gray-500 mb-1">Paper Width</label>
                <select
                    name="paperWidth"
                    value={printerSettings.paperWidth}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:border-orange-500"
                >
                    <option value="80mm">80mm Receipt</option>
                    <option value="58mm">58mm Receipt</option>
                </select>
            </div>

            <div>
                <label className="block text-sm text-gray-500 mb-1">
                    Print Density ({printerSettings.printDensity}%)
                </label>
                <input
                    type="range"
                    name="printDensity"
                    min="1"
                    max="100"
                    value={printerSettings.printDensity}
                    onChange={handleChange}
                    className="w-full"
                />
            </div>

            <div className="flex items-center space-x-2">
                <input
                    type="checkbox"
                    name="enableAutocut"
                    checked={printerSettings.enableAutocut}
                    onChange={handleChange}
                    className="h-4 w-4"
                />
                <label className="text-sm text-gray-500">Enable Auto-cut</label>
            </div>

            <button
                type="submit"
                className="w-full bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700 transition-colors"
            >
                Save Settings
            </button>
        </form>
    );

    return (
        <div className="grid grid-cols-2 gap-6">
            {/* Left side - Configuration Tabs */}
            <div className="space-y-6">
                <div className="bg-white border">
                    <div className="border-b">
                        <div className="flex">
                            <button
                                className={`px-4 py-2 text-sm font-medium ${activeTab === 'designer'
                                    ? 'text-orange-600 border-b-2 border-orange-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => setActiveTab('designer')}
                            >
                                Designer
                            </button>
                            <button
                                className={`px-4 py-2 text-sm font-medium ${activeTab === 'settings'
                                    ? 'text-orange-600 border-b-2 border-orange-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => setActiveTab('settings')}
                            >
                                Settings
                            </button>
                        </div>
                    </div>
                    <div className="p-6">
                        {activeTab === 'designer' ? renderDesignerTab() : renderSettingsTab()}
                    </div>
                </div>
            </div>

            {/* Right side - Receipt Preview */}
            <div className="space-y-6">
                <div className="bg-white border p-6">
                    <h2 className="text-xl font-semibold mb-6">Receipt Preview</h2>
                    <div className="bg-gray-100 p-4 rounded" style={{ width: printerSettings.paperWidth === '80mm' ? '302px' : '219px', margin: '0 auto' }}>
                        <div className={`bg-white p-4 rounded shadow-sm font-mono ${printerSettings.fontSize === 'small' ? 'text-sm' : printerSettings.fontSize === 'large' ? 'text-lg' : 'text-base'}`}>
                            {printerSettings.showLogo && (
                                <div className="text-center mb-2">
                                    [Store Logo]
                                </div>
                            )}
                            <div className="text-center font-bold mb-2">{printerSettings.headerText}</div>
                            {printerSettings.showAddress && (
                                <div className="text-center text-sm mb-1">{printerSettings.addressText}</div>
                            )}
                            {printerSettings.showPhone && (
                                <div className="text-center text-sm mb-2">{printerSettings.phoneText}</div>
                            )}
                            <div className="border-t border-b border-dashed py-2 my-2">
                                {receiptData.items.map((item, index) => (
                                    <div key={index} className="flex justify-between">
                                        <div>{item.qty}x {item.name}</div>
                                        <div>GHS {(item.price * item.qty).toFixed(2)}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-between font-bold mt-2">
                                <div>Total</div>
                                <div>GHS {receiptData.total.toFixed(2)}</div>
                            </div>
                            {printerSettings.showFooter && (
                                <div className="text-center text-sm mt-4">
                                    {printerSettings.footerText}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrinterSettings;