import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../firebase-config';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, updateProfile } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs, getFirestore, serverTimestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Mail, Lock, AlertCircle, Loader2, User, Smartphone } from 'lucide-react';
import womanCashier from '../../assets/woman-cashier.png';
import logo from '../../assets/hotKioskLogocolor.png';

function Signup() {
    // State management
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const db = getFirestore();
    const functions = getFunctions();

    // Feature flag to disable web signup
    const WEB_SIGNUP_DISABLED = true;

    const updateLastLogin = async () => {
        try {
            const updateLoginCallable = httpsCallable(functions, 'updateLastLogin');
            const result = await updateLoginCallable();
            if (result.data.success) {
                console.log('Last login updated successfully');
            }
        } catch (error) {
            console.error('Error updating last login:', error);
        }
    };

    const checkUserStoreAssignment = async (userId) => {
        try {
            const storeUserQuery = query(
                collection(db, 'StoreUsers'),
                where('userId', '==', userId),
            );
            const querySnapshot = await getDocs(storeUserQuery);
            return !querySnapshot.empty;
        } catch (error) {
            console.error('Error checking store assignment:', error);
            return false;
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();

        if (WEB_SIGNUP_DISABLED) {
            return;
        }

        if (!fullName.trim()) {
            setError('Full name is required');
            return;
        }
        setIsLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            await updateProfile(userCredential.user, {
                displayName: fullName.trim()
            });

            await setDoc(doc(db, 'Users', userCredential.user.uid), {
                email: email.trim(),
                displayName: fullName.trim(),
                photoURL: "",
                createdAt: serverTimestamp(),
                lastLogin: serverTimestamp(),
                isActive: true,
            });

            updateLastLogin();

            navigate('/roleselection', {
                state: { userId: userCredential.user.uid }
            });

        } catch (error) {
            let errorMessage = 'An error occurred during signup';
            if (error.code === 'auth/email-already-in-use') {
                errorMessage = 'This email is already registered';
            } else if (error.code === 'auth/weak-password') {
                errorMessage = 'Password should be at least 6 characters';
            }
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSignup = async () => {
        if (WEB_SIGNUP_DISABLED) {
            return;
        }

        setIsLoading(true);
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            await setDoc(doc(db, 'Users', result.user.uid), {
                email: result.user.email,
                displayName: result.user.displayName,
                photoURL: result.user.photoURL,
                createdAt: serverTimestamp(),
                lastLogin: serverTimestamp(),
                isActive: true,
            }, { merge: true });

            const isAssigned = await checkUserStoreAssignment(result.user.uid);

            updateLastLogin();

            if (isAssigned) {
                navigate('/home');
            } else {
                navigate('/roleselection', {
                    state: { userId: result.user.uid }
                });
            }
        } catch (error) {
            console.error('Google signup error:', error);
            setError('Failed to sign up with Google. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const renderMobileMessage = () => (
        <div className="text-center">
            <Smartphone className="w-16 h-16 text-blue-500 mx-auto mb-6" />
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Please use our mobile app to sign up
            </h3>
            <div className="bg-blue-50 p-6 rounded-lg mb-8">
                <p className="text-blue-700 mb-4">
                    Web signup is temporarily available only through our mobile application.
                </p>
                <p className="text-blue-700 font-medium">
                    Download our app from:
                </p>
                <div className="mt-4 space-y-3">
                    <a
                        href="https://apps.apple.com/us/app/hotkiosk/id6738089148"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full flex justify-center items-center py-3 px-4 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
                    >
                        Download on the App Store
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.jaydaylabs.hot_kiosk"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full flex justify-center items-center py-3 px-4 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
                    >
                        Get it on Google Play
                    </a>
                </div>
            </div>
            <p className="text-sm text-gray-600">
                Already have an account?{' '}
                <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                    Sign in
                </Link>
            </p>
        </div>
    );

    const renderForm = () => (
        <>
            {error && (
                <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-start gap-3">
                    <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
                    <p className="text-sm text-red-700">{error}</p>
                </div>
            )}

            <form onSubmit={handleSignup} className="space-y-6">
                <div>
                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                        Full Name
                    </label>
                    <div className="mt-1 relative">
                        <input
                            id="fullName"
                            type="text"
                            required
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className="appearance-none block w-full px-3 py-2 pl-10 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        <User className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
                    </div>
                </div>

                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                    </label>
                    <div className="mt-1 relative">
                        <input
                            id="email"
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="appearance-none block w-full px-3 py-2 pl-10 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
                    </div>
                </div>

                <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                    </label>
                    <div className="mt-1 relative">
                        <input
                            id="password"
                            type="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            minLength={6}
                            className="appearance-none block w-full px-3 py-2 pl-10 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full flex justify-center items-center py-2.5 px-4 rounded-lg text-white text-sm font-medium transition duration-150 ease-in-out ${isLoading
                            ? 'bg-blue-400 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                            }`}
                    >
                        {isLoading ? (
                            <>
                                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                                Creating account...
                            </>
                        ) : (
                            'Create account'
                        )}
                    </button>
                </div>
            </form>

            <div className="mt-6">
                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">Or continue with</span>
                    </div>
                </div>

                <div className="mt-6">
                    <button
                        onClick={handleGoogleSignup}
                        disabled={isLoading}
                        className={`w-full flex items-center justify-center py-2.5 px-4 rounded-lg text-gray-700 bg-white border border-gray-300 text-sm font-medium transition duration-150 ease-in-out ${isLoading
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                            }`}
                    >
                        <img
                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                            alt="Google logo"
                            className="w-5 h-5 mr-2"
                        />
                        Sign up with Google
                    </button>
                </div>
            </div>

            <p className="mt-6 text-center text-sm text-gray-600">
                Already have an account?{' '}
                <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                    Sign in
                </Link>
            </p>
        </>
    );

    return (
        <div className="min-h-screen bg-white flex">
            {/* Left side - Form or Mobile Message */}
            <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="mx-auto w-full max-w-md">
                    <div className="text-center mb-8">
                        <img
                            src={logo}
                            alt="Hot Kiosk Logo"
                            className="mx-auto h-12 w-auto mb-6"
                        />
                        <h2 className="text-3xl font-semibold text-gray-900">Create your account</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            Start managing your store today
                        </p>
                    </div>

                    {WEB_SIGNUP_DISABLED ? renderMobileMessage() : renderForm()}
                </div>
            </div>

            {/* Right side - Image/Pattern */}
            <div className="hidden lg:block relative w-0 flex-1 bg-gradient-to-br from-orange-50 to-red-50">
                <div className="absolute inset-0 flex items-center justify-center p-12">
                    <div className="max-w-lg">
                        <img
                            src={womanCashier}
                            alt="Store management illustration"
                            className="w-full h-auto rounded-lg shadow-lg"
                        />
                        <div className="mt-8 text-center">
                            <h3 className="text-2xl font-semibold text-gray-900">Take Your Store to Another Level</h3>
                            <p className="mt-4 text-gray-600">
                                Complete business management with inventory, sales, POS, advanced financial reports, AI and more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;