import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImagePlus, X, Loader2 } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const ImageUploadDropzone = ({
    onImageUploaded,
    currentImageUrl = null,
    storeId,
    className = ''
}) => {
    const [uploadProgress, setUploadProgress] = useState(false);
    const [error, setError] = useState(null);

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (!file) return;

        try {
            setError(null);
            setUploadProgress(true);

            // Convert file to base64
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const base64Data = reader.result.split(',')[1];

                // Call the cloud function
                const functions = getFunctions();
                const uploadImage = httpsCallable(functions, 'uploadImage');

                const result = await uploadImage({
                    imageData: base64Data,
                    fileName: file.name,
                    contentType: file.type,
                    storeId: storeId
                });

                if (result.data.success) {
                    onImageUploaded(result.data.imageUrl);
                } else {
                    throw new Error('Upload failed');
                }
            };
        } catch (err) {
            setError('Failed to upload image. Please try again.');
            console.error('Upload error:', err);
        } finally {
            setUploadProgress(false);
        }
    }, [storeId, onImageUploaded]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.webp']
        },
        maxFiles: 1,
        multiple: false
    });

    const removeImage = useCallback(() => {
        onImageUploaded(null);
    }, [onImageUploaded]);

    return (
        <div className={`relative ${className}`}>
            {currentImageUrl ? (
                <div className="relative w-full h-64 bg-gray-50 rounded-lg overflow-hidden">
                    <img
                        src={currentImageUrl}
                        alt="Uploaded preview"
                        className="w-full h-full object-contain"
                    />
                    <button
                        onClick={removeImage}
                        className="absolute top-2 right-2 p-1 bg-red-100 rounded-full hover:bg-red-200 transition-colors"
                        title="Remove image"
                    >
                        <X className="h-4 w-4 text-red-600" />
                    </button>
                </div>
            ) : (
                <div
                    {...getRootProps()}
                    className={`w-64 h-64 border-2 border-dashed rounded-lg flex flex-col items-center justify-center cursor-pointer transition-colors
            ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-gray-50 hover:bg-gray-100'}
            ${uploadProgress ? 'pointer-events-none' : ''}`}
                >
                    <input {...getInputProps()} />
                    {uploadProgress ? (
                        <div className="flex flex-col items-center">
                            <Loader2 className="h-12 w-12 text-blue-500 animate-spin" />
                            <p className="mt-4 text-sm text-gray-600">Uploading...</p>
                        </div>
                    ) : (
                        <>
                            <ImagePlus className="h-8 w-8 text-gray-400" />
                            <p className="mt-2 p-4 text-sm text-gray-600 flex text-center">
                                {isDragActive
                                    ? "Drop the image here"
                                    : "Drag & drop an image here, or click to select"}
                            </p>
                        </>
                    )}
                    {error && (
                        <p className="mt-2 text-sm text-red-600">{error}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImageUploadDropzone;