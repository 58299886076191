import React, { useState, useEffect, useRef } from 'react';
import { Plus, User2, X, Search, UserPlus } from 'lucide-react';
import AddCustomerModal from './add_customer';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const CustomerSection = ({ customer, setCustomer }) => {
    const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dropdownRef = useRef(null);
    const db = getFirestore();
    const auth = getAuth();

    const storeId = localStorage.getItem('storeId');

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        loadCustomers();
    }, []);

    const loadCustomers = async () => {
        try {
            setIsLoading(true);
            const userId = auth.currentUser?.uid;
            const userDoc = await getDocs(query(collection(db, 'Users'), where('uid', '==', userId)));
            // const storeId = userDoc.docs[0].data().storeId;

            const customersRef = collection(db, 'Stores', storeId, 'Customers');
            const querySnapshot = await getDocs(customersRef);
            const customersList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setCustomers(customersList);
        } catch (error) {
            console.error('Error loading customers:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCustomerAdded = (newCustomer) => {
        setCustomer(newCustomer);
        loadCustomers(); // Refresh the customer list
    };

    const removeCustomer = (e) => {
        e.stopPropagation();
        setCustomer(null);
    };

    const filteredCustomers = searchQuery
        ? customers.filter(c =>
            c.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            c.phone?.includes(searchQuery) ||
            c.email?.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : customers;

    return (
        <div className="relative" ref={dropdownRef}>
            <div className="relative w-40">
                {customer ? (
                    <div
                        onClick={() => setShowDropdown(true)}
                        className="flex items-center justify-between px-3 py-1.5 rounded bg-orange-50 text-orange-600 group cursor-pointer"
                    >
                        <div className="flex items-center min-w-0 flex-1">
                            <User2 size={16} className="flex-shrink-0" />
                            <span className="text-sm font-medium truncate ml-2">{customer.name}</span>
                        </div>
                        <button
                            onClick={removeCustomer}
                            className="flex-shrink-0 p-0.5 rounded-full hover:bg-orange-100 opacity-0 group-hover:opacity-100 transition-opacity ml-1"
                        >
                            <X size={14} />
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={() => setShowDropdown(true)}
                        className="flex items-center space-x-2 px-3 py-1.5 rounded bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors w-full"
                    >
                        <Plus size={16} className="flex-shrink-0" />
                        <User2 size={16} className="flex-shrink-0" />
                        <span className="hidden text-sm lg:block">Add Info</span>
                    </button>
                )}
            </div>

            {showDropdown && (
                <div className="absolute right-0 top-full mt-1 w-64 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                    <div className="p-2">
                        <div className="relative mb-2">
                            <input
                                type="text"
                                placeholder="Search customers..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-8 pr-4 py-1.5 text-sm border rounded"
                            />
                            <Search size={14} className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        </div>
                        <div className="max-h-48 overflow-y-auto">
                            {isLoading ? (
                                <div className="text-center py-2 text-sm text-gray-500">Loading...</div>
                            ) : filteredCustomers.length > 0 ? (
                                filteredCustomers.map(c => (
                                    <div
                                        key={c.id}
                                        onClick={() => {
                                            setCustomer(c);
                                            setShowDropdown(false);
                                            setSearchQuery('');
                                        }}
                                        className="px-3 py-2 hover:bg-gray-50 rounded cursor-pointer"
                                    >
                                        <div className="font-medium text-sm">{c.name}</div>
                                        <div className="text-xs text-gray-500">{c.phone}</div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center py-2 text-sm text-gray-500">No customers found</div>
                            )}
                        </div>
                        <button
                            onClick={() => {
                                setShowAddCustomerModal(true);
                                setShowDropdown(false);
                                setSearchQuery('');
                            }}
                            className="w-full mt-2 px-3 py-1.5 text-sm bg-gray-50 text-gray-600 hover:bg-gray-100 rounded flex items-center justify-center space-x-1"
                        >
                            <UserPlus size={14} />
                            <span>Add New Customer</span>
                        </button>
                    </div>
                </div>
            )}

            <AddCustomerModal
                isOpen={showAddCustomerModal}
                onClose={() => setShowAddCustomerModal(false)}
                onSuccess={handleCustomerAdded}
            />
        </div>
    );
};

export default CustomerSection;