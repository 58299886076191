import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const DEFAULT_RESTRICTED_URLS = [
    '/inventory',
    '/inventory/list',
    '/inventory/categories',
    '/inventory/stock',
    '/sales',
    '/sales/list',
    '/sales/quotes',
    '/sales/returns',
    '/sales/invoices',
    '/clients',
    '/clients/customers',
    '/clients/suppliers',
    '/clients/suppliers/purchase-orders',
    '/expenses',
    '/expenses/list',
    '/expenses/categories',
    '/expenses/recurring',
    '/reports',
    '/reports/analytics',
    '/reports/finance',
    '/reports/clients'
];

export function PrivateRoute({ children, requireFullAccess = false }) {
    const { user, loading, hasFullAccess, accessLevel } = useAuth();
    const [pageAccess, setPageAccess] = useState(null);
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const loadPageAccess = async () => {
            if (!user) {
                setLoadingPermissions(false);
                return;
            }

            const db = getFirestore();
            const storeId = localStorage.getItem('storeId');

            try {
                const storeDoc = await getDoc(doc(db, 'Stores', storeId));
                if (storeDoc.exists()) {
                    const data = storeDoc.data();
                    const pageAccessData = data.pageAccess || {};

                    // Handle both old and new formats
                    if (Array.isArray(pageAccessData[accessLevel])) {
                        // Convert old array format to new object format
                        const converted = {};
                        DEFAULT_RESTRICTED_URLS.forEach(url => {
                            converted[url] = pageAccessData[accessLevel].includes(url.replace(/^\//, ''));
                        });
                        setPageAccess(converted);
                    } else {
                        // Use new object format directly
                        setPageAccess(pageAccessData[accessLevel] || {});
                    }
                } else {
                    // If no store document exists, use default restrictions
                    const defaultAccess = {};
                    DEFAULT_RESTRICTED_URLS.forEach(url => {
                        defaultAccess[url] = false;
                    });
                    setPageAccess(defaultAccess);
                }
            } catch (err) {
                console.error('Error loading page access:', err);
                // On error, use default restrictions
                const defaultAccess = {};
                DEFAULT_RESTRICTED_URLS.forEach(url => {
                    defaultAccess[url] = false;
                });
                setPageAccess(defaultAccess);
            } finally {
                setLoadingPermissions(false);
            }
        };

        loadPageAccess();
    }, [user, accessLevel]);

    if (loading || loadingPermissions) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-gray-600">Loading...</div>
            </div>
        );
    }

    // Redirect to login if not authenticated
    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Check for full access requirement
    if (requireFullAccess && !hasFullAccess) {
        return <Navigate to="/unauthorized" replace />;
    }

    // Store owners (level 1) and admins (level 3) have full access
    if (accessLevel === 1 || accessLevel === 3) {
        return children;
    }

    // For cashiers (level 2), check exact URL permissions
    const currentPath = location.pathname;

    // First check if the current URL is in the restricted list
    if (DEFAULT_RESTRICTED_URLS.includes(currentPath)) {
        // If it's restricted, check if it's explicitly allowed in Firebase
        const isAllowed = pageAccess && pageAccess[currentPath] === true;

        if (!isAllowed) {
            return <Navigate to="/unauthorized" replace />;
        }
    }

    return children;
}

// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';

// export function PrivateRoute({ children }) {
//     const { user, loading } = useAuth();

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (!user) {
//         return <Navigate to="/login" />;
//     }

//     return children;
// }