import React, { useState, memo } from 'react';
import { X, Printer, Mail, Download, Clock, CheckCircle, AlertTriangle, Edit, Loader2 } from 'lucide-react';
import { query, limit, orderBy, getDocs, getDoc, updateDoc, doc, getFirestore, runTransaction, collection, serverTimestamp } from 'firebase/firestore';
import { generateQuotePDF, printQuote, sendQuoteEmail } from './quote_utils';

// Memoized PaymentModal component
const PaymentModal = memo(({
    onClose,
    onSubmit,
    isProcessing,
    totalAmount,
}) => {
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [amountReceived, setAmountReceived] = useState('');

    const handleSubmit = () => {
        onSubmit({
            paymentMethod,
            amountReceived: parseFloat(amountReceived)
        });
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" onClick={(e) => e.stopPropagation()}>
            <div className="bg-white rounded-lg p-6 w-full max-w-md" onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-bold">Complete Payment</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100"
                    >
                        <X size={24} />
                    </button>
                </div>

                <div className="space-y-6">
                    <div className="text-lg font-semibold flex justify-between">
                        <span>Total Amount:</span>
                        <span>GHS {totalAmount.toFixed(2)}</span>
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Payment Method</label>
                        <div className="grid grid-cols-3 gap-2">
                            {['cash', 'card', 'mobile_money'].map(method => (
                                <button
                                    key={method}
                                    onClick={() => setPaymentMethod(method)}
                                    className={`p-2 rounded-lg border transition-colors ${paymentMethod === method
                                        ? 'bg-blue-500 text-white border-blue-500'
                                        : 'bg-white border-gray-300 hover:bg-gray-50'
                                        }`}
                                >
                                    {method.replace('_', ' ').toUpperCase()}
                                </button>
                            ))}
                        </div>
                    </div>

                    {paymentMethod === 'cash' && (
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Amount Received
                            </label>
                            <input
                                type="number"
                                value={amountReceived}
                                onChange={(e) => setAmountReceived(e.target.value)}
                                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter amount"
                            />
                            {Number(amountReceived) > totalAmount && (
                                <div className="text-right text-sm text-gray-600">
                                    Change: GHS {(Number(amountReceived) - totalAmount).toFixed(2)}
                                </div>
                            )}
                        </div>
                    )}

                    <button
                        onClick={handleSubmit}
                        disabled={isProcessing || (paymentMethod === 'cash' && Number(amountReceived) < totalAmount)}
                        className={`w-full p-3 rounded-lg text-white transition-colors ${isProcessing || (paymentMethod === 'cash' && Number(amountReceived) < totalAmount)
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-green-500 hover:bg-green-600'
                            }`}
                    >
                        {isProcessing ? 'Processing...' : 'Complete Payment'}
                    </button>
                </div>
            </div>
        </div>
    );
});

PaymentModal.displayName = 'PaymentModal';

const QuoteDetails = ({ quote, onClose }) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState({
        print: false,
        email: false,
        download: false
    });
    const [showEmailDialog, setShowEmailDialog] = useState(false);
    const [emailAddress, setEmailAddress] = useState(quote.customerDetails.email);
    const [error, setError] = useState(null);

    // New payment-related state
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [amountReceived, setAmountReceived] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    const handlePurchase = async () => {
        setShowPaymentModal(true);
    };

    // Helper function to generate order number
    const generateOrderNumber = async (storeId) => {
        const salesRef = collection(db, 'Stores', storeId, 'Sales');
        const q = query(salesRef, orderBy('createdAt', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return 'ORD-0001';
        } else {
            const lastDoc = querySnapshot.docs[0];
            const lastOrderNumber = lastDoc.data().orderNumber;
            const lastNumber = parseInt(lastOrderNumber.split('-')[1]);
            const newNumber = lastNumber + 1;
            return `ORD-${newNumber.toString().padStart(4, '0')}`;
        }
    };

    const handlePayment = async ({ paymentMethod, amountReceived }) => {
        setIsProcessing(true);
        try {
            const result = await runTransaction(db, async (transaction) => {
                // First, perform all reads
                const inventoryReads = await Promise.all(
                    quote.items.map(async (item) => {
                        const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
                        const inventoryDoc = await transaction.get(inventoryRef);

                        if (!inventoryDoc.exists()) {
                            throw new Error(`Inventory item ${item.id} not found`);
                        }

                        return {
                            ref: inventoryRef,
                            currentQuantity: inventoryDoc.data().quantity,
                            item
                        };
                    })
                );

                const quoteRef = doc(db, 'Stores', storeId, 'SalesQuotes', quote.id);
                await transaction.get(quoteRef); // Read the quote document

                // Verify inventory quantities
                for (const { currentQuantity, item } of inventoryReads) {
                    if (currentQuantity < item.quantity) {
                        throw new Error(`Insufficient stock for item: ${item.itemName}`);
                    }
                }

                const orderNumber = await generateOrderNumber(storeId);

                // Now perform all writes
                // 1. Create sale document
                const saleRef = doc(collection(db, 'Stores', storeId, 'Sales'));
                const saleTransaction = {
                    orderNumber: orderNumber,
                    items: quote.items.map(item => ({
                        inventoryItemId: item.id,
                        itemName: item.itemName,
                        price: item.price,
                        quantity: item.quantity,
                        sku: item.sku
                    })),
                    totalCost: quote.total,
                    saleDate: new Date(),
                    createdAt: serverTimestamp(),
                    isCreditSale: false,
                    isPaid: true,
                    paymentMethod,
                    amountReceived,
                    change: Math.max(0, amountReceived - quote.total),
                    storeId,
                    quoteId: quote.id,
                    customerDetails: quote.customerDetails
                };
                transaction.set(saleRef, saleTransaction);

                // 2. Update inventory quantities
                inventoryReads.forEach(({ ref, currentQuantity, item }) => {
                    const newQuantity = Math.max(0, currentQuantity - item.quantity);
                    transaction.update(ref, { quantity: newQuantity });
                });

                // 3. Update quote status
                transaction.update(quoteRef, {
                    status: 'purchased',
                    purchasedAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                });

                return { saleRef, saleTransaction };
            });

            setShowPaymentModal(false);
            setError(null);

            // Optionally show success message or trigger a refresh
            window.location.reload(); // Or use a more sophisticated update mechanism

        } catch (error) {
            console.error('Payment error:', error);
            setError('Failed to process payment: ' + error.message);
        } finally {
            setIsProcessing(false);
        }
    };

    const handlePrint = async () => {
        setIsLoading(prev => ({ ...prev, print: true }));
        setError(null);
        try {
            await printQuote(quote);
        } catch (error) {
            setError('Failed to print quote. Please try again.');
        } finally {
            setIsLoading(prev => ({ ...prev, print: false }));
        }
    };

    const handleEmail = async () => {
        if (!emailAddress) return;

        setIsLoading(prev => ({ ...prev, email: true }));
        setError(null);
        try {
            await sendQuoteEmail(quote, emailAddress);
            setShowEmailDialog(false);
        } catch (error) {
            setError('Failed to send email. Please try again.');
        } finally {
            setIsLoading(prev => ({ ...prev, email: false }));
        }
    };

    const getDaysRemaining = () => {
        const validUntil = new Date(quote.terms.validUntil);
        const now = new Date();
        const diffTime = validUntil - now;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    const handleDownload = async () => {
        setIsLoading(prev => ({ ...prev, download: true }));
        setError(null);
        try {
            const pdfBytes = await generateQuotePDF(quote);
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Quote-${quote.quoteNumber}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            setError('Failed to download quote. Please try again.');
        } finally {
            setIsLoading(prev => ({ ...prev, download: false }));
        }
    };

    const isExpired = () => {
        const validUntil = new Date(quote.terms.validUntil);
        const now = new Date();
        return now > validUntil;
    };

    const getQuoteStatus = () => {
        if (quote.status === 'purchased') {
            return {
                label: 'Purchased',
                className: 'bg-green-50 text-green-700',
                icon: <CheckCircle className="h-4 w-4 mr-2" />
            };
        }

        if (quote.status === 'expired' || isExpired()) {
            return {
                label: 'Expired',
                className: 'bg-red-50 text-red-700',
                icon: <AlertTriangle className="h-4 w-4 mr-2" />
            };
        }

        return {
            label: 'Active',
            className: 'bg-blue-50 text-blue-700',
            icon: <Clock className="h-4 w-4 mr-2" />
        };
    };

    const renderActionButton = () => {
        const status = getQuoteStatus();

        if (quote.status === 'purchased') {
            return (
                <div className={`px-4 py-2 rounded-lg flex items-center ${status.className}`}>
                    {status.icon}
                    Purchased on {new Date(quote.purchasedAt).toLocaleDateString()}
                </div>
            );
        }

        if (isExpired()) {
            return (
                <div className={`px-4 py-2 rounded-lg flex items-center ${status.className}`}>
                    {status.icon}
                    Quote Expired
                </div>
            );
        }

        return (
            <button
                onClick={handlePurchase}
                disabled={isUpdating}
                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center disabled:bg-green-300"
            >
                {isUpdating ? (
                    <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        Processing...
                    </>
                ) : (
                    <>
                        <CheckCircle className="h-4 w-4 mr-2" />
                        Quote Payment
                    </>
                )}
            </button>
        );
    };

    const EmailDialog = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <h3 className="text-lg font-medium mb-4">Send Quote via Email</h3>
                <input
                    type="email"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    className="w-full p-2 border rounded-lg mb-4"
                    placeholder="Enter email address"
                />
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={() => setShowEmailDialog(false)}
                        className="px-4 py-2 border rounded-lg"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleEmail}
                        disabled={!emailAddress || isLoading.email}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-blue-300"
                    >
                        {isLoading.email ? 'Sending...' : 'Send'}
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-6 border-b">
                    <div>
                        <h2 className="text-2xl font-semibold">Quote Details</h2>
                        <div className="flex items-center mt-1">
                            <p className="text-sm text-gray-500 mr-3">Quote #{quote.quoteNumber}</p>
                            <div className={`text-sm px-2 py-1 rounded-full flex items-center ${getQuoteStatus().className}`}>
                                {getQuoteStatus().icon}
                                {getQuoteStatus().label}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={handlePrint}
                            disabled={isLoading.print}
                            className="p-2 hover:bg-gray-100 rounded-lg disabled:opacity-50"
                            title="Print Quote"
                        >
                            {isLoading.print ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : (
                                <Printer className="h-5 w-5 text-gray-600" />
                            )}
                        </button>
                        <button
                            onClick={() => setShowEmailDialog(true)}
                            disabled={isLoading.email}
                            className="p-2 hover:bg-gray-100 rounded-lg disabled:opacity-50"
                            title="Email Quote"
                        >
                            {isLoading.email ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : (
                                <Mail className="h-5 w-5 text-gray-600" />
                            )}
                        </button>
                        <button
                            onClick={handleDownload}
                            disabled={isLoading.download}
                            className="p-2 hover:bg-gray-100 rounded-lg disabled:opacity-50"
                            title="Download PDF"
                        >
                            {isLoading.download ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : (
                                <Download className="h-5 w-5 text-gray-600" />
                            )}
                        </button>
                        <button
                            onClick={onClose}
                            className="p-2 hover:bg-gray-100 rounded-lg"
                        >
                            <X className="h-5 w-5 text-gray-600" />
                        </button>
                    </div>
                </div>

                <div className="p-6">
                    {/* Customer & Quote Information */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <h3 className="font-medium mb-4">Customer Information</h3>
                            <div className="space-y-2">
                                <p><span className="text-gray-500">Name:</span> {quote.customerDetails.name}</p>
                                <p><span className="text-gray-500">Email:</span> {quote.customerDetails.email}</p>
                                <p><span className="text-gray-500">Phone:</span> {quote.customerDetails.phone || 'N/A'}</p>
                                <p><span className="text-gray-500">Address:</span> {quote.customerDetails.address || 'N/A'}</p>
                            </div>
                        </div>

                        <div className="bg-gray-50 p-4 rounded-lg">
                            <h3 className="font-medium mb-4">Quote Information</h3>
                            <div className="space-y-2">
                                <p><span className="text-gray-500">Created Date:</span> {new Date(quote.createdAt).toLocaleDateString()}</p>
                                <p><span className="text-gray-500">Valid Until:</span> {new Date(quote.terms.validUntil).toLocaleDateString()}</p>
                                {quote.status === 'purchased' && (
                                    <p><span className="text-gray-500">Purchase Date:</span> {new Date(quote.purchasedAt).toLocaleDateString()}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Validity Warning */}
                    {!isExpired() && quote.status !== 'purchased' && (
                        <div className="mb-6">
                            <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4 flex items-center">
                                <Clock className="h-5 w-5 text-yellow-500 mr-3" />
                                <div>
                                    <p className="text-yellow-700">
                                        This quote is valid until {new Date(quote.terms.validUntil).toLocaleDateString()}
                                        <span className="ml-1">
                                            ({getDaysRemaining()} days remaining)
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Items Table */}
                    <div className="mb-8">
                        <h3 className="font-medium mb-4">Items</h3>
                        <div className="border rounded-lg overflow-hidden">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Item</th>
                                        <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Quantity</th>
                                        <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Price</th>
                                        <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {quote.items.map((item, index) => (
                                        <tr key={index}>
                                            <td className="px-4 py-2">
                                                <div className="font-medium">{item.itemName}</div>
                                                <div className="text-sm text-gray-500">SKU: {item.sku}</div>
                                            </td>
                                            <td className="px-4 py-2 text-right">{item.quantity}</td>
                                            <td className="px-4 py-2 text-right">GHS {item.price.toFixed(2)}</td>
                                            <td className="px-4 py-2 text-right">GHS {(item.price * item.quantity).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Summary */}
                    <div className="mb-8">
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <div className="space-y-2">
                                <div className="flex justify-between">
                                    <span className="text-gray-500">Subtotal:</span>
                                    <span>GHS {quote.subtotal.toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-500">Discount ({quote.discount}%):</span>
                                    <span>GHS {((quote.subtotal * quote.discount) / 100).toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-500">Tax ({quote.tax}%):</span>
                                    <span>GHS {((quote.subtotal * (1 - quote.discount / 100) * quote.tax) / 100).toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between font-medium text-lg pt-2 border-t">
                                    <span>Total:</span>
                                    <span>GHS {quote.total.toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Notes */}
                    {quote.terms.notes && (
                        <div className="mb-8">
                            <h3 className="font-medium mb-2">Notes</h3>
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <p className="text-gray-600">{quote.terms.notes}</p>
                            </div>
                        </div>
                    )}

                    {/* Error Display */}
                    {error && (
                        <div className="mb-6">
                            <div className="bg-red-50 border border-red-100 rounded-lg p-4 text-red-600">
                                {error}
                            </div>
                        </div>
                    )}

                    {/* Actions */}
                    <div className="flex justify-between items-center pt-6 border-t">
                        {!isExpired() && quote.status !== 'purchased' && (
                            <button
                                className="px-4 py-2 text-blue-500 rounded-lg hover:bg-blue-50 flex items-center"
                            >
                                <Edit className="h-4 w-4 mr-2" />
                                Edit Quote
                            </button>
                        )}
                        {renderActionButton()}
                    </div>
                </div>
            </div>

            {/* Email Dialog */}
            {showEmailDialog && <EmailDialog />}
            {showPaymentModal && (
                <PaymentModal
                    onClose={() => setShowPaymentModal(false)}
                    onSubmit={handlePayment}
                    isProcessing={isProcessing}
                    totalAmount={quote.total}
                />
            )}
        </div>
    );
};

export default QuoteDetails;