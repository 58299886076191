import React, { useState } from 'react';
import { Clock, ChevronDown } from 'lucide-react';

const TimePeriodPicker = ({ onDateRangeChange, selectedLabel, onLabelChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showCustomPicker, setShowCustomPicker] = useState(false);
    const [customStartDate, setCustomStartDate] = useState('');
    const [customEndDate, setCustomEndDate] = useState('');

    const ranges = [
        {
            label: 'Today',
            getDates: () => {
                const today = new Date();
                return { start: today, end: today };
            }
        },
        {
            label: 'Yesterday',
            getDates: () => {
                const today = new Date();
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);
                return { start: yesterday, end: yesterday };
            }
        },
        {
            label: 'This Week',
            getDates: () => {
                const today = new Date();
                const startOfWeek = new Date(today);
                startOfWeek.setDate(today.getDate() - today.getDay());
                return { start: startOfWeek, end: today };
            }
        },
        {
            label: 'Last Week',
            getDates: () => {
                const today = new Date();
                const startOfLastWeek = new Date(today);
                startOfLastWeek.setDate(today.getDate() - today.getDay() - 7);
                const endOfLastWeek = new Date(startOfLastWeek);
                endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
                return { start: startOfLastWeek, end: endOfLastWeek };
            }
        },
        {
            label: 'This Month',
            getDates: () => {
                const today = new Date();
                const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                return { start: startOfMonth, end: today };
            }
        },
        {
            label: 'Last 30 Days',
            getDates: () => {
                const today = new Date();
                const thirtyDaysAgo = new Date(today);
                thirtyDaysAgo.setDate(today.getDate() - 30);
                return { start: thirtyDaysAgo, end: today };
            }
        },
        {
            label: 'Last 90 Days',
            getDates: () => {
                const today = new Date();
                const ninetyDaysAgo = new Date(today);
                ninetyDaysAgo.setDate(today.getDate() - 90);
                return { start: ninetyDaysAgo, end: today };
            }
        },
        {
            label: 'Last 3 Months',
            getDates: () => {
                const today = new Date();
                const threeMonthsAgo = new Date(today);
                threeMonthsAgo.setMonth(today.getMonth() - 3);
                return { start: threeMonthsAgo, end: today };
            }
        },
        {
            label: 'Last 6 Months',
            getDates: () => {
                const today = new Date();
                const sixMonthsAgo = new Date(today);
                sixMonthsAgo.setMonth(today.getMonth() - 6);
                return { start: sixMonthsAgo, end: today };
            }
        },
        {
            label: 'Year to Date',
            getDates: () => {
                const today = new Date();
                const startOfYear = new Date(today.getFullYear(), 0, 1);
                return { start: startOfYear, end: today };
            }
        },
        {
            label: 'Custom Range',
            isCustom: true
        }
    ];

    const handleSelect = (range) => {
        if (range.isCustom) {
            setShowCustomPicker(true);
        } else {
            const { start, end } = range.getDates();
            onLabelChange(range.label);
            onDateRangeChange(start, end, range.label);
        }
        setIsOpen(false);
    };

    const handleCustomDateSubmit = (e) => {
        e.preventDefault();
        if (customStartDate && customEndDate) {
            const start = new Date(customStartDate);
            const end = new Date(customEndDate);
            const label = `${new Date(customStartDate).toLocaleDateString()} - ${new Date(customEndDate).toLocaleDateString()}`;
            onLabelChange(label);
            onDateRangeChange(start, end, label);
            setShowCustomPicker(false);
        }
    };

    return (
        <div className="relative">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center space-x-2 bg-white border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
                <Clock className="h-4 w-4 text-gray-500" />
                <span className="text-sm text-gray-700">{selectedLabel}</span>
                <ChevronDown className="h-4 w-4 text-gray-500" />
            </button>

            {isOpen && (
                <div className="absolute z-10 right-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-gray-200">
                    <div className="py-1">
                        {ranges.map((range, index) => (
                            <button
                                key={range.label}
                                onClick={() => handleSelect(range)}
                                className={`
                                    w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100
                                    ${selectedLabel === range.label ? 'bg-blue-50 text-blue-600' : ''}
                                    ${index === 0 ? 'rounded-t-lg' : ''}
                                    ${index === ranges.length - 1 ? 'rounded-b-lg' : ''}
                                `}
                            >
                                {range.label}
                            </button>
                        ))}
                    </div>
                </div>
            )}

            {showCustomPicker && (
                <div className="absolute z-10 right-0 mt-1 p-4 w-64 bg-white rounded-lg shadow-lg border border-gray-200">
                    <form onSubmit={handleCustomDateSubmit} className="space-y-3">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Start Date</label>
                            <input
                                type="date"
                                value={customStartDate}
                                onChange={(e) => setCustomStartDate(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">End Date</label>
                            <input
                                type="date"
                                value={customEndDate}
                                onChange={(e) => setCustomEndDate(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                type="button"
                                onClick={() => setShowCustomPicker(false)}
                                className="px-3 py-2 text-sm text-gray-600 hover:text-gray-800"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-3 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            >
                                Apply
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default TimePeriodPicker;