import jsPDF from 'jspdf';
import { collection, addDoc, getFirestore } from 'firebase/firestore';
import { format } from 'date-fns';

export const generateQuotePDF = async (quote) => {
    const doc = new jsPDF();

    // Define margins and columns
    const margin = {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20
    };
    const pageWidth = 210; // A4 width in mm
    const usableWidth = pageWidth - margin.left - margin.right;

    // Column positions for the right side info
    const rightCol = {
        labels: 120,
        values: 180
    };

    // Column positions for the table
    const tableColumns = {
        qty: margin.left,
        description: 45,
        unitPrice: 120,
        amount: 190
    };

    const addHeader = () => {
        // Quote number at top right
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.text(quote.quoteNumber, rightCol.values, margin.top, { align: 'right' });

        // Header
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(40);
        doc.setTextColor(20, 30, 80);
        doc.text('QUOTE', margin.left, 30);

        // Company details
        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.text('East Repair Inc.', margin.left, 45);
        doc.setFont('helvetica', 'normal');
        doc.text('1912 Harvest Lane', margin.left, 52);
        doc.text('New York, NY 12210', margin.left, 59);

        return 80; // Return next Y position
    };

    let currentY = addHeader();

    // Bill To section
    doc.setTextColor(0);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('BILL TO', margin.left, currentY);

    // Quote details section (right-aligned) with reduced spacing
    doc.text('QUOTE DATE', rightCol.labels, currentY);
    doc.text('VALID UNTIL', rightCol.labels, currentY + 7);

    doc.setFont('helvetica', 'normal');
    doc.text(format(new Date(quote.createdAt), 'dd/MM/yyyy'), rightCol.values, currentY, { align: 'right' });
    doc.text(format(new Date(quote.terms.validUntil), 'dd/MM/yyyy'), rightCol.values, currentY + 7, { align: 'right' });

    // Updated customer details with name, email, and phone
    currentY += 10;
    doc.setFont('helvetica', 'bold');
    doc.text(quote.customerDetails.name, margin.left, currentY);

    doc.setFont('helvetica', 'normal');
    currentY += 7;
    doc.text(quote.customerDetails.email || '', margin.left, currentY);

    currentY += 7;
    doc.text(quote.customerDetails.phone || '', margin.left, currentY);

    // Table headers
    currentY += 30;
    doc.setDrawColor(220, 50, 50);
    doc.line(margin.left, currentY, pageWidth - margin.right, currentY);

    doc.setFont('helvetica', 'bold');
    doc.text('QTY', tableColumns.qty, currentY - 5);
    doc.text('DESCRIPTION', tableColumns.description, currentY - 5);
    doc.text('UNIT PRICE', tableColumns.unitPrice, currentY - 5);
    doc.text('AMOUNT', tableColumns.amount, currentY - 5, { align: 'right' });

    doc.line(margin.left, currentY + 2, pageWidth - margin.right, currentY + 2);

    // Items section
    currentY += 15;
    doc.setFont('helvetica', 'normal');

    // Function to add new page
    const addNewPage = () => {
        doc.addPage();
        currentY = addHeader();
        currentY += 20;

        doc.setDrawColor(220, 50, 50);
        doc.line(margin.left, currentY, pageWidth - margin.right, currentY);

        doc.setFont('helvetica', 'bold');
        doc.text('QTY', tableColumns.qty, currentY - 5);
        doc.text('DESCRIPTION', tableColumns.description, currentY - 5);
        doc.text('UNIT PRICE', tableColumns.unitPrice, currentY - 5);
        doc.text('AMOUNT', tableColumns.amount, currentY - 5, { align: 'right' });

        doc.line(margin.left, currentY + 2, pageWidth - margin.right, currentY + 2);
        currentY += 15;
        doc.setFont('helvetica', 'normal');
    };

    quote.items.forEach(item => {
        if (currentY > 250) {
            addNewPage();
        }

        doc.text(item.quantity.toString(), tableColumns.qty, currentY);

        const descriptionWidth = tableColumns.unitPrice - tableColumns.description - 10;
        const lines = doc.splitTextToSize(item.itemName, descriptionWidth);
        doc.text(lines, tableColumns.description, currentY);

        doc.text(`GHS ${item.price.toFixed(2)}`, tableColumns.unitPrice, currentY);

        doc.text(
            `GHS ${(item.price * item.quantity).toFixed(2)}`,
            tableColumns.amount,
            currentY,
            { align: 'right' }
        );

        currentY += (lines.length * 7);
    });

    if (currentY > 240) {
        addNewPage();
    }

    // Terms and Conditions section (Left side)
    const termsY = currentY + 10;
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(24);
    doc.setTextColor(20, 30, 80);
    doc.text('Thank you', margin.left, termsY);

    doc.setTextColor(0);
    doc.setFontSize(12);
    doc.text('TERMS & CONDITIONS', margin.left, termsY + 15);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text([
        'Payment is due within 15 days',
        'Please make checks payable to: East Repair Inc.'
    ], margin.left, termsY + 25);

    // Totals section (Right side)
    const totalsLabelX = tableColumns.unitPrice;
    const totalsValueX = tableColumns.amount;

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text('Subtotal', totalsLabelX, termsY);
    doc.text(`GHS ${quote.subtotal.toFixed(2)}`, totalsValueX, termsY, { align: 'right' });

    let totalsY = termsY + 7;
    if (quote.tax > 0) {
        doc.text(`Tax (${quote.tax}%)`, totalsLabelX, totalsY);
        doc.text(`GHS ${((quote.subtotal * quote.tax) / 100).toFixed(2)}`, totalsValueX, totalsY, { align: 'right' });
        totalsY += 7;
    }

    doc.line(totalsLabelX, totalsY, totalsValueX, totalsY);
    totalsY += 7;
    doc.setFont('helvetica', 'bold');
    doc.text('TOTAL', totalsLabelX, totalsY);
    doc.text(`GHS ${quote.total.toFixed(2)}`, totalsValueX, totalsY, { align: 'right' });

    // Footer
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.setTextColor(128);
    doc.text('Powered by Hotkiosk · www.hotkiosk.com', pageWidth / 2, 280, { align: 'center' });

    return doc.output('arraybuffer');
};

export const sendQuoteEmail = async (quote, recipientEmail = null) => {
    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    try {
        await addDoc(collection(db, 'Stores', storeId, 'EmailQueue'), {
            type: 'quote',
            quoteId: quote.id,
            quoteNumber: quote.quoteNumber,
            recipient: recipientEmail || quote.customerDetails.email,
            status: 'pending',
            createdAt: new Date().toISOString(),
            attachPDF: true,
            template: 'quote_email',
            data: {
                customerName: quote.customerDetails.name,
                quoteNumber: quote.quoteNumber,
                total: quote.total,
                validUntil: quote.terms.validUntil,
                items: quote.items.map(item => ({
                    name: item.itemName,
                    quantity: item.quantity,
                    price: item.price
                }))
            }
        });

        return { success: true };
    } catch (error) {
        console.error('Error sending quote email:', error);
        throw new Error('Failed to send quote email');
    }
};

export const printQuote = async (quote) => {
    try {
        const pdfBytes = await generateQuotePDF(quote);

        // Create a blob from the PDF bytes
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        // Create an iframe to print the PDF
        const printFrame = document.createElement('iframe');
        printFrame.style.display = 'none';
        printFrame.src = url;

        document.body.appendChild(printFrame);
        printFrame.onload = () => {
            try {
                printFrame.contentWindow.print();
            } catch (error) {
                console.error('Print failed:', error);
                // Fallback: Open PDF in new tab
                window.open(url, '_blank');
            }

            // Cleanup
            setTimeout(() => {
                document.body.removeChild(printFrame);
                URL.revokeObjectURL(url);
            }, 1000);
        };
    } catch (error) {
        console.error('Error printing quote:', error);
        throw new Error('Failed to print quote');
    }
};