import React, { useState, useEffect } from 'react';
import { Search, ArrowLeft, Download, Grid, List } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import { Link } from 'react-router-dom';
import TimePeriodPicker from '../components/time_period_picker';

const SalesList = () => {
    const [salesData, setSalesData] = useState({
        sales: [],
        isLoading: true,
        error: null
    });
    const [viewMode, setViewMode] = useState('orders');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTimeLabel, setSelectedTimeLabel] = useState('Today');
    const [dateRange, setDateRange] = useState(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return {
            startDate: today,
            endDate: new Date()
        };
    });

    const salesPerPage = 20;
    const db = getFirestore();
    const auth = getAuth();

    const handleDateRangeChange = (start, end, label) => {
        setDateRange({
            startDate: start,
            endDate: end
        });
        setSelectedTimeLabel(label);
    };

    const getFilteredData = () => {
        if (viewMode === 'orders') {
            return salesData.sales.filter(sale =>
                sale.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (sale.orderNumber && sale.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        } else {
            const allItems = salesData.sales.flatMap(sale =>
                sale.items.map(item => ({
                    ...item,
                    saleId: sale.id,
                    orderNumber: sale.orderNumber,
                    saleDate: sale.saleDate
                }))
            );
            return allItems.filter(item =>
                item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.orderNumber && item.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }
    };

    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (userDoc.empty) {
                    throw new Error('User data not found');
                }

                const userData = userDoc.docs[0].data();

                const salesQuery = query(
                    collection(db, 'Stores', userData.storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(dateRange.startDate)),
                    where('saleDate', '<=', Timestamp.fromDate(dateRange.endDate)),
                    orderBy('saleDate', 'desc')
                );

                const salesSnapshot = await getDocs(salesQuery);
                const sales = salesSnapshot.docs.map(doc => {
                    const saleData = doc.data();
                    const totalAmount = saleData.items.reduce(
                        (sum, item) => sum + (item.price * item.quantity),
                        0
                    );
                    return {
                        id: doc.id,
                        ...saleData,
                        saleDate: saleData.saleDate.toDate(),
                        totalAmount
                    };
                });

                setSalesData({
                    sales,
                    isLoading: false,
                    error: null
                });
            } catch (error) {
                console.error('Error fetching sales data:', error);
                setSalesData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load sales data'
                }));
            }
        };

        fetchSalesData();
    }, [db, dateRange]);

    const exportToCSV = () => {
        let headers, csvContent;

        if (viewMode === 'orders') {
            headers = ['Order No.', 'Date', 'Amount', 'Items'];
            csvContent = [
                headers.join(','),
                ...filteredData.map(sale => [
                    sale.orderNumber || `ORD${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`,
                    sale.saleDate.toLocaleString(),
                    sale.totalAmount.toFixed(2),
                    sale.items.length
                ].join(','))
            ].join('\n');
        } else {
            headers = ['Order No.', 'Date', 'Item Name', 'Quantity', 'Price', 'Total'];
            csvContent = [
                headers.join(','),
                ...filteredData.map(item => [
                    item.orderNumber || `ORD${String(parseInt(item.saleId.slice(-6), 16)).padStart(6, '0')}`,
                    item.saleDate.toLocaleString(),
                    item.itemName,
                    item.quantity,
                    item.price.toFixed(2),
                    (item.price * item.quantity).toFixed(2)
                ].join(','))
            ].join('\n');
        }

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `sales_${viewMode}_report.csv`;
        link.click();
    };

    const filteredData = getFilteredData();
    const totalPages = Math.ceil(filteredData.length / salesPerPage);
    const indexOfLastItem = currentPage * salesPerPage;
    const indexOfFirstItem = indexOfLastItem - salesPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    if (salesData.isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="text-lg">Loading sales data...</div>
                </div>
            </div>
        );
    }

    if (salesData.error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center text-red-500">
                    <div>{salesData.error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 p-8 overflow-y-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <Link
                            to="/sales"
                            className="mr-4 text-gray-500 hover:text-gray-700 transition-colors"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </Link>
                        <h1 className="text-3xl font-semibold">All Sales</h1>
                    </div>
                    <div className="flex items-center space-x-4">
                        {/* View Toggle */}
                        <div className="flex items-center bg-gray-100 rounded-lg p-1">
                            <button
                                onClick={() => setViewMode('orders')}
                                className={`flex items-center px-3 py-1 rounded-md ${viewMode === 'orders'
                                    ? 'bg-white text-blue-600 shadow'
                                    : 'text-gray-600'
                                    }`}
                            >
                                <List className="h-4 w-4 mr-2" />
                                Orders
                            </button>
                            <button
                                onClick={() => setViewMode('items')}
                                className={`flex items-center px-3 py-1 rounded-md ${viewMode === 'items'
                                    ? 'bg-white text-blue-600 shadow'
                                    : 'text-gray-600'
                                    }`}
                            >
                                <Grid className="h-4 w-4 mr-2" />
                                Items
                            </button>
                        </div>

                        <TimePeriodPicker
                            onDateRangeChange={handleDateRangeChange}
                            selectedLabel={selectedTimeLabel}
                            onLabelChange={setSelectedTimeLabel}
                        />
                    </div>
                </div>

                <div className="flex items-center justify-between mb-5">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder={`Search ${viewMode}...`}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                            <Search className="h-5 w-5 text-gray-400" />
                        </div>
                    </div>
                    <button
                        onClick={exportToCSV}
                        className="inline-flex items-center px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
                    >
                        <Download className="h-4 w-4 mr-2" />
                        Export
                    </button>
                </div>

                <div className="bg-white overflow-hidden border p-5">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                {viewMode === 'orders' ? (
                                    <>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Order No.
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Date
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Amount
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Items
                                        </th>
                                    </>
                                ) : (
                                    <>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Order No.
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Date
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Item Name
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Quantity
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Price
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Total
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentItems.length === 0 ? (
                                <tr>
                                    <td colSpan={viewMode === 'orders' ? 4 : 6} className="px-6 py-4 text-center text-gray-500">
                                        No {viewMode} found
                                    </td>
                                </tr>
                            ) : viewMode === 'orders' ? (
                                currentItems.map((sale) => (
                                    <tr key={sale.id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {sale.orderNumber || `ORD${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {sale.saleDate.toLocaleString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className="text-xs mr-1">GHS</span>
                                            <span className="text-gray-900">{sale.totalAmount.toFixed(2)}</span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {sale.items.length} items
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                currentItems.map((item, index) => (
                                    <tr key={`${item.saleId}-${index}`} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {item.orderNumber || `ORD${String(parseInt(item.saleId.slice(-6), 16)).padStart(6, '0')}`}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {item.saleDate.toLocaleString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {item.itemName}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {item.quantity}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className="text-xs mr-1">GHS</span>
                                            <span className="text-gray-900">{item.price.toFixed(2)}</span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className="text-xs mr-1">GHS</span>
                                            <span className="text-gray-900">{(item.price * item.quantity).toFixed(2)}</span>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                        <div className="flex items-center justify-between">
                            <div className="text-sm text-gray-700">
                                Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of{' '}
                                {filteredData.length} results
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                    className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesList;