import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase-config';
import { signOut } from 'firebase/auth';
import { doc, setDoc, collection, addDoc, getFirestore, serverTimestamp } from 'firebase/firestore';
import { Loader2, LogOut, Store, Building2, PartyPopper, ChevronRight, Check, InfoIcon } from 'lucide-react';
import logo from '../../assets/hotKioskLogocolor.png';

function AddStore() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showCelebration, setShowCelebration] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        customType: ''
    });
    const [isCustomType, setIsCustomType] = useState(false);
    const [storeId, setStoreId] = useState(null);
    const [storeName, setStoreName] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const userId = location.state?.userId || auth.currentUser?.uid;

    const storeTypes = [
        'Provisions',
        'Electricals',
        'Tools & Equipment',
        'Clothes & Materials',
        'Fashion, Bags & Accessories',
        'General Goods',
        'Wholesale',
        'Other'
    ];

    const generateStoreCode = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let code = '';
        for (let i = 0; i < 7; i++) {
            code += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return code;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const storeCode = generateStoreCode();
            const finalStoreType = isCustomType ? formData.customType : formData.type;

            const storeRef = await addDoc(collection(db, 'Stores'), {
                name: formData.name,
                type: finalStoreType,
                createdAt: serverTimestamp(),
                ownerId: userId,
                storeCode: storeCode,
            });

            await setDoc(doc(db, 'StoreUsers', userId), {
                accessLevel: 1,
                userId: userId,
                storeId: storeRef.id,
                role: 'Owner',
                approved: 'approved',
                addedAt: serverTimestamp(),
            });

            await setDoc(doc(db, 'Users', userId), {
                accessLevel: 1,
                storeId: storeRef.id,
                approval: 'approved',
                storeCode: storeCode,
            }, { merge: true });

            setStoreId(storeRef.id);
            setStoreName(formData.name);
            setShowCelebration(true);
        } catch (error) {
            console.error('Error creating store:', error);
            setError('Failed to create store. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            setError('Failed to log out');
        }
    };

    const handleTypeChange = (e) => {
        const value = e.target.value;
        setFormData(prev => ({
            ...prev,
            type: value,
            customType: ''
        }));
        setIsCustomType(value === 'custom');
    };

    const goToStore = () => {
        navigate(`/dashboard`);
    };

    if (showCelebration) {
        return (
            <div className="min-h-screen bg-white">
                <div className="border-b">
                    <div className="max-w-[1200px] mx-auto">
                        <div className="flex justify-between items-center h-16 px-4">
                            <div className="flex items-center gap-4">
                                <img src={logo} alt="Hot Kiosk Logo" className="h-12 w-auto" />
                            </div>
                            <button
                                onClick={handleLogout}
                                className="flex items-center text-gray-500 hover:text-gray-700"
                            >
                                <LogOut className="h-5 w-5 mr-2" />
                                <span className="text-sm">Logout</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="max-w-[600px] mx-auto pt-16 px-4">
                    <div className="text-center">
                        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-orange-50 mb-6">
                            <PartyPopper className="h-8 w-8 text-orange-500" />
                        </div>

                        <h1 className="text-3xl font-bold text-gray-900 mb-4">
                            Congratulations!
                        </h1>

                        <p className="text-xl text-gray-600 mb-2">
                            {storeName} has been created successfully
                        </p>

                        <p className="text-gray-500 mb-8">
                            You're ready to start managing your business
                        </p>

                        <div className="space-y-4">
                            <button
                                onClick={goToStore}
                                className="w-full bg-orange-500 text-white py-3 px-4 rounded-lg hover:bg-orange-600 transition-colors flex items-center justify-center"
                            >
                                Go to Store Dashboard
                                <ChevronRight className="ml-2 h-5 w-5" />
                            </button>
                        </div>

                        <div className="mt-12 space-y-6">
                            <h2 className="text-lg font-semibold text-gray-900">What's next?</h2>
                            <div className="space-y-4">
                                {[
                                    'Add your inventory items',
                                    'Set up your staff accounts',
                                    'Customize your store settings',
                                    'Start making sales'
                                ].map((step, index) => (
                                    <div key={step} className="flex items-center gap-3 text-gray-600">
                                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-orange-50 flex items-center justify-center">
                                            <span className="text-sm text-orange-500 font-medium">{index + 1}</span>
                                        </div>
                                        {step}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-white">
            {/* Header */}
            <div className="border-b">
                <div className="max-w-[1200px] mx-auto">
                    <div className="flex justify-between items-center h-16 px-4">
                        <div className="flex items-center gap-4">
                            <img src={logo} alt="Hot Kiosk Logo" className="h-8 w-auto" />
                            <div className="h-4 w-px bg-gray-200" />
                            <h1 className="text-xl font-semibold text-gray-900">Create Store</h1>
                        </div>
                        <button
                            onClick={handleLogout}
                            className="flex items-center text-gray-500 hover:text-gray-700"
                        >
                            <LogOut className="h-5 w-5 mr-2" />
                            <span className="text-sm">Logout</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-[1200px] mx-auto">
                <div className="flex h-[calc(100vh-64px)]">
                    {/* Left Column - Form */}
                    <div className="w-[600px] border-r p-8">
                        <div className="max-w-md mx-auto">
                            {error && (
                                <div className="mb-6 p-4 bg-red-50 border border-red-100 text-red-700 text-sm">
                                    {error}
                                </div>
                            )}

                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Store Name
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        minLength={3}
                                        value={formData.name}
                                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                        placeholder="e.g., City Electronics"
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Store Type
                                    </label>
                                    <select
                                        required
                                        value={formData.type}
                                        onChange={handleTypeChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    >
                                        <option value="">Select a store type</option>
                                        {storeTypes.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                        <option value="custom">Enter custom type</option>
                                    </select>
                                </div>

                                {isCustomType && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Custom Store Type
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={formData.customType}
                                            onChange={(e) => setFormData(prev => ({ ...prev, customType: e.target.value }))}
                                            placeholder="Enter your store type"
                                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        />
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    disabled={isLoading || (isCustomType && !formData.customType)}
                                    className={`w-full flex justify-center items-center py-2 px-4 rounded-lg text-white ${isLoading || (isCustomType && !formData.customType)
                                        ? 'bg-orange-400 cursor-not-allowed'
                                        : 'bg-orange-500 hover:bg-orange-600'
                                        }`}
                                >
                                    {isLoading ? (
                                        <>
                                            <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                                            Creating Store...
                                        </>
                                    ) : (
                                        'Create Store'
                                    )}
                                </button>
                            </form>

                            <p className="mt-4 text-center text-sm text-gray-500">
                                By creating a store, you agree to our{' '}
                                <a href="/terms" target="_blank" className="text-orange-600 hover:text-orange-500">Terms</a>
                                {' '}and{' '}
                                <a href="/privacy" target="_blank" className="text-orange-600 hover:text-orange-500">Privacy Policy</a>
                            </p>
                        </div>
                    </div>

                    {/* Right Column - Information */}
                    <div className="w-[600px] bg-gray-50 p-8">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center gap-2 text-sm text-gray-500 mb-6">
                                <InfoIcon className="h-4 w-4" />
                                Store Information
                            </div>

                            <div className="space-y-6">
                                <div>
                                    <h3 className="text-sm font-medium text-gray-900 mb-3">What's included:</h3>
                                    <div className="space-y-3">
                                        {[
                                            'Inventory Management System',
                                            'Point of Sale (POS)',
                                            'Sales Tracking',
                                            'Financial Reports',
                                            'Staff Management',
                                            'AI-powered Insights',
                                            'Customer Database',
                                            'Mobile Access'
                                        ].map((feature) => (
                                            <div key={feature} className="flex items-center gap-2 text-sm text-gray-600">
                                                <ChevronRight className="h-4 w-4 text-orange-500" />
                                                {feature}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="border-t pt-6">
                                    <h3 className="text-sm font-medium text-gray-900 mb-3">After creation:</h3>
                                    <div className="space-y-3">
                                        <div className="text-sm text-gray-600">
                                            • You'll get a 14-day free trial
                                        </div>
                                        <div className="text-sm text-gray-600">
                                            • Full access to all premium features
                                        </div>
                                        <div className="text-sm text-gray-600">
                                            • Add unlimited products and staff
                                        </div>
                                    </div>
                                </div>

                                <div className="border-t pt-6">
                                    <h3 className="text-sm font-medium text-gray-900 mb-3">Need help?</h3>
                                    <div className="text-sm text-gray-600">
                                        Contact our support team at support@hotkiosk.com
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddStore;