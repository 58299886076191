import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, getDocs, orderBy, where, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Users, Building, FileText, ShoppingBag, ArrowRight, Loader2, UserPlus, Truck, TruckIcon } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Sidebar from '../components/sidebar';
import AddCustomerModal from '../components/customer/add_customer';
import AddSupplierModal from '../components/add_supplier';

const Clients = () => {
    const [customers, setCustomers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
    const [isAddSupplierModalOpen, setIsAddSupplierModalOpen] = useState(false);
    const [stats, setStats] = useState({
        totalCustomers: 0,
        totalCustomerRevenue: 0,
        totalSuppliers: 0,
        activeSuppliers: 0,
        pendingOrders: 0,
        totalPurchased: 0
    });

    const db = getFirestore();
    const auth = getAuth();
    const storeId = localStorage.getItem('storeId');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch Customers
                const customersRef = collection(db, 'Stores', storeId, 'Customers');
                const customersQuery = query(customersRef, orderBy('createdAt', 'desc'));
                const customersSnapshot = await getDocs(customersQuery);

                const customersData = [];
                let totalRevenue = 0;

                customersSnapshot.forEach((doc) => {
                    const customerData = { id: doc.id, ...doc.data() };
                    customersData.push(customerData);
                    totalRevenue += customerData.totalRevenue || 0;
                });

                setCustomers(customersData);

                // Fetch Suppliers
                const suppliersRef = collection(db, 'Stores', storeId, 'Suppliers');
                const suppliersQuery = query(suppliersRef, orderBy('name'));
                const suppliersSnapshot = await getDocs(suppliersQuery);

                const suppliersData = [];
                let totalPurchased = 0;

                for (const doc of suppliersSnapshot.docs) {
                    const supplierData = doc.data();
                    const statsQuery = query(
                        collection(db, 'Stores', storeId, 'SupplierStats'),
                        where('supplierId', '==', doc.id)
                    );
                    const statsDoc = await getDocs(statsQuery);

                    if (!statsDoc.empty) {
                        const stats = statsDoc.docs[0].data();
                        totalPurchased += stats.totalPurchased || 0;
                        suppliersData.push({
                            id: doc.id,
                            ...supplierData,
                            ...stats
                        });
                    } else {
                        suppliersData.push({
                            id: doc.id,
                            ...supplierData,
                            totalOrders: 0,
                            totalPurchased: 0
                        });
                    }
                }

                setSuppliers(suppliersData);

                // Fetch Pending Orders
                const ordersQuery = query(
                    collection(db, 'Stores', storeId, 'PurchaseOrders'),
                    where('status', '==', 'pending')
                );
                const ordersSnapshot = await getDocs(ordersQuery);

                // Update Stats
                setStats({
                    totalCustomers: customersData.length,
                    totalCustomerRevenue: totalRevenue,
                    totalSuppliers: suppliersData.length,
                    activeSuppliers: suppliersData.filter(s => s.status === 'active').length,
                    pendingOrders: ordersSnapshot.size,
                    totalPurchased: totalPurchased
                });

            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load client data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [db, storeId]);

    const handleCustomerAdded = () => {
        window.location.reload();
    };

    const handleSupplierAdded = () => {
        window.location.reload();
    };

    const StatCard = ({ title, value, description, icon: Icon, linkTo }) => (
        <Link to={linkTo} className="bg-white border hover:ring-2 hover:ring-orange-600 hover:cursor-pointer transition-all group">
            <div className="p-6">
                <div className="flex items-center justify-between mb-2">
                    <h3 className="text-sm text-gray-500">{title}</h3>
                    <div className="text-gray-400"><Icon className="h-6 w-6" /></div>
                </div>
                <p className="text-2xl font-bold mb-2">{value}</p>
            </div>
        </Link>
    );

    const OverviewSection = ({ title, children, linkTo }) => (
        <div className="bg-white mb-8 mt-4">
            <div className="flex justify-between items-center mb-3">
                <h2 className="text-xl font-semibold">{title}</h2>
                <Link
                    to={linkTo}
                    className="text-sm text-blue-500 hover:text-blue-600 transition-colors"
                >
                    View All
                </Link>
            </div>
            <div className="border p-6 py-3 text-sm">
                {children}
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="flex flex-col items-center gap-3">
                        <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                        <div className="text-lg">Loading client data...</div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="text-red-500 text-center">
                        <p className="text-lg">{error}</p>
                        <p className="text-sm">Please try refreshing the page</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex-1 overflow-y-auto p-8">
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-5xl font-semibold">Clients</h1>
                            <div className="flex gap-4">
                                <button
                                    onClick={() => setIsAddSupplierModalOpen(true)}
                                    className="bg-indigo-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-indigo-600 transition-colors"
                                >
                                    <TruckIcon className="h-5 w-5 mr-2" />
                                    Add Supplier
                                </button>
                                <button
                                    onClick={() => setIsAddCustomerModalOpen(true)}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                                >
                                    <UserPlus className="h-5 w-5 mr-2" />
                                    Add Customer
                                </button>
                            </div>
                        </div>

                        {/* Stats Overview */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                            <StatCard
                                title="Total Customers"
                                value={stats.totalCustomers.toLocaleString()}
                                icon={Users}
                                linkTo="customers"
                            />
                            <StatCard
                                title="Active Suppliers"
                                value={stats.activeSuppliers.toLocaleString()}
                                icon={Building}
                                linkTo="suppliers"
                            />
                            <StatCard
                                title="Pending Purchase Orders"
                                value={stats.pendingOrders.toLocaleString()}
                                icon={FileText}
                                linkTo="suppliers/purchase-orders"
                            />
                            <StatCard
                                title="Sales Quotes"
                                value={customers.reduce((total, customer) => total + (customer.totalQuotes || 0), 0).toLocaleString()}
                                icon={FileText}
                                linkTo="/sales/quotes"
                            />
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            {/* Recent Customers */}
                            <OverviewSection title="Recent Customers" linkTo="customers">
                                <div className="divide-y">
                                    {customers.slice(0, 3).map(customer => (
                                        <div key={customer.id} className="flex items-center justify-between py-4">
                                            <div>
                                                <p className="font-medium">{customer.name}</p>
                                                <p className="text-sm text-gray-500">{customer.phone}</p>
                                            </div>
                                            <div className="text-right">
                                                <p className="font-medium">GHS {customer.totalRevenue?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }) || '0.00'}</p>
                                                <p className="text-sm text-gray-500">
                                                    {customer.totalQuotes || 0} quotes
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </OverviewSection>

                            {/* Active Suppliers */}
                            <OverviewSection title="Active Suppliers" linkTo="suppliers">
                                <div className="divide-y">
                                    {suppliers
                                        .filter(supplier => supplier.status === 'active')
                                        .slice(0, 3)
                                        .map(supplier => (
                                            <div key={supplier.id} className="flex items-center justify-between py-4">
                                                <div>
                                                    <p className="font-medium">{supplier.name}</p>
                                                    <p className="text-sm text-gray-500">{supplier.category}</p>
                                                </div>
                                                <div className="text-right">
                                                    <p className="font-medium">GHS {supplier.totalPurchased?.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }) || '0.00'}</p>
                                                    <p className="text-sm text-gray-500">
                                                        {supplier.totalOrders || 0} orders
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </OverviewSection>
                        </div>
                    </div>
                </div>
            </div>

            {isAddCustomerModalOpen && (
                <AddCustomerModal
                    isOpen={isAddCustomerModalOpen}
                    onClose={() => setIsAddCustomerModalOpen(false)}
                    onSuccess={handleCustomerAdded}
                />
            )}

            {isAddSupplierModalOpen && (
                <AddSupplierModal
                    isOpen={isAddSupplierModalOpen}
                    onClose={() => setIsAddSupplierModalOpen(false)}
                    onSuccess={handleSupplierAdded}
                />
            )}
        </>
    );
};

export default Clients;