import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs, orderBy, Timestamp, doc, updateDoc, where, limit } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { DollarSign, AlertCircle, Loader2, PlusCircle, FilePlus, Folders } from 'lucide-react';
import Sidebar from '../components/sidebar';
import AddExpenseModal from '../components/add_expense';
import AddCategoryModal from '../components/add_expense_category';
import ExpenseRow from '../components/expenses_row';
import { getAuth } from 'firebase/auth';
import RecurringExpenses from '../components/expenses_recurring';
import TimePeriodPicker from '../components/time_period_picker';

const Expenses = () => {
    const [expenses, setExpenses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [recurringExpenses, setRecurringExpenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
    const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);
    const [stats, setStats] = useState({
        paidExpenses: 0,
        totalUnpaidExpenses: 0,
        numberOfCategories: 0
    });
    const [selectedTimeRange, setSelectedTimeRange] = useState({
        label: 'Last 30 Days',
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
        end: new Date()
    });

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');


    const handleDateRangeChange = (start, end, label) => {
        setSelectedTimeRange({ start, end, label });
        fetchData(start, end);
    };

    const handleLabelChange = (label) => {
        setSelectedTimeRange(prev => ({
            ...prev,
            label
        }));
    };

    const fetchData = async (start = selectedTimeRange.start, end = selectedTimeRange.end) => {
        try {
            setLoading(true);

            // Create timestamp bounds for Firestore query
            const startTimestamp = Timestamp.fromDate(start);
            const endTimestamp = Timestamp.fromDate(end);

            // Fetch expenses with date filter
            const expensesRef = collection(db, 'Stores', storeId, 'Expenses');
            const expensesQuery = query(
                expensesRef,
                where('date', '>=', startTimestamp),
                where('date', '<=', endTimestamp),
                orderBy('date', 'desc')
            );
            const expensesSnapshot = await getDocs(expensesQuery);

            const expensesData = expensesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                amount: Number(doc.data().amount) || 0,
                date: doc.data().date?.toDate?.() || null,
                paidDate: doc.data().paidDate?.toDate?.() || null,
                dueDate: doc.data().dueDate?.toDate?.() || null
            }));

            // Fetch last 4 recurring expenses without date filtering
            const recurringExpensesRef = collection(db, 'Stores', storeId, 'RecurringExpenses');
            const recurringExpensesQuery = query(
                recurringExpensesRef,
                orderBy('nextDueDate', 'desc'),
                limit(4)
            );
            const recurringExpensesSnapshot = await getDocs(recurringExpensesQuery);

            const recurringExpensesData = recurringExpensesSnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    amount: Number(doc.data().amount) || 0,
                    nextDueDate: doc.data().nextDueDate?.toDate?.() || null,
                    date: doc.data().date?.toDate?.() || null,
                    lastBilledDate: doc.data().lastBilledDate?.toDate?.() || null
                }));

            // Calculate stats for the filtered date range (regular expenses only)
            const regularPaidExpenses = expensesData
                .filter(exp => exp.paid)
                .reduce((sum, exp) => sum + (exp.amount || 0), 0);

            const regularUnpaidExpenses = expensesData
                .filter(exp => !exp.paid)
                .reduce((sum, exp) => sum + (exp.amount || 0), 0);

            // Update state with filtered data
            setExpenses(expensesData);
            setRecurringExpenses(recurringExpensesData);
            setStats({
                paidExpenses: regularPaidExpenses,
                totalUnpaidExpenses: regularUnpaidExpenses,
                numberOfCategories: categories.length
            });

        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to load data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Initial data fetch
        fetchData();

        // Fetch categories (unchanged as they don't need date filtering)
        const fetchCategories = async () => {
            const categoriesRef = collection(db, 'Stores', storeId, 'ExpenseCategories');
            const categoriesSnapshot = await getDocs(categoriesRef);
            const categoriesData = categoriesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCategories(categoriesData);
        };

        fetchCategories();
    }, [db, storeId]);

    const updateExpensePaymentStatus = async (expenseId, isPaid) => {
        const originalExpenses = [...expenses];
        const auth = getAuth();
        const expenseToUpdate = expenses.find(exp => exp.id === expenseId);

        if (!expenseToUpdate) {
            throw new Error('Expense not found');
        }

        try {
            setExpenses(currentExpenses =>
                currentExpenses.map(exp =>
                    exp.id === expenseId
                        ? {
                            ...exp,
                            paid: isPaid,
                            paidDate: isPaid ? new Date() : null
                        }
                        : exp
                )
            );

            setStats(prev => ({
                ...prev,
                paidExpenses: isPaid
                    ? prev.paidExpenses + expenseToUpdate.amount
                    : prev.paidExpenses - expenseToUpdate.amount,
                totalUnpaidExpenses: isPaid
                    ? prev.totalUnpaidExpenses - expenseToUpdate.amount
                    : prev.totalUnpaidExpenses + expenseToUpdate.amount
            }));

            const expenseRef = doc(db, 'Stores', storeId, 'Expenses', expenseId);
            await updateDoc(expenseRef, {
                paid: isPaid,
                paidDate: isPaid ? Timestamp.now() : null,
                updatedAt: Timestamp.now(),
                updatedBy: auth.currentUser?.uid || 'unknown'
            });

        } catch (error) {
            setExpenses(originalExpenses);
            throw error;
        }
    };

    if (loading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="flex flex-col items-center gap-3">
                        <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                        <div className="text-lg">Loading expense data...</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center my-10">
                        <h1 className="text-5xl font-semibold">Expenses</h1>
                        <div className="flex space-x-2">
                            <TimePeriodPicker
                                onDateRangeChange={handleDateRangeChange}
                                selectedLabel={selectedTimeRange.label}
                                onLabelChange={handleLabelChange}
                            />
                            <button
                                onClick={() => setIsAddCategoryModalOpen(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                            >
                                <FilePlus className="h-5 w-5 mr-2" />
                                Add Category
                            </button>
                            <button
                                onClick={() => setIsAddExpenseModalOpen(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                            >
                                <PlusCircle className="h-5 w-5 mr-2" />
                                Record Expense
                            </button>
                        </div>
                    </div>

                    {/* Stats Overview */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                        <Link
                            to="/expenses/list?paid=true"
                            className="bg-white border p-6 hover:ring-2 hover:ring-orange-600 hover:cursor-pointer"
                        >
                            <div className="flex items-center justify-between mb-2">
                                <h3 className="text-sm text-gray-500">Paid Expenses</h3>
                                <DollarSign className="text-emerald-500 h-6 w-6" />
                            </div>
                            <p className="text-2xl font-bold text-gray-900">
                                <span className="mr-1 text-gray-800">GHS</span>
                                {stats.paidExpenses.toFixed(2)}
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                                Including recurring expenses
                            </p>
                        </Link>

                        <Link
                            to="/expenses/list?paid=false"
                            className="bg-white border p-6 hover:ring-2 hover:ring-orange-600 hover:cursor-pointer"
                        >
                            <div className="flex items-center justify-between mb-2">
                                <h3 className="text-sm text-gray-500">Unpaid Expenses</h3>
                                <AlertCircle className="text-amber-500 h-6 w-6" />
                            </div>
                            <p className="text-2xl font-bold text-gray-900">
                                <span className="mr-1 text-gray-800">GHS</span>
                                {stats.totalUnpaidExpenses.toFixed(2)}
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                                Including recurring expenses
                            </p>
                        </Link>
                        <Link
                            to="/expenses/categories"
                            className="bg-white border p-6 hover:ring-2 hover:ring-orange-600 hover:cursor-pointer"
                        >
                            <div className="flex items-center justify-between mb-2">
                                <h3 className="text-sm text-gray-500">Categories</h3>
                                <Folders className="text-purple-500 h-6 w-6" />
                            </div>
                            <p className="text-2xl font-bold text-gray-900">
                                {stats.numberOfCategories}
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                                Total expense categories
                            </p>
                        </Link>
                    </div>

                    {/* Recent Expenses Table */}
                    <div className="mb-8">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Recent Single Expenses</h2>
                            <Link to="/expenses/list" className="text-sm text-blue-600 hover:text-blue-700">View All</Link>
                        </div>
                        <div className="border p-5">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Date</th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Description</th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Category</th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Amount</th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Status</th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Paid Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y">
                                        {expenses.slice(0, 5).map((expense) => (
                                            <ExpenseRow
                                                key={expense.id}
                                                expense={expense}
                                                onUpdatePaymentStatus={updateExpensePaymentStatus}
                                                loading={loading}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Bottom Section */}
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
                        <RecurringExpenses expenses={recurringExpenses} />
                    </div>
                </div>
            </div>

            {/* Modals */}
            <AddExpenseModal
                isOpen={isAddExpenseModalOpen}
                onClose={() => setIsAddExpenseModalOpen(false)}
                onExpenseAdded={(newExpense, isRecurring) => {
                    if (isRecurring) {
                        setRecurringExpenses(prev => [newExpense, ...prev]);
                    } else {
                        setExpenses(prev => [newExpense, ...prev]);
                        const updatedExpenses = [newExpense, ...expenses];

                        setStats(prev => ({
                            ...prev,
                            paidExpenses: newExpense.paid ?
                                prev.paidExpenses + newExpense.amount :
                                prev.paidExpenses,
                            totalUnpaidExpenses: !newExpense.paid ?
                                prev.totalUnpaidExpenses + newExpense.amount :
                                prev.totalUnpaidExpenses
                        }));
                    }
                }}
            />
            <AddCategoryModal
                isOpen={isAddCategoryModalOpen}
                onClose={() => setIsAddCategoryModalOpen(false)}
                onCategoryAdded={(newCategory) => {
                    setIsAddCategoryModalOpen(false);
                }}
            />
        </div>
    );
};

export default Expenses;