import React from 'react';

const DateInputWithQuickSelect = ({ value, onChange }) => {
    const handleSetToday = () => {
        const today = new Date().toISOString().split('T')[0];
        onChange({ target: { value: today } });
    };

    const handleSetStartOfMonth = () => {
        const date = new Date();
        const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
            .toISOString()
            .split('T')[0];
        onChange({ target: { value: startOfMonth } });
    };

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date *
            </label>
            <div className="flex gap-2 items-start">
                <input
                    type="date"
                    required
                    value={value}
                    onChange={onChange}
                    className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                />
                <button
                    type="button"
                    onClick={handleSetToday}
                    className="px-3 py-2.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Today
                </button>
                <button
                    type="button"
                    onClick={handleSetStartOfMonth}
                    className="px-3 py-2.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Start of Month
                </button>
            </div>
        </div>
    );
};

export default DateInputWithQuickSelect;