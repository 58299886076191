// app/pages/Unauthorized.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth, ACCESS_LEVELS } from './AuthContext';

function Unauthorized() {
    const navigate = useNavigate();
    const { user, accessLevel } = useAuth();

    const getRoleName = (level) => {
        switch (level) {
            case ACCESS_LEVELS.OWNER:
                return 'Store Owner';
            case ACCESS_LEVELS.ADMIN:
                return 'Administrator';
            case ACCESS_LEVELS.CASHIER:
                return 'Cashier';
            default:
                return 'Unknown Role';
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 px-6">
            <div className="max-w-md mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
                <div className="px-6 py-8">
                    {/* Warning Icon */}
                    <div className="flex justify-center mb-6">
                        <svg
                            className="w-16 h-16 text-red-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>
                    </div>

                    <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">
                        Unauthorized Access
                    </h2>

                    {/* User Details */}
                    <div className="bg-gray-50 rounded-lg p-4 mb-6">
                        <h3 className="text-lg font-semibold text-gray-700 mb-4">Your Account Details:</h3>
                        <div className="space-y-2">
                            <p className="text-gray-600">
                                <span className="font-medium">Email:</span> {user?.email}
                            </p>
                            <p className="text-gray-600">
                                <span className="font-medium">Role:</span> {getRoleName(accessLevel)}
                            </p>
                        </div>
                    </div>

                    <p className="text-gray-600 text-center mb-8">
                        You don't have sufficient permissions to access this feature.
                        Please contact your administrator if you need access.
                    </p>

                    {/* Centered Link */}
                    <div className="flex justify-center">
                        <Link to="/dashboard"
                            className="bg-orange-600 text-white rounded-lg px-8 py-2 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 transition-colors"
                        >
                            Go to Dashboard
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Unauthorized;