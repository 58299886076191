// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const ACCESS_LEVELS = {
    OWNER: 1,
    CASHIER: 2,
    ADMIN: 3
};

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accessLevel, setAccessLevel] = useState(null);
    const db = getFirestore();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const userDoc = await getDoc(doc(db, 'Users', user.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setAccessLevel(userData.accessLevel || 2);
                        setUser({
                            ...user,
                            accessLevel: userData.accessLevel,
                            storeId: userData.storeId
                        });
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setUser(user);
                    setAccessLevel(2); // Default to cashier if fetch fails
                }
            } else {
                setUser(null);
                setAccessLevel(null);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        user,
        loading,
        accessLevel,
        isOwner: accessLevel === ACCESS_LEVELS.OWNER,
        isAdmin: accessLevel === ACCESS_LEVELS.ADMIN,
        hasFullAccess: accessLevel === ACCESS_LEVELS.OWNER || accessLevel === ACCESS_LEVELS.ADMIN
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}


// import React, { createContext, useContext, useEffect, useState } from 'react';
// import { auth } from '../../firebase-config';
// import { onAuthStateChanged } from 'firebase/auth';

// const AuthContext = createContext();

// export function useAuth() {
//     return useContext(AuthContext);
// }

// export function AuthProvider({ children }) {
//     const [user, setUser] = useState(null);
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         const unsubscribe = onAuthStateChanged(auth, (user) => {
//             setUser(user);
//             setLoading(false);
//         });

//         return unsubscribe;
//     }, []);

//     const value = {
//         user,
//         loading
//     };

//     return (
//         <AuthContext.Provider value={value}>
//             {!loading && children}
//         </AuthContext.Provider>
//     );
// }