import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from '../../../firebase-config';
import { Check, CreditCard, Calendar, Clock, ArrowRight, Store } from 'lucide-react';
import { toast } from 'react-hot-toast';

const functions = getFunctions();

// Tier mapping for easy reference
const TIER_NAMES = {
    0: 'Basic',
    1: 'Pro',
    2: 'Premium'
};

// Store Avatar Component
const StoreAvatar = ({ storeInfo }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="relative">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center gap-3 px-3 py-2 rounded-lg hover:bg-gray-800 transition-colors"
            >
                <div className="w-10 h-10 rounded-full bg-gray-700 flex items-center justify-center overflow-hidden">
                    {storeInfo.imageUrl ? (
                        <img
                            src={storeInfo.imageUrl}
                            alt={storeInfo.name}
                            className="w-full h-full object-cover"
                        />
                    ) : (
                        <Store className="w-5 h-5 text-gray-400" />
                    )}
                </div>
                <div className="text-left">
                    <p className="text-sm font-medium text-white">{storeInfo.name}</p>
                    <p className="text-xs text-gray-400">{storeInfo.location}</p>
                </div>
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                        <div className="px-4 py-2 text-sm text-white">
                            <div className="font-medium border-b border-gray-700 pb-2 mb-2">Store Details</div>
                            <div className="space-y-1">
                                <p>Store ID: {storeInfo.id}</p>
                                <p>Owner: {storeInfo.owner}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

// Pricing Toggle Component
const PricingToggle = ({ isYearly, onToggle, disabled }) => (
    <div className="flex items-center justify-center gap-4 mb-8">
        <span className={`text-sm ${!isYearly ? 'text-white' : 'text-gray-400'}`}>Monthly</span>
        <button
            onClick={onToggle}
            disabled={disabled}
            className={`relative inline-flex h-6 w-11 items-center rounded-full bg-gray-700 ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                }`}
        >
            <span className="sr-only">Toggle billing frequency</span>
            <span
                className={`${isYearly ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
        </button>
        <div className="flex items-center gap-2">
            <span className={`text-sm ${isYearly ? 'text-white' : 'text-gray-400'}`}>Annual</span>
            <span className="bg-green-500 text-white text-xs px-2 py-1 rounded">Save up to 20%</span>
        </div>
    </div>
);

// Pricing Card Skeleton Component
const PricingCardSkeleton = () => (
    <div className="bg-gray-800 border-2 border-gray-700 rounded-lg p-6 flex flex-col h-full animate-pulse">
        <div className="h-8 bg-gray-700 rounded-md mb-2 w-1/2"></div>
        <div className="h-12 bg-gray-700 rounded-md mb-6"></div>
        <div className="space-y-4 mb-8 flex-grow">
            {[1, 2, 3, 4].map((i) => (
                <div key={i} className="flex items-center">
                    <div className="w-5 h-5 bg-gray-700 rounded-full mr-3"></div>
                    <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                </div>
            ))}
        </div>
        <div className="h-12 bg-gray-700 rounded-lg"></div>
    </div>
);

// Pricing Card Component
const PricingCard = ({ name, price, features, tier, planId, onSubscribe, isLoading, interval }) => {
    const isPopular = tier === 1;

    return (
        <div
            className={`${isPopular ? 'bg-gray-800 border-[#FF6B35]' : 'bg-gray-800 border-gray-700'
                } border-2 rounded-lg p-6 flex flex-col h-full relative transition-opacity duration-200 ${isLoading ? 'opacity-50' : 'opacity-100'
                }`}
        >
            {isPopular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <span className="bg-[#FF6B35] text-white px-4 py-1 rounded-full text-sm font-medium">
                        Most Popular
                    </span>
                </div>
            )}
            <h3 className="text-xl font-bold mb-2 text-white">{name}</h3>
            <div className="mb-6">
                <span className="text-4xl font-bold text-white">GHS {price}</span>
                <span className="text-gray-400">/{interval}</span>
            </div>
            <ul className="space-y-4 mb-8 flex-grow">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-300">
                        <Check className="w-5 h-5 text-[#FF6B35] mr-3 flex-shrink-0" />
                        <span>{feature}</span>
                    </li>
                ))}
            </ul>
            <button
                onClick={() => onSubscribe(planId)}
                disabled={isLoading}
                className={`w-full py-3 px-4 rounded-lg font-medium transition-colors ${isPopular
                    ? 'bg-[#FF6B35] text-white hover:bg-[#ff8255]'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                    } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                {isLoading ? 'Processing...' : 'Get Started'}
            </button>
        </div>
    );
};

const PricingFAQ = () => (
    <div className="mt-16 max-w-3xl mx-auto">
        <h3 className="text-2xl font-bold text-center mb-8">Frequently Asked Questions</h3>
        <div className="space-y-6">
            <div className="bg-gray-800 rounded-lg p-6">
                <h4 className="text-lg font-semibold mb-2">What's included in the free trial?</h4>
                <p className="text-gray-400">You get full access to all features of your chosen plan for 14 days. No credit card required to start.</p>
            </div>
            <div className="bg-gray-800 rounded-lg p-6">
                <h4 className="text-lg font-semibold mb-2">Can I change plans later?</h4>
                <p className="text-gray-400">Yes, you can upgrade or downgrade your plan at any time. Changes take effect at the start of your next billing cycle.</p>
            </div>
            <div className="bg-gray-800 rounded-lg p-6">
                <h4 className="text-lg font-semibold mb-2">How does annual billing work?</h4>
                <p className="text-gray-400">Annual billing gives you a 20% discount compared to monthly billing. You'll be billed once per year.</p>
            </div>
        </div>
    </div>
);

// Subscription Details Component
const SubscriptionDetails = ({ subscription, onAction }) => {
    const getStatusColor = (status) => {
        switch (status?.toLowerCase()) {
            case 'active': return 'text-green-500';
            case 'trialing': return 'text-blue-500';
            case 'past_due': return 'text-yellow-500';
            case 'canceled': return 'text-red-500';
            default: return 'text-gray-500';
        }
    };

    return (
        <div className="grid gap-8 md:grid-cols-2">
            <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
                <h3 className="text-xl font-bold mb-6 text-white">Current Plan</h3>
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <span className="text-gray-400">Plan</span>
                        <span className="text-white font-medium">{TIER_NAMES[subscription.tier]}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-400">Billing</span>
                        <span className="text-white font-medium">
                            GHS {subscription.price}/{subscription.interval}
                        </span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-400">Status</span>
                        <span className={`font-medium ${getStatusColor(subscription.status)}`}>
                            {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                        </span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-400">Next billing</span>
                        <span className="text-white font-medium">{subscription.nextBillingDate}</span>
                    </div>
                </div>
            </div>

            <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
                <h3 className="text-xl font-bold mb-6 text-white">Quick Actions</h3>
                <div className="space-y-4">
                    <button
                        onClick={() => onAction('updatePayment')}
                        className="w-full flex items-center justify-between p-4 rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors"
                    >
                        <div className="flex items-center gap-3">
                            <CreditCard className="w-5 h-5 text-[#FF6B35]" />
                            <span className="text-white">Update payment method</span>
                        </div>
                        <ArrowRight className="w-4 h-4 text-gray-400" />
                    </button>

                    <button
                        onClick={() => onAction('viewHistory')}
                        className="w-full flex items-center justify-between p-4 rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors"
                    >
                        <div className="flex items-center gap-3">
                            <Calendar className="w-5 h-5 text-[#FF6B35]" />
                            <span className="text-white">View billing history</span>
                        </div>
                        <ArrowRight className="w-4 h-4 text-gray-400" />
                    </button>

                    <button
                        onClick={() => onAction('changeBilling')}
                        className="w-full flex items-center justify-between p-4 rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors"
                    >
                        <div className="flex items-center gap-3">
                            <Clock className="w-5 h-5 text-[#FF6B35]" />
                            <span className="text-white">Change billing cycle</span>
                        </div>
                        <ArrowRight className="w-4 h-4 text-gray-400" />
                    </button>
                </div>
            </div>
        </div>
    );
};

// Main Subscription Component
const Subscription = () => {
    const [isYearly, setIsYearly] = useState(false);
    const [plansLoading, setPlansLoading] = useState(false);
    const [toggleLoading, setToggleLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentStore, setCurrentStore] = useState(null);
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setCurrentUser(user);
                try {
                    const userDoc = await getDoc(doc(db, 'Users', user.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        if (userData.storeId) {
                            const storeDoc = await getDoc(doc(db, 'Stores', userData.storeId));
                            if (storeDoc.exists()) {
                                setCurrentStore({
                                    id: storeDoc.id,
                                    ...storeDoc.data()
                                });
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    toast.error('Failed to load user data');
                }
            } else {
                setCurrentUser(null);
                setCurrentStore(null);
            }
            setAuthChecked(true);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (currentStore?.id) {
            handleToggle();
        }
    }, [currentStore, isYearly]);

    const handleToggle = async () => {
        if (!currentStore?.id) return;

        setToggleLoading(true);
        try {
            const [plansData, subscriptionResponse] = await Promise.all([
                fetchPlans(),
                fetchCurrentSubscription()
            ]);

            setPlans(plansData);
            if (subscriptionResponse.hasSubscription) {
                setSubscription(subscriptionResponse.data);
                setHasSubscription(true);
            }
        } catch (error) {
            console.error('Error fetching subscription data:', error);
            toast.error('Failed to load subscription information');
        } finally {
            setToggleLoading(false);
        }
    };

    const fetchPlans = async () => {
        try {
            const plansCollectionRef = collection(db, 'Plans');
            const plansQuery = query(
                plansCollectionRef,
                where('interval', '==', isYearly ? 'annually' : 'monthly'),
                where('status', '==', true)
            );

            const plansSnapshot = await getDocs(plansQuery);

            const plansData = plansSnapshot.docs
                .map(doc => {
                    const plan = doc.data();
                    return {
                        name: TIER_NAMES[plan.tier],
                        price: plan.amount,
                        features: plan.features || [],
                        tier: plan.tier,
                        planId: plan.planId,
                        interval: plan.interval,
                        description: plan.description,
                        currency: plan.currency,
                        metadata: plan.metadata
                    };
                })
                .sort((a, b) => a.tier - b.tier);

            return plansData;
        } catch (error) {
            console.error('Error fetching plans:', error);
            toast.error('Failed to load pricing plans');
            return [];
        }
    };

    const fetchCurrentSubscription = async () => {
        if (!currentStore?.id) return { hasSubscription: false };

        try {
            const getSubscriptionFunction = httpsCallable(functions, 'getSubscriptionDetails');
            const response = await getSubscriptionFunction({ storeId: currentStore.id });
            return response.data;
        } catch (error) {
            console.error('Error fetching subscription:', error);
            return { hasSubscription: false };
        }
    };

    const handleSubscribe = async (planId) => {
        if (!currentStore?.id) {
            toast.error('Store information not found');
            return;
        }

        try {
            setPlansLoading(true);
            const initializeSubscriptionFunction = httpsCallable(functions, 'initializeSubscription');

            const result = await initializeSubscriptionFunction({
                planId,
                storeId: currentStore.id,
                interval: isYearly ? 'annually' : 'monthly'
            });

            if (result.data.status) {
                toast.success('Subscription initialized successfully');
                await handleToggle(); // Refresh subscription data
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            toast.error(error.message || 'Failed to initialize subscription');
        } finally {
            setPlansLoading(false);
        }
    };

    if (!authChecked) {
        return (
            <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white mx-auto"></div>
                    <p className="mt-4">Loading subscription details...</p>
                </div>
            </div>
        );
    }

    if (!currentUser) {
        return (
            <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">Please Log In</h2>
                    <p className="text-gray-400">You need to be logged in to view subscription details.</p>
                </div>
            </div>
        );
    }

    if (!currentStore) {
        return (
            <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">No Store Found</h2>
                    <p className="text-gray-400">Please create or select a store to manage subscriptions.</p>
                </div>
            </div>
        );
    }

    // Add loading state for subscription data
    if (hasSubscription && !subscription) {
        return (
            <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white mx-auto"></div>
                    <p className="mt-4">Loading subscription details...</p>
                </div>
            </div>
        );
    }

    if (hasSubscription && subscription) {
        return (
            <div className="min-h-screen bg-gray-900 text-white">
                <header className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg fixed w-full z-10">
                    <div className="container mx-auto flex justify-between items-center py-4 px-4">
                        <h1 className="text-2xl font-bold">Subscription Management</h1>
                        <StoreAvatar storeInfo={currentStore} />
                    </div>
                </header>

                <main className="container mx-auto pt-24 pb-12 px-4">
                    <SubscriptionDetails
                        subscription={subscription}
                        onAction={async (action) => {
                            switch (action) {
                                case 'updatePayment':
                                    toast.info('Payment update feature coming soon');
                                    break;
                                case 'viewHistory':
                                    toast.info('Billing history feature coming soon');
                                    break;
                                case 'changeBilling':
                                    toast.info('Billing cycle change feature coming soon');
                                    break;
                                default:
                                    console.log('Unknown action:', action);
                            }
                        }}
                    />

                    {/* Plan Features Summary */}
                    <div className="bg-gray-800 rounded-lg p-6 border border-gray-700 mt-8">
                        <div className="flex items-start justify-between mb-6">
                            <div>
                                <h2 className="text-2xl font-bold text-white mb-2">
                                    {TIER_NAMES[subscription.tier] || 'Loading...'}
                                </h2>
                                <p className="text-gray-400">Your current activated features</p>
                            </div>
                            <button
                                onClick={() => setHasSubscription(false)}
                                className="px-4 py-2 bg-[#FF6B35] hover:bg-[#ff8255] rounded-lg transition-colors"
                            >
                                Change Plan
                            </button>
                        </div>
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                            {subscription.features?.map((feature, index) => (
                                <div key={index} className="flex items-center text-gray-300">
                                    <Check className="w-5 h-5 text-[#FF6B35] mr-3 flex-shrink-0" />
                                    <span>{feature}</span>
                                </div>
                            )) || (
                                    <div className="text-gray-400">No features available</div>
                                )}
                        </div>
                    </div>
                </main>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            <header className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg fixed w-full z-10">
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    <h1 className="text-2xl font-bold">Choose Your Plan</h1>
                    <StoreAvatar storeInfo={currentStore} />
                </div>
            </header>

            <main className="container mx-auto pt-32 pb-12 px-4">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold mb-4">Simple, Transparent Pricing</h2>
                    <p className="text-gray-400 max-w-2xl mx-auto">
                        Start with a 14-day free trial. No credit card required.
                    </p>
                </div>

                <PricingToggle
                    isYearly={isYearly}
                    onToggle={() => setIsYearly(!isYearly)}
                    disabled={toggleLoading}
                />

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                    {toggleLoading ? (
                        <>
                            <PricingCardSkeleton />
                            <PricingCardSkeleton />
                            <PricingCardSkeleton />
                        </>
                    ) : (
                        plans.map((plan) => (
                            <PricingCard
                                key={plan.planId}
                                {...plan}
                                onSubscribe={handleSubscribe}
                                isLoading={plansLoading}
                            />
                        ))
                    )}
                </div>

                {/* FAQ Section only shows on pricing page */}
                <PricingFAQ />
            </main>

            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto text-center text-gray-400">
                    <p>&copy; 2024 HotKiosk. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Subscription;