import React, { useState } from 'react';
import { X, Calculator, Plus, Minus, Package, Ship } from 'lucide-react';

export const CostCalculatorModal = ({ isOpen, onClose, onApplyCost }) => {
    const [activeTab, setActiveTab] = useState('bulk');

    // Bulk pricing state
    const [packPrice, setPackPrice] = useState('');
    const [itemsInPack, setItemsInPack] = useState('');

    // Shipped pricing state
    const [basePrice, setBasePrice] = useState('');
    const [itemsInShipment, setItemsInShipment] = useState('');
    const [additionalCosts, setAdditionalCosts] = useState([
        { id: 1, name: '', amount: '' }
    ]);
    const [shippingCost, setShippingCost] = useState('');
    const [overhead, setOverhead] = useState('');

    // Reset form when changing tabs
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        if (tab === 'bulk') {
            setPackPrice('');
            setItemsInPack('');
        } else {
            setBasePrice('');
            setItemsInShipment('');
            setShippingCost('');
            setOverhead('');
            setAdditionalCosts([{ id: 1, name: '', amount: '' }]);
        }
    };

    const addCostField = () => {
        const newId = additionalCosts.length > 0
            ? Math.max(...additionalCosts.map(cost => cost.id)) + 1
            : 1;
        setAdditionalCosts([...additionalCosts, { id: newId, name: '', amount: '' }]);
    };

    const removeCostField = (id) => {
        setAdditionalCosts(additionalCosts.filter(cost => cost.id !== id));
    };

    const updateCost = (id, field, value) => {
        setAdditionalCosts(additionalCosts.map(cost =>
            cost.id === id ? { ...cost, [field]: value } : cost
        ));
    };

    const calculateBulkCost = () => {
        const totalPackPrice = parseFloat(packPrice) || 0;
        const totalItems = parseInt(itemsInPack) || 1;
        return Number((totalPackPrice / totalItems).toFixed(2));
    };

    const calculateShippedCost = () => {
        const items = parseInt(itemsInShipment) || 1;
        const base = parseFloat(basePrice) || 0;
        const shipping = (parseFloat(shippingCost) || 0) / items;
        const overheadCost = (parseFloat(overhead) || 0) / items;
        const additional = additionalCosts.reduce((sum, cost) => {
            return sum + ((parseFloat(cost.amount) || 0) / items);
        }, 0);

        return Number((base + shipping + overheadCost + additional).toFixed(2));
    };

    const handleCalculate = () => {
        const totalCost = activeTab === 'bulk' ? calculateBulkCost() : calculateShippedCost();
        // Ensure final cost is rounded to 2 decimal places when passing to parent
        onApplyCost(Number(totalCost.toFixed(2)));
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />

            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-auto">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h2 className="text-lg font-semibold flex items-center gap-2">
                            <Calculator className="h-5 w-5" />
                            Cost Price Calculator
                        </h2>
                        <button
                            onClick={onClose}
                            className="p-1 hover:bg-gray-100 rounded-full"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    </div>

                    <div className="border-b">
                        <div className="flex">
                            <button
                                className={`flex-1 py-3 px-4 text-sm font-medium focus:outline-none ${activeTab === 'bulk'
                                    ? 'border-b-2 border-blue-500 text-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabChange('bulk')}
                            >
                                <Package className="h-4 w-4 inline-block mr-2" />
                                Bulk/Local
                            </button>
                            <button
                                className={`flex-1 py-3 px-4 text-sm font-medium focus:outline-none ${activeTab === 'shipped'
                                    ? 'border-b-2 border-blue-500 text-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabChange('shipped')}
                            >
                                <Ship className="h-4 w-4 inline-block mr-2" />
                                Imported
                                <span className="ml-2 text-xs px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full">
                                    Experimental
                                </span>
                            </button>
                        </div>
                    </div>

                    {activeTab === 'shipped' && (
                        <div className="px-6 pt-4">
                            <div className="p-3 bg-yellow-50 border border-yellow-200 rounded-lg">
                                <p className="text-sm text-yellow-800">
                                    This is an experimental calculator. Cost estimates may not be accurate as shipping costs can vary based on multiple factors.
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="p-6 space-y-4">
                        {activeTab === 'bulk' ? (
                            <>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Pack Price (GHS)
                                    </label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={packPrice}
                                        onChange={(e) => setPackPrice(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="0.00"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Items in Pack
                                    </label>
                                    <input
                                        type="number"
                                        value={itemsInPack}
                                        onChange={(e) => setItemsInPack(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="1"
                                        min="1"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Base Price Per Item (GHS)
                                        </label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            value={basePrice}
                                            onChange={(e) => setBasePrice(e.target.value)}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="0.00"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Total Items in Shipment
                                        </label>
                                        <input
                                            type="number"
                                            min="1"
                                            value={itemsInShipment}
                                            onChange={(e) => setItemsInShipment(e.target.value)}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="1"
                                        />
                                    </div>
                                </div>

                                {/* <div className="p-4 bg-gray-50 rounded-lg text-sm">
                                    <p className="text-xs text-gray-600 mb-2">
                                        All costs below will be divided by the total number of items in the shipment
                                    </p>
                                </div> */}

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Total Shipping Cost (GHS)
                                    </label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={shippingCost}
                                        onChange={(e) => setShippingCost(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="0.00"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Total Overhead Cost (GHS)
                                    </label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={overhead}
                                        onChange={(e) => setOverhead(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="0.00"
                                    />
                                </div>

                                <div>
                                    <div className="flex items-center justify-between mb-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Additional Shared Costs
                                        </label>
                                        <button
                                            type="button"
                                            onClick={addCostField}
                                            className="p-1 hover:bg-gray-100 rounded-full text-blue-600"
                                        >
                                            <Plus className="h-5 w-5" />
                                        </button>
                                    </div>
                                    <div className="space-y-2">
                                        {additionalCosts.map((cost) => (
                                            <div key={cost.id} className="flex gap-2 items-start">
                                                <input
                                                    type="text"
                                                    value={cost.name}
                                                    onChange={(e) => updateCost(cost.id, 'name', e.target.value)}
                                                    placeholder="Cost name"
                                                    className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                />
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    value={cost.amount}
                                                    onChange={(e) => updateCost(cost.id, 'amount', e.target.value)}
                                                    placeholder="0.00"
                                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                />
                                                {additionalCosts.length > 1 && (
                                                    <button
                                                        type="button"
                                                        onClick={() => removeCostField(cost.id)}
                                                        className="p-2 hover:bg-gray-100 rounded-full text-red-500"
                                                    >
                                                        <Minus className="h-5 w-5" />
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="pt-4 border-t">
                            <div className="flex justify-between items-center text-lg font-semibold">
                                <span>Cost Per Item:</span>
                                <span>GHS {(activeTab === 'bulk' ? calculateBulkCost() : calculateShippedCost()).toFixed(2)}</span>
                            </div>
                            {activeTab === 'shipped' && itemsInShipment && (
                                <div className="mt-2 text-sm text-gray-600">
                                    Costs are distributed across {itemsInShipment} item{parseInt(itemsInShipment) !== 1 ? 's' : ''}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end gap-3 p-4 border-t bg-gray-50">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 border rounded-lg text-gray-700 hover:bg-gray-100"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleCalculate}
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        >
                            Apply Cost
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CostCalculatorModal;