import React, { useState } from 'react';
import {
    X,
    Pencil,
    Sparkles,
    Loader2
} from 'lucide-react';
import {
    getFirestore,
    collection,
    addDoc
} from 'firebase/firestore';
import {
    getFunctions,
    httpsCallable
} from 'firebase/functions';

const CustomCheckbox = ({ checked, onChange, label }) => (
    <div
        className="flex items-center space-x-3 p-3 rounded-lg transition-colors hover:bg-gray-100 cursor-pointer"
        onClick={onChange}
    >
        <div className="relative flex items-center">
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className="peer sr-only"
            />
            <div className={`
                w-5 h-5 border-2 rounded 
                transition-colors duration-200 
                flex items-center justify-center
                ${checked
                    ? 'bg-orange-500 border-orange-500'
                    : 'border-gray-300 bg-white'
                }
            `}>
                {checked && (
                    <svg
                        className="w-3 h-3 text-white"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2 6L5 9L10 3"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </div>
        </div>
        <span className="text-sm font-medium select-none">{label}</span>
    </div>
);

const AddInventoryCategoryModal = ({ isOpen, onClose, onSave, storeId, inventoryItems, existingCategories }) => {
    const [activeTab, setActiveTab] = useState('manual');
    const [categoryName, setCategoryName] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [suggestedCategories, setSuggestedCategories] = useState([]);
    const [selectedSuggestions, setSelectedSuggestions] = useState(new Set());

    const db = getFirestore();
    const functions = getFunctions();

    const handleManualSubmit = async (e) => {
        e.preventDefault();
        if (!categoryName.trim() || !storeId) return;

        setIsSaving(true);
        try {
            const newCategory = {
                name: categoryName.trim(),
                createdAt: new Date(),
                updatedAt: new Date()
            };

            const docRef = await addDoc(
                collection(db, 'Stores', storeId, 'InventoryCategories'),
                newCategory
            );

            onSave(docRef.id, categoryName.trim());
            setCategoryName('');
            onClose();
        } catch (error) {
            console.error('Error adding category:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const analyzeCategoriesByAI = async () => {
        setIsAnalyzing(true);
        try {
            const itemNames = inventoryItems?.map(item => item.itemName) || [];
            const existingCategoryNames = existingCategories?.map(cat => cat.name) || [];

            const getAICategories = httpsCallable(functions, 'getAICategories');
            const result = await getAICategories({
                items: itemNames,
                existingCategories: existingCategoryNames
            });

            setSuggestedCategories(result.data || []);
        } catch (error) {
            console.error('Error analyzing categories:', error);
            alert('Failed to analyze categories. Please try again.');
        } finally {
            setIsAnalyzing(false);
        }
    };

    const handleSuggestionSelect = (category) => {
        setSelectedSuggestions(prev => {
            const newSet = new Set(prev);
            if (newSet.has(category)) {
                newSet.delete(category);
            } else {
                newSet.add(category);
            }
            return newSet;
        });
    };

    const handleSaveSuggested = async () => {
        setIsSaving(true);
        try {
            const savedCategories = [];
            for (const categoryName of selectedSuggestions) {
                const newCategory = {
                    name: categoryName,
                    createdAt: new Date(),
                    updatedAt: new Date()
                };

                const docRef = await addDoc(
                    collection(db, 'Stores', storeId, 'InventoryCategories'),
                    newCategory
                );
                savedCategories.push({ id: docRef.id, name: categoryName });
            }

            savedCategories.forEach(cat => onSave(cat.id, cat.name));
            onClose();
        } catch (error) {
            console.error('Error saving suggested categories:', error);
        } finally {
            setIsSaving(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                >
                    <X className="h-5 w-5" />
                </button>

                <h2 className="text-xl font-semibold mb-4">Add Categories</h2>

                <div className="flex mb-6 border-b">
                    <button
                        onClick={() => setActiveTab('manual')}
                        className={`flex items-center justify-center px-4 py-2 flex-1 ${activeTab === 'manual'
                            ? 'border-b-2 border-orange-500 text-orange-500'
                            : 'text-gray-500'}`}
                    >
                        <Pencil className="h-4 w-4 mr-2" />
                        Manual
                    </button>
                    <button
                        onClick={() => setActiveTab('ai')}
                        className={`flex items-center justify-center px-4 py-2 flex-1 ${activeTab === 'ai'
                            ? 'border-b-2 border-orange-500 text-orange-500'
                            : 'text-gray-500'}`}
                    >
                        <Sparkles className="h-4 w-4 mr-2" />
                        AI Assisted
                    </button>
                </div>

                {activeTab === 'manual' ? (
                    <form onSubmit={handleManualSubmit}>
                        <input
                            type="text"
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            placeholder="Category Name"
                            className="w-full p-2 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
                            required
                        />
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                                disabled={isSaving}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-orange-500 text-white hover:bg-orange-600 rounded-lg flex items-center disabled:bg-orange-300"
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                        Saving...
                                    </>
                                ) : (
                                    'Add Category'
                                )}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div>
                        {suggestedCategories.length === 0 ? (
                            <div className="text-center">
                                <p className="text-gray-600 mb-4">
                                    HotKiosk AI will analyze your inventory items and suggest relevant categories.
                                </p>
                                <button
                                    onClick={analyzeCategoriesByAI}
                                    className="px-4 py-2 bg-orange-500 text-white hover:bg-orange-600 rounded-lg flex items-center mx-auto disabled:bg-orange-300"
                                    disabled={isAnalyzing}
                                >
                                    {isAnalyzing ? (
                                        <>
                                            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                            Analyzing...
                                        </>
                                    ) : (
                                        'Analyze Inventory'
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div>
                                <p className="text-sm text-gray-600 mb-4">
                                    Select categories to add:
                                </p>
                                <div className="max-h-60 overflow-y-auto mb-4 space-y-1">
                                    {suggestedCategories.map((category) => (
                                        <CustomCheckbox
                                            key={category}
                                            checked={selectedSuggestions.has(category)}
                                            onChange={() => handleSuggestionSelect(category)}
                                            label={category}
                                        />
                                    ))}
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        onClick={onClose}
                                        className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                                        disabled={isSaving}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSaveSuggested}
                                        className="px-4 py-2 bg-orange-500 text-white hover:bg-orange-600 rounded-lg flex items-center disabled:bg-orange-300"
                                        disabled={isSaving || selectedSuggestions.size === 0}
                                    >
                                        {isSaving ? (
                                            <>
                                                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                                Saving...
                                            </>
                                        ) : (
                                            `Add Selected (${selectedSuggestions.size})`
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddInventoryCategoryModal;