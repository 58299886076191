import React, { useState } from 'react';
import { ArrowLeft, Send } from 'lucide-react';
import { getAuth, signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { getFirestore, addDoc, collection } from 'firebase/firestore';

const DeleteAccount = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [reason, setReason] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const auth = getAuth();
            const db = getFirestore();

            // First, reauthenticate the user
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Add deletion record to Firestore
            await addDoc(collection(db, 'Deletions'), {
                email: email,
                reason: reason || 'No reason provided',
                timestamp: new Date(),
                uid: user.uid
            });

            // Delete the user
            await deleteUser(user);

            console.log('User deleted successfully');
            console.log('Deletion reason:', reason);
            setSubmitted(true);
        } catch (error) {
            console.error('Error deleting user:', error);
            setError(error.message);
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            {/* Header */}
            <header className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg fixed w-full z-10">
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    <a href="/" className="flex items-center text-red-400 hover:text-red-300 transition duration-300">
                        <ArrowLeft className="w-5 h-5 mr-2" />
                        Back to Home
                    </a>
                    <h1 className="text-2xl font-bold">HotKiosk Data Deletion Request</h1>
                </div>
            </header>

            {/* Main Content */}
            <main className="container mx-auto pt-24 pb-12 px-4">
                <section className="max-w-3xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8">
                    <h2 className="text-3xl font-bold mb-6">Request Data Deletion</h2>

                    {!submitted ? (
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {error && (
                                <div className="p-4 bg-red-500 bg-opacity-20 border border-red-500 rounded-md text-red-400">
                                    {error}
                                </div>
                            )}
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium mb-2">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                                    placeholder="Enter your email"
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium mb-2">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                                    placeholder="Enter your password"
                                />
                            </div>
                            <div>
                                <label htmlFor="reason" className="block text-sm font-medium mb-2">Reason for Deletion (Optional)</label>
                                <textarea
                                    id="reason"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                                    rows="4"
                                    placeholder="Please provide a reason for your data deletion request (optional)"
                                ></textarea>
                            </div>
                            <div>
                                <button type="submit" className="flex items-center justify-center w-full px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 transition duration-300">
                                    <Send className="w-5 h-5 mr-2" />
                                    Submit Deletion Request
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className="text-center">
                            <h3 className="text-2xl font-semibold mb-4 text-green-400">Account Deleted Successfully</h3>
                            <p>Your account has been permanently deleted from our system. Thank you for using HotKiosk.</p>
                        </div>
                    )}

                    <div className="mt-8 p-4 bg-gray-700 rounded-md">
                        <h3 className="text-xl font-semibold mb-3 text-red-400">Important Information</h3>
                        <ul className="list-disc list-inside space-y-2">
                            <li>This action cannot be undone.</li>
                            <li>You will need to create a new account if you wish to use HotKiosk services again.</li>
                            <li>Any associated data will be permanently removed from our systems.</li>
                            <li>Related data may take up to 30 days to be deleted.</li>
                        </ul>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto text-center text-gray-400">
                    <p>&copy; 2024 HotKiosk. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default DeleteAccount;