import React, { useState, useEffect, useRef } from 'react';
import { X, ImageOff, BarcodeIcon, Calculator } from 'lucide-react';
import { collection, query, where, getDocs, getFirestore } from 'firebase/firestore';
import CostCalculatorModal from './cost_calculator';
import ImageUploadDropzone from './image_upload_dropzone';

export const ProductAddModal = ({ isOpen, onClose, onSave, storeId }) => {
    const [formData, setFormData] = useState({
        itemName: '',
        sku: '',
        quantity: 0,
        reorderPoint: 0,
        sellingPrice: 0,
        costPrice: 0,
        imageUrl: ''
    });

    const [isScanning, setIsScanning] = useState(false);
    const [scanBuffer, setScanBuffer] = useState('');
    const [skuError, setSkuError] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [isCostCalculatorOpen, setIsCostCalculatorOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const scanTimeoutRef = useRef(null);
    const skuInputRef = useRef(null);
    const db = getFirestore();

    // Calculate reorder point whenever quantity changes
    useEffect(() => {
        const quantity = parseFloat(formData.quantity) || 0;
        const reorderPoint = Math.ceil(quantity * 0.2); // 20% of quantity, rounded up
        setFormData(prev => ({
            ...prev,
            reorderPoint
        }));
    }, [formData.quantity]);

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') onClose();
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscape);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (!isScanning) return;

            e.preventDefault();

            if (scanTimeoutRef.current) {
                clearTimeout(scanTimeoutRef.current);
            }

            setScanBuffer(prev => prev + e.key);

            scanTimeoutRef.current = setTimeout(() => {
                if (scanBuffer) {
                    setFormData(prev => ({
                        ...prev,
                        sku: scanBuffer
                    }));
                    setScanBuffer('');
                    validateSku(scanBuffer);
                }
            }, 100);
        };

        if (isScanning) {
            window.addEventListener('keypress', handleKeyPress);
            skuInputRef.current?.focus();
        }

        return () => {
            window.removeEventListener('keypress', handleKeyPress);
            if (scanTimeoutRef.current) {
                clearTimeout(scanTimeoutRef.current);
            }
        };
    }, [isScanning, scanBuffer]);

    const validateSku = async (skuValue) => {
        if (!skuValue) {
            setSkuError('');
            return true;
        }

        setIsValidating(true);
        try {
            const inventoryRef = collection(db, 'Stores', storeId, 'Inventory');
            const q = query(inventoryRef, where('sku', '==', skuValue));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                setSkuError('This SKU already exists');
                return false;
            }

            setSkuError('');
            return true;
        } catch (error) {
            console.error('Error validating SKU:', error);
            setSkuError('Error validating SKU');
            return false;
        } finally {
            setIsValidating(false);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: ['quantity', 'sellingPrice', 'costPrice'].includes(name)
                ? Number(parseFloat(value || 0).toFixed(2))
                : value
        }));

        if (name === 'sku') {
            await validateSku(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.sku) {
            const isValid = await validateSku(formData.sku);
            if (!isValid) return;
        }

        if (!formData.itemName.trim()) {
            return;
        }

        const newItem = {
            ...formData,
            sku: formData.sku.trim(),
            createdAt: new Date(),
            updatedAt: new Date()
        };

        onSave(newItem);
        onClose();

        setFormData({
            itemName: '',
            sku: '',
            quantity: 0,
            reorderPoint: 0,
            sellingPrice: 0,
            costPrice: 0,
            imageUrl: ''
        });
    };

    const toggleScanner = () => {
        setIsScanning(!isScanning);
        if (!isScanning) {
            skuInputRef.current?.focus();
        }
    };

    const handleApplyCost = (calculatedCost) => {
        setFormData(prev => ({
            ...prev,
            costPrice: calculatedCost
        }));
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />

            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-lg shadow-xl w-full max-w-4xl mx-auto">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h2 className="text-lg font-semibold">Add New Product</h2>
                        <button
                            onClick={onClose}
                            className="p-1 hover:bg-gray-100 rounded-full"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    </div>

                    <div className="flex">
                        <div className="w-2/5 p-6 bg-gray-50 border-r">
                            <div className="flex flex-col items-center justify-center h-full">
                                <ImageUploadDropzone
                                    onImageUploaded={setImageUrl}
                                    currentImageUrl={imageUrl}
                                    storeId="your-store-id"
                                    className="w-full max-w-md"
                                />
                            </div>
                        </div>

                        <form onSubmit={handleSubmit} className="flex-2 w-full p-6">
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Product Name *
                                    </label>
                                    <input
                                        required
                                        name="itemName"
                                        value={formData.itemName}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        SKU (Optional)
                                    </label>
                                    <div className="flex gap-2">
                                        <input
                                            ref={skuInputRef}
                                            name="sku"
                                            value={formData.sku}
                                            onChange={handleChange}
                                            className={`flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${skuError ? 'border-red-500' : ''
                                                }`}
                                            readOnly={isScanning}
                                        />
                                        <button
                                            type="button"
                                            onClick={toggleScanner}
                                            className={`px-3 py-2 border rounded-lg flex items-center justify-center transition-colors ${isScanning
                                                ? 'bg-blue-100 border-blue-300 text-blue-600 hover:bg-blue-200'
                                                : 'bg-gray-100 hover:bg-gray-200 text-gray-600'
                                                }`}
                                            title={isScanning ? 'Scanner Active' : 'Enable Scanner'}
                                        >
                                            <BarcodeIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                    {skuError && (
                                        <p className="mt-1 text-sm text-red-600">{skuError}</p>
                                    )}
                                    {isScanning && (
                                        <p className="mt-1 text-sm text-blue-600">
                                            Scanner active. Waiting for barcode...
                                        </p>
                                    )}
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Quantity
                                        </label>
                                        <input
                                            type="number"
                                            name="quantity"
                                            value={formData.quantity}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Low Stock Point (20% of quantity)
                                        </label>
                                        <input
                                            type="number"
                                            name="reorderPoint"
                                            value={formData.reorderPoint}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Selling Price (GHS)
                                        </label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            name="sellingPrice"
                                            value={formData.sellingPrice}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Cost Price (GHS)
                                        </label>
                                        <div className="flex gap-2">
                                            <input
                                                type="number"
                                                step="0.01"
                                                name="costPrice"
                                                value={formData.costPrice}
                                                onChange={handleChange}
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => setIsCostCalculatorOpen(true)}
                                                className="px-3 py-2 bg-gray-100 border rounded-lg hover:bg-gray-200 text-gray-600"
                                                title="Calculate Cost"
                                            >
                                                <Calculator className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end gap-3 mt-6">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="px-4 py-2 border rounded-lg text-gray-700 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isValidating || !!skuError}
                                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Add Product
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <CostCalculatorModal
                isOpen={isCostCalculatorOpen}
                onClose={() => setIsCostCalculatorOpen(false)}
                onApplyCost={handleApplyCost}
            />
        </div>
    );
};

export default ProductAddModal;