import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import { Loader2, AlertTriangle } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import TimePeriodPicker from '../components/time_period_picker';

const ReportsFinancials = () => {
    const [dateRange, setDateRange] = useState({
        start: new Date(new Date().setDate(new Date().getDate() - 7)),
        end: new Date(),
        label: 'Last 7 Days'
    });
    const [financialData, setFinancialData] = useState({
        isLoading: true,
        error: null,
        data: null
    });
    const [user, setUser] = useState(null);

    const db = getFirestore();
    const auth = getAuth();

    // Fetch user data
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setFinancialData({
                    isLoading: false,
                    error: 'Failed to authenticate user',
                    data: null
                });
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    // Fetch financial data
    useEffect(() => {
        const fetchFinancialData = async () => {
            if (!user?.storeId) return;

            try {
                setFinancialData(prev => ({ ...prev, isLoading: true }));

                const [salesSnapshot, expensesSnapshot] = await Promise.all([
                    getDocs(query(
                        collection(db, 'Stores', user.storeId, 'Sales'),
                        where('saleDate', '>=', Timestamp.fromDate(dateRange.start)),
                        where('saleDate', '<=', Timestamp.fromDate(dateRange.end)),
                        orderBy('saleDate', 'desc')
                    )),
                    getDocs(query(
                        collection(db, 'Stores', user.storeId, 'Expenses'),
                        where('date', '>=', Timestamp.fromDate(dateRange.start)),
                        where('date', '<=', Timestamp.fromDate(dateRange.end)),
                        orderBy('date', 'desc')
                    ))
                ]);

                const processedData = processFinancialData(
                    salesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
                    expensesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
                    dateRange.start
                );

                setFinancialData({
                    isLoading: false,
                    error: null,
                    data: processedData
                });

            } catch (error) {
                console.error('Error fetching financial data:', error);
                setFinancialData({
                    isLoading: false,
                    error: 'Failed to load financial data',
                    data: null
                });
            }
        };

        fetchFinancialData();
    }, [user, dateRange, db]);

    const handleDateRangeChange = (start, end, label) => {
        setDateRange({ start, end, label });
    };

    const processFinancialData = (sales, expenses, startDate) => {
        const dailyData = {};
        const endDate = new Date(dateRange.end);

        // Process sales data
        sales.forEach(sale => {
            const dateKey = sale.saleDate.toDate().toISOString().split('T')[0];
            if (!dailyData[dateKey]) {
                dailyData[dateKey] = {
                    revenue: 0,
                    expenses: 0,
                    profit: 0,
                    cogs: 0
                };
            }

            sale.items.forEach(item => {
                const revenue = item.price * item.quantity;
                const cogs = (item.costPrice || 0) * item.quantity;
                dailyData[dateKey].revenue += revenue;
                dailyData[dateKey].cogs += cogs;
                dailyData[dateKey].profit += (revenue - cogs);
            });
        });

        // Process expenses data
        expenses.forEach(expense => {
            const dateKey = expense.date.toDate().toISOString().split('T')[0];
            if (!dailyData[dateKey]) {
                dailyData[dateKey] = {
                    revenue: 0,
                    expenses: 0,
                    profit: 0,
                    cogs: 0
                };
            }
            dailyData[dateKey].expenses += expense.amount || 0;
            dailyData[dateKey].profit -= expense.amount || 0;
        });

        // Create chronological data array with all dates in range
        const chartData = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dateKey = currentDate.toISOString().split('T')[0];
            chartData.push({
                date: dateKey,
                ...dailyData[dateKey] || {
                    revenue: 0,
                    expenses: 0,
                    profit: 0,
                    cogs: 0
                },
                formattedDate: currentDate.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric'
                })
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Calculate totals for the metrics cards
        const totals = chartData.reduce((acc, day) => ({
            revenue: acc.revenue + day.revenue,
            expenses: acc.expenses + day.expenses,
            profit: acc.profit + day.profit,
            cogs: acc.cogs + day.cogs
        }), { revenue: 0, expenses: 0, profit: 0, cogs: 0 });

        return {
            chartData,
            totals
        };
    };

    if (financialData.isLoading) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="flex flex-col items-center gap-3">
                        <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                        <div className="text-lg text-gray-600">Loading financial data...</div>
                    </div>
                </div>
            </div>
        );
    }

    if (financialData.error) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center text-red-500">
                    <AlertTriangle className="mr-2 h-5 w-5" />
                    <div>{financialData.error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 p-8 overflow-y-auto">
                <div className="flex justify-between items-center my-10">
                    <h1 className="text-5xl font-semibold">Financial Reports</h1>
                    <TimePeriodPicker
                        onDateRangeChange={handleDateRangeChange}
                        selectedLabel={dateRange.label}
                        onLabelChange={(label) => setDateRange(prev => ({ ...prev, label }))}
                    />
                </div>

                {financialData.data && (
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                            <MetricCard
                                title="Total Revenue"
                                value={`GHS ${financialData.data.totals.revenue.toFixed(2)}`}
                                subtitle={dateRange.label}
                            />
                            <MetricCard
                                title="Total Expenses"
                                value={`GHS ${financialData.data.totals.expenses.toFixed(2)}`}
                                subtitle={dateRange.label}
                            />
                            <MetricCard
                                title="Cost of Goods Sold"
                                value={`GHS ${financialData.data.totals.cogs.toFixed(2)}`}
                                subtitle={dateRange.label}
                            />
                            <MetricCard
                                title="Net Profit"
                                value={`GHS ${financialData.data.totals.profit.toFixed(2)}`}
                                subtitle={dateRange.label}
                            />
                        </div>

                        <div className="bg-white border p-4 min-h-[32rem]">
                            <div className="flex justify-between items-center mb-6">
                                <h2 className="text-xl font-semibold">Financial Overview</h2>
                            </div>
                            <div className="h-[28rem]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={financialData.data.chartData}>
                                        <XAxis dataKey="formattedDate" />
                                        <YAxis />
                                        <Tooltip formatter={(value) => [`GHS ${value.toFixed(2)}`, 'Amount']} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Line
                                            type="monotone"
                                            dataKey="revenue"
                                            name="Revenue"
                                            stroke="#8884d8"
                                            strokeWidth={2}
                                            dot={false}
                                            activeDot={{ r: 8 }}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="profit"
                                            name="Profit"
                                            stroke="#82ca9d"
                                            strokeWidth={2}
                                            dot={false}
                                            activeDot={{ r: 8 }}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="expenses"
                                            name="Expenses"
                                            stroke="#ff7300"
                                            strokeWidth={2}
                                            dot={false}
                                            activeDot={{ r: 8 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const MetricCard = ({ title, value, subtitle }) => (
    <div className="bg-white border p-4">
        <h3 className="text-sm text-gray-500 mb-1">{title}</h3>
        <p className="text-2xl font-bold mb-1">{value}</p>
        <p className="text-xs text-gray-500">{subtitle}</p>
    </div>
);

export default ReportsFinancials;