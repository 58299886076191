import React, { useState } from 'react';
import {
    Search,
    Plus,
    Loader2,
    X,
    Pencil,
    Sparkles
} from 'lucide-react';
import {
    collection,
    addDoc,
    getFirestore
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import StockItem from './stock_item'; // We'll keep StockItem as a separate component

const IndividualStockManagement = ({
    inventory,
    categories,
    onStockUpdate,
    onCategorySelect,
    onAddCategory,
    user // Add user to props
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterCategory, setFilterCategory] = useState('all');
    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);

    const filteredInventory = inventory.filter(item => {
        const matchesSearch = item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.sku?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = filterCategory === 'all' || item.categoryId === filterCategory;
        return matchesSearch && matchesCategory;
    });

    return (
        <div>
            <div className="sticky -top-8 bg-white z-10 py-4 pb-2 space-y-4 shadow-sm">
                <div className="bg-white flex items-center space-x-4 mb-3">
                    <div className="bg-white relative flex-1">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search by name or SKU..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 pr-4 py-2 w-full border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <select
                        value={filterCategory}
                        onChange={(e) => setFilterCategory(e.target.value)}
                        className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="all">All Categories</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="space-y-2 mt-2">
                {filteredInventory.length === 0 ? (
                    <div className="text-center py-8 text-gray-500">
                        No items found
                    </div>
                ) : (
                    filteredInventory.map(item => (
                        <StockItem
                            key={item.id}
                            item={item}
                            onStockUpdate={onStockUpdate}
                            onCategorySelect={onCategorySelect}
                            categories={categories}
                        />
                    ))
                )}
            </div>


        </div>
    );
};

export default IndividualStockManagement;