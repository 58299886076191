import React from 'react';
import { XCircle, Trash2, Calendar, RefreshCcw, DollarSign, File, X } from 'lucide-react';

const RecurringExpenseModal = ({ expense, onClose, onMarkPaid, onDelete, loading, deleting }) => {
    if (!expense) return null;

    const handleSubmit = (e) => {
        e.preventDefault();
        onMarkPaid(expense.id);
    };

    const handleDelete = (e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete this recurring expense? This action cannot be undone.')) {
            onDelete(expense.id);
        }
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-4"
            onClick={onClose}
        >
            <div
                className="bg-white rounded-xl shadow-xl w-full max-w-md relative"
                onClick={handleModalClick}
            >
                {/* Header */}
                <div className="p-6 border-b border-gray-100">
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 p-2 rounded-lg text-gray-400 hover:text-gray-600 hover:bg-gray-200 transition-colors"
                    >
                        <X className="w-5 h-5" />
                    </button>
                    <h2 className="text-xl font-semibold text-gray-800">Payment Details</h2>
                    <p className="text-sm text-gray-500 mt-1">Review and confirm payment for recurring expense</p>
                </div>

                {/* Content */}
                <div className="p-6 space-y-5">
                    <div className="flex items-start space-x-3">
                        <div className="mt-1">
                            <File className="w-5 h-5 text-blue-500" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <div className="mt-1 text-sm text-gray-900 font-medium">{expense.description}</div>
                            <div className="mt-1 text-sm text-gray-500">
                                <span className="text-xs mr-1">GHS</span>
                                {expense.amount.toFixed(2)}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-start space-x-3">
                        <div className="mt-1">
                            <RefreshCcw className="w-5 h-5 text-green-500" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Payment Schedule</label>
                            <div className="mt-1 text-sm text-gray-900 capitalize">
                                {expense.recurringPeriod === 'custom'
                                    ? `Every ${expense.customPeriod.value} ${expense.customPeriod.unit}`
                                    : expense.recurringPeriod}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-start space-x-3">
                        <div className="mt-1">
                            <Calendar className="w-5 h-5 text-purple-500" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Next Due Date</label>
                            <div className="mt-1 text-sm text-gray-900">
                                {expense.nextDueDate?.toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Actions */}
                <div className="p-6 border-t border-gray-100 flex flex-col space-y-3">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        disabled={loading}
                        className="w-full px-4 py-2.5 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors"
                    >
                        {loading ? 'Processing Payment...' : 'It has been Paid'}
                    </button>

                    <div className="flex space-x-3">
                        <button
                            type="button"
                            onClick={handleDelete}
                            disabled={deleting}
                            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-red-600 bg-red-50 rounded-lg hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200 transition-colors disabled:opacity-50"
                        >
                            <Trash2 className="w-4 h-4 mr-2" />
                            {deleting ? 'Deleting...' : 'Delete'}
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition-colors"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecurringExpenseModal;