import React, { useState, useEffect } from 'react';
import { X, Plus, Trash2, Search, CalendarIcon, ChevronDown, AlertTriangle, Loader2 } from 'lucide-react';
import {
    doc,
    setDoc,
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    addDoc,
    serverTimestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';

const CreateQuoteForm = ({ onClose, onSuccess }) => {
    const [quoteData, setQuoteData] = useState({
        customerDetails: {
            name: '',
            email: '',
            phone: '',
            address: '',
        },
        items: [],
        terms: {
            validUntil: '',
            notes: '',
        },
        discount: 0,
        tax: 0,
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [showProductSearch, setShowProductSearch] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [inventoryData, setInventoryData] = useState({
        items: [],
        isLoading: false,
        error: null
    });
    const [user, setUser] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const db = getFirestore();
    const auth = getAuth();
    const storeId = localStorage.getItem('storeId');

    // Fetch user data
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setInventoryData(prev => ({
                    ...prev,
                    error: 'Failed to authenticate user'
                }));
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    // Fetch inventory data
    useEffect(() => {
        const fetchInventoryData = async () => {
            if (!user?.storeId) return;

            setInventoryData(prev => ({ ...prev, isLoading: true }));

            try {
                const inventoryQuery = query(
                    collection(db, 'Stores', user.storeId, 'Inventory'),
                    orderBy('itemName', 'asc')
                );

                const inventorySnapshot = await getDocs(inventoryQuery);
                const items = [];

                inventorySnapshot.forEach(doc => {
                    items.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                setInventoryData({
                    items,
                    isLoading: false,
                    error: null
                });
            } catch (error) {
                console.error('Error fetching inventory data:', error);
                setInventoryData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load inventory data'
                }));
            }
        };

        fetchInventoryData();
    }, [user, db]);

    const setValidUntilDays = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        const formattedDate = date.toISOString().split('T')[0];
        setQuoteData(prev => ({
            ...prev,
            terms: { ...prev.terms, validUntil: formattedDate }
        }));
    };

    const handleCustomerChange = (field, value) => {
        setQuoteData(prev => ({
            ...prev,
            customerDetails: {
                ...prev.customerDetails,
                [field]: value
            }
        }));
        if (validationErrors[field]) {
            setValidationErrors(prev => ({ ...prev, [field]: null }));
        }
    };

    const handleAddItem = (inventoryItem) => {
        const existingItem = quoteData.items.find(item => item.id === inventoryItem.id);

        if (existingItem) {
            setQuoteData(prev => ({
                ...prev,
                items: prev.items.map(item =>
                    item.id === inventoryItem.id
                        ? { ...item, quantity: Math.min(item.quantity + 1, inventoryItem.quantity) }
                        : item
                )
            }));
        } else {
            setQuoteData(prev => ({
                ...prev,
                items: [...prev.items, {
                    id: inventoryItem.id,
                    itemName: inventoryItem.itemName,
                    sku: inventoryItem.sku,
                    price: inventoryItem.sellingPrice,
                    quantity: 1,
                    maxQuantity: inventoryItem.quantity
                }]
            }));
        }
        setShowProductSearch(false);
        setSearchTerm('');
    };

    const handleQuantityChange = (index, quantity) => {
        setQuoteData(prev => ({
            ...prev,
            items: prev.items.map((item, i) => {
                if (i === index) {
                    const newQuantity = Math.min(
                        Math.max(1, parseInt(quantity) || 0),
                        item.maxQuantity
                    );
                    return { ...item, quantity: newQuantity };
                }
                return item;
            })
        }));
    };

    const handleRemoveItem = (index) => {
        setQuoteData(prev => ({
            ...prev,
            items: prev.items.filter((_, i) => i !== index)
        }));
    };

    const calculateSubtotal = () => {
        return quoteData.items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const discountAmount = (subtotal * quoteData.discount) / 100;
        const taxAmount = ((subtotal - discountAmount) * quoteData.tax) / 100;
        return subtotal - discountAmount + taxAmount;
    };

    const handlePhoneChange = (value, country) => {
        // If it's a Ghana number (starts with +233) and has a leading 0 after the country code
        if (value.startsWith('233') && value.length > 3) {
            const afterCountryCode = value.substring(3); // Get everything after '233'
            if (afterCountryCode.startsWith('0')) {
                // Remove the leading 0 after country code
                const correctedNumber = '+233' + afterCountryCode.substring(1);
                handleCustomerChange('phone', correctedNumber);
                return;
            }
        }
        // Add + prefix if it doesn't exist
        const formattedNumber = value.startsWith('+') ? value : '+' + value;
        handleCustomerChange('phone', formattedNumber);
    };

    const validateForm = () => {
        const errors = {};
        if (!quoteData.customerDetails.name.trim()) {
            errors.name = 'Customer name is required';
        }
        // Phone validation - should be at least the country code plus 4 digits
        if (!quoteData.customerDetails.phone) {
            errors.phone = 'Phone number is required';
        } else if (quoteData.customerDetails.phone.length < 6) { // Country code + min 4 digits
            errors.phone = 'Please enter a valid phone number';
        }
        // Only validate email if it's provided
        if (quoteData.customerDetails.email && !/\S+@\S+\.\S+/.test(quoteData.customerDetails.email)) {
            errors.email = 'Invalid email format';
        }
        if (quoteData.items.length === 0) {
            errors.items = 'At least one item is required';
        }
        if (!quoteData.terms.validUntil) {
            errors.validUntil = 'Valid until date is required';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        if (!storeId) {
            setSubmitError('Store ID not found. Please try logging in again.');
            return;
        }

        setIsSubmitting(true);
        setSubmitError(null);

        try {
            // Format the phone number with + prefix consistently
            const formattedPhone = quoteData.customerDetails.phone.startsWith('+')
                ? quoteData.customerDetails.phone
                : '+' + quoteData.customerDetails.phone;

            // Use the formatted phone number directly as the customer ID
            const customerId = formattedPhone;

            // Save customer data
            const customerData = {
                name: quoteData.customerDetails.name,
                phone: formattedPhone,
                address: quoteData.customerDetails.address || '',
                updatedAt: serverTimestamp(),
                totalQuotes: 1,
                totalPurchases: 0,
                lastQuoteDate: serverTimestamp()
            };

            // Only include email if it's provided
            if (quoteData.customerDetails.email) {
                customerData.email = quoteData.customerDetails.email;
            }

            const customerRef = doc(db, 'Stores', storeId, 'Customers', customerId);

            // Check if customer already exists
            const existingCustomer = await getDocs(
                query(
                    collection(db, 'Stores', storeId, 'Customers'),
                    where('phone', '==', formattedPhone)
                )
            );

            if (!existingCustomer.empty) {
                // Update existing customer
                const existing = existingCustomer.docs[0].data();
                customerData.totalQuotes = (existing.totalQuotes || 0) + 1;
                customerData.totalPurchases = existing.totalPurchases || 0;
                customerData.createdAt = existing.createdAt;

                if (!quoteData.customerDetails.email && existing.email) {
                    customerData.email = existing.email;
                }
            } else {
                // New customer
                customerData.createdAt = serverTimestamp();
            }

            // Save/update customer document
            await setDoc(customerRef, customerData, { merge: true });

            const now = new Date();
            const validUntil = new Date(quoteData.terms.validUntil);

            // Save quote data
            const quoteToSave = {
                ...quoteData,
                customerDetails: {
                    ...quoteData.customerDetails,
                    phone: formattedPhone,
                    customerId: customerId,
                },
                subtotal: calculateSubtotal(),
                total: calculateTotal(),
                createdAt: serverTimestamp(),
                status: now > validUntil ? 'expired' : 'active',
                createdBy: auth.currentUser?.uid || null,
                storeId: storeId,
                quoteNumber: `QT-${Date.now()}`
            };

            if (quoteData.customerDetails.email) {
                quoteToSave.customerDetails.email = quoteData.customerDetails.email;
            }

            const quoteRef = await addDoc(
                collection(db, 'Stores', storeId, 'SalesQuotes'),
                quoteToSave
            );

            if (onSuccess) {
                onSuccess(quoteRef.id);
            }

            onClose();
        } catch (error) {
            console.error('Error saving quote:', error);
            setSubmitError('Failed to save quote. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderProductSearch = () => {
        if (inventoryData.isLoading) {
            return (
                <div className="flex items-center justify-center py-8">
                    <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
                    <span className="ml-2 text-gray-600">Loading inventory...</span>
                </div>
            );
        }

        if (inventoryData.error) {
            return (
                <div className="text-center py-8 text-red-500">
                    <div className="font-medium">Error loading inventory</div>
                    <div className="text-sm">{inventoryData.error}</div>
                </div>
            );
        }

        const filteredInventory = inventoryData.items.filter(item =>
            item.itemName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.sku?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return (
            <div className="mt-2 border rounded-lg divide-y max-h-48 overflow-y-auto bg-white">
                {filteredInventory.length === 0 ? (
                    <div className="p-4 text-center text-gray-500">
                        No items found matching your search
                    </div>
                ) : (
                    filteredInventory.map(item => (
                        <div
                            key={item.id}
                            className="p-2 hover:bg-gray-50 cursor-pointer"
                            onClick={() => handleAddItem(item)}
                        >
                            <div className="flex justify-between">
                                <div>
                                    <div className="font-medium">{item.itemName}</div>
                                    <div className="text-sm text-gray-500">SKU: {item.sku || 'N/A'}</div>
                                </div>
                                <div className="text-right">
                                    <div className="font-medium text-sm">GHS {item.sellingPrice?.toFixed(2)}</div>
                                    <div className={`text-sm ${item.quantity === 0 ? 'text-red-500' :
                                        item.quantity <= item.reorderPoint ? 'text-yellow-500' :
                                            'text-green-500'
                                        }`}>
                                        {item.quantity} in stock
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-6 border-b">
                    <h2 className="text-2xl font-semibold">Create New Quote</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-100"
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="p-6">
                    {/* Customer Details Section */}
                    <div className="mb-8">
                        <h3 className="text-lg font-medium mb-4">Customer Details</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Customer Name*
                                </label>
                                <input
                                    type="text"
                                    value={quoteData.customerDetails.name}
                                    onChange={(e) => handleCustomerChange('name', e.target.value)}
                                    className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none
                                        ${validationErrors.name ? 'border-red-500' : 'border-gray-300'}`}
                                />
                                {validationErrors.name && (
                                    <p className="text-red-500 text-sm mt-1">{validationErrors.name}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Phone*
                                </label>
                                <div className={`phone-input-container ${validationErrors.phone ? 'error' : ''}`}>
                                    <PhoneInput
                                        country={'gh'}
                                        placeholder="+233 XXX XXX XXX"
                                        value={quoteData.customerDetails.phone}
                                        onChange={handlePhoneChange}
                                        enableSearch={true}
                                        disableSearchIcon={false}
                                        searchPlaceholder="Search country..."
                                    />
                                </div>
                                {validationErrors.phone && (
                                    <p className="text-red-500 text-sm mt-1">{validationErrors.phone}</p>
                                )}
                                {/* {quoteData.customerDetails.phone?.startsWith('233') && (
                                    <p className="text-gray-500 text-sm mt-1">
                                        Format: +233 XXX XXX XXX (no leading zero needed)
                                    </p>
                                )} */}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    value={quoteData.customerDetails.email}
                                    onChange={(e) => handleCustomerChange('email', e.target.value)}
                                    className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none
                                        ${validationErrors.email ? 'border-red-500' : 'border-gray-300'}`}
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    value={quoteData.customerDetails.address}
                                    onChange={(e) => handleCustomerChange('address', e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Items Section */}
                    <div className="mb-8">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-medium">Items</h3>
                            <button
                                type="button"
                                onClick={() => setShowProductSearch(true)}
                                className="flex items-center text-blue-500 hover:text-blue-600 px-3 py-1 rounded-lg hover:bg-blue-50"
                                disabled={inventoryData.isLoading}
                            >
                                <Plus className="h-4 w-4 mr-1" />
                                Add Item
                            </button>
                        </div>

                        {validationErrors.items && (
                            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-sm">
                                {validationErrors.items}
                            </div>
                        )}

                        {showProductSearch && (
                            <div className="mb-4 border rounded-lg p-4 bg-gray-50">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search products by name or SKU..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full p-2 pl-10 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        disabled={inventoryData.isLoading}
                                    />
                                    <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                                </div>
                                {renderProductSearch()}
                            </div>
                        )}

                        {quoteData.items.length > 0 ? (
                            <div className="border rounded-lg overflow-hidden">
                                <table className="w-full">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Item</th>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">SKU</th>
                                            <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Quantity</th>
                                            <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Price</th>
                                            <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Total</th>
                                            <th className="px-4 py-2 w-10"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {quoteData.items.map((item, index) => (
                                            <tr key={index} className="hover:bg-gray-50">
                                                <td className="px-4 py-2 text-base">
                                                    <div className="font-medium">{item.itemName}</div>
                                                </td>
                                                <td className="px-4 py-2 text-sm text-gray-500">
                                                    {item.sku || 'N/A'}
                                                </td>
                                                <td className="px-4 py-2">
                                                    <div className="flex items-center justify-end">
                                                        <input
                                                            type="number"
                                                            min="1"
                                                            max={item.maxQuantity}
                                                            value={item.quantity}
                                                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                            className="w-20 p-1 border rounded text-right"
                                                        />
                                                        {item.quantity === item.maxQuantity && (
                                                            <div className="ml-2 text-yellow-500" title="Maximum available quantity">
                                                                <AlertTriangle className="h-4 w-4" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-4 py-2 text-right text-sm">
                                                    GHS {item.price.toFixed(2)}
                                                </td>
                                                <td className="px-4 py-2 text-right text-sm">
                                                    GHS {(item.price * item.quantity).toFixed(2)}
                                                </td>
                                                <td className="px-4 py-2">
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveItem(index)}
                                                        className="text-red-500 hover:text-red-700 p-1 rounded-full hover:bg-red-50"
                                                    >
                                                        <Trash2 className="h-4 w-4" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="text-center py-8 bg-gray-50 border-2 border-dashed rounded-lg">
                                <div className="text-gray-500">No items added to quote yet</div>
                                <div className="text-sm text-gray-400">Click "Add Item" to begin</div>
                            </div>
                        )}
                    </div>

                    {/* Quote Terms Section */}
                    <div className="mb-8">
                        <h3 className="text-lg font-medium mb-4">Quote Terms</h3>
                        <div className="grid grid-cols-1 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Valid Until*
                                </label>
                                <div className="flex gap-2">
                                    <div className="relative flex-1">
                                        <input
                                            type="date"
                                            value={quoteData.terms.validUntil}
                                            onChange={(e) => setQuoteData(prev => ({
                                                ...prev,
                                                terms: { ...prev.terms, validUntil: e.target.value }
                                            }))}
                                            className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none
                                            ${validationErrors.validUntil ? 'border-red-500' : 'border-gray-300'}`}
                                            min={new Date().toISOString().split('T')[0]}
                                        />
                                        {validationErrors.validUntil && (
                                            <p className="text-red-500 text-sm mt-1">{validationErrors.validUntil}</p>
                                        )}
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => setValidUntilDays(14)}
                                        className="px-3 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-200"
                                    >
                                        14 days
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setValidUntilDays(30)}
                                        className="px-3 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-200"
                                    >
                                        30 days
                                    </button>
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Notes
                                </label>
                                <textarea
                                    value={quoteData.terms.notes}
                                    onChange={(e) => setQuoteData(prev => ({
                                        ...prev,
                                        terms: { ...prev.terms, notes: e.target.value }
                                    }))}
                                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none h-24 resize-none"
                                    placeholder="Add any additional notes or terms..."
                                />
                            </div>
                        </div>
                    </div>

                    {/* Summary Section */}
                    <div className="mb-8">
                        <h3 className="text-lg font-medium mb-4">Summary</h3>
                        <div className="bg-gray-50 rounded-lg p-4">
                            <div className="space-y-3">
                                <div className="flex justify-between">
                                    <span className="text-gray-600">Subtotal:</span>
                                    <span className="font-medium">GHS {calculateSubtotal().toFixed(2)}</span>
                                </div>

                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Discount (%):</span>
                                    <div className="w-32">
                                        <input
                                            type="number"
                                            min="0"
                                            max="100"
                                            step="0.1"
                                            value={quoteData.discount}
                                            onChange={(e) => setQuoteData(prev => ({
                                                ...prev,
                                                discount: Math.min(100, Math.max(0, parseFloat(e.target.value) || 0))
                                            }))}
                                            className="w-full p-1 border border-gray-300 rounded text-right focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Tax (%):</span>
                                    <div className="w-32">
                                        <input
                                            type="number"
                                            min="0"
                                            max="100"
                                            step="0.1"
                                            value={quoteData.tax}
                                            onChange={(e) => setQuoteData(prev => ({
                                                ...prev,
                                                tax: Math.min(100, Math.max(0, parseFloat(e.target.value) || 0))
                                            }))}
                                            className="w-full p-1 border border-gray-300 rounded text-right focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        />
                                    </div>
                                </div>

                                <div className="pt-3 border-t border-gray-200">
                                    <div className="flex justify-between font-medium text-lg">
                                        <span>Total:</span>
                                        <span>GHS {calculateTotal().toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-end space-x-4 border-t pt-6">
                        {submitError && (
                            <div className="flex-1 text-red-500 text-sm">
                                {submitError}
                            </div>
                        )}
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-6 py-2 border border-gray-300 rounded-lg text-gray-600 hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-200"
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-200 disabled:bg-blue-300"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <span className="flex items-center">
                                    <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                                    Saving...
                                </span>
                            ) : (
                                'Create Quote'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateQuoteForm;