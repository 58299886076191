import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { collection, query, where, getDocs, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const ReturnModal = ({ isOpen, onClose, onSubmit }) => {
    const initialState = {
        orderNumber: '',
        reason: '',
        items: [],
        stockDecision: 'pending',
        refundType: 'full'
    };
    const [formData, setFormData] = useState(initialState);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [customRefundAmount, setCustomRefundAmount] = useState(0);
    const [maxRefundAmount, setMaxRefundAmount] = useState(0);

    const db = getFirestore();
    const auth = getAuth();

    // Reset all state when modal is opened
    useEffect(() => {
        if (isOpen) {
            setFormData(initialState);
            setError('');
            setIsLoading(false);
            setOrderDetails(null);
            setCustomRefundAmount(0);
            setMaxRefundAmount(0);
            setIsSubmitting(false);
        }
    }, [isOpen]);

    // Calculate maximum refund amount based on selected items
    useEffect(() => {
        const maxAmount = formData.items.reduce((sum, item) => {
            if (item.selected && item.returnQuantity > 0) {
                return sum + (item.price * item.returnQuantity);
            }
            return sum;
        }, 0);

        setMaxRefundAmount(maxAmount);

        // If custom amount exceeds max, adjust it
        if (customRefundAmount > maxAmount) {
            setCustomRefundAmount(maxAmount);
        }
    }, [formData.items]);

    const fetchOrderDetails = async (orderNumber) => {
        setIsLoading(true);
        setError('');
        try {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                throw new Error('No authenticated user found');
            }

            const userDoc = await getDocs(
                query(
                    collection(db, 'Users'),
                    where('email', '==', currentUser.email)
                )
            );

            if (userDoc.empty) {
                throw new Error('User data not found');
            }

            const userData = userDoc.docs[0].data();

            // First get the original order
            const salesQuery = query(
                collection(db, 'Stores', userData.storeId, 'Sales'),
                where('orderNumber', '==', orderNumber)
            );

            const salesSnapshot = await getDocs(salesQuery);

            if (salesSnapshot.empty) {
                throw new Error('Order not found');
            }

            const orderData = salesSnapshot.docs[0].data();

            // Then get all returns for this order
            const returnsQuery = query(
                collection(db, 'Stores', userData.storeId, 'Returns'),
                where('orderNumber', '==', orderNumber)
            );

            const returnsSnapshot = await getDocs(returnsQuery);
            const previousReturns = {};

            // Sum up all previously returned quantities for each item
            returnsSnapshot.docs.forEach(doc => {
                const returnData = doc.data();
                returnData.items.forEach(item => {
                    // Use a combination of unique identifiers to match items
                    const itemKey = `${item.itemId}_${item.itemName}_${item.price}`; // Add more unique identifiers if needed
                    if (!previousReturns[itemKey]) {
                        previousReturns[itemKey] = 0;
                    }
                    previousReturns[itemKey] += item.returnedQuantity || 0;
                });
            });

            // Adjust available quantities based on previous returns
            const itemsWithAdjustedQuantities = orderData.items.map(item => {
                const itemKey = `${item.itemId}_${item.itemName}_${item.price}`;
                const previouslyReturned = previousReturns[itemKey] || 0;
                const remainingQuantity = item.quantity - previouslyReturned;

                return {
                    ...item,
                    selected: false,
                    returnQuantity: 0,
                    stockDecision: 'pending',
                    refundAmount: item.price,
                    originalQuantity: item.quantity,
                    previouslyReturned,
                    quantity: remainingQuantity,
                    disabled: remainingQuantity <= 0
                };
            });

            setOrderDetails({
                ...orderData,
                items: itemsWithAdjustedQuantities
            });

            setFormData(prev => ({
                ...prev,
                items: itemsWithAdjustedQuantities
            }));
            setCustomRefundAmount(0);

        } catch (error) {
            setError(error.message);
            setOrderDetails(null);
            setFormData(prev => ({
                ...prev,
                items: []
            }));
        } finally {
            setIsLoading(false);
        }
    };


    const handleOrderNumberChange = async (e) => {
        const value = e.target.value;
        setFormData(prev => ({
            ...prev,
            orderNumber: value
        }));

        if (value.length > 0) {
            await fetchOrderDetails(value);
        }
    };

    const handleItemSelection = (index, field, value) => {
        const newItems = [...formData.items];
        newItems[index][field] = value;

        // Initialize returnQuantity to 1 when item is selected
        if (field === 'selected' && value === true) {
            newItems[index].returnQuantity = 1;
        }

        if (field === 'returnQuantity') {
            const maxQuantity = formData.items[index].quantity;
            newItems[index].returnQuantity = Math.min(Math.max(0, value), maxQuantity);
        }

        setFormData(prev => ({
            ...prev,
            items: newItems
        }));
    };

    const handleItemStockDecision = (index, decision) => {
        const newItems = [...formData.items];
        newItems[index].stockDecision = decision;
        setFormData(prev => ({
            ...prev,
            items: newItems
        }));
    };

    const handleRefundTypeChange = (type) => {
        setFormData(prev => ({
            ...prev,
            refundType: type
        }));

        if (type === 'full') {
            setCustomRefundAmount(maxRefundAmount);
        } else if (type === 'none') {
            setCustomRefundAmount(0);
        }
    };

    const handleCustomRefundChange = (value) => {
        const numValue = parseFloat(value) || 0;
        // Ensure refund amount doesn't exceed maximum
        setCustomRefundAmount(Math.min(numValue, maxRefundAmount));
    };

    const calculateTotalRefund = () => {
        if (formData.refundType === 'none') return 0;
        if (formData.refundType === 'partial') return customRefundAmount;
        return maxRefundAmount;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.orderNumber) {
            setError('Order number is required');
            return;
        }

        const selectedItems = formData.items.filter(
            item => item.selected && item.returnQuantity > 0
        );

        if (selectedItems.length === 0) {
            setError('Please select at least one item to return');
            return;
        }

        setIsSubmitting(true);
        try {
            await onSubmit({
                orderNumber: formData.orderNumber,
                reason: formData.reason,
                items: selectedItems.map(item => ({
                    ...item,
                    returnedQuantity: item.returnQuantity // Explicitly name it
                })),
                refundType: formData.refundType,
                refundAmount: calculateTotalRefund(),
                stockDecision: formData.stockDecision
            });
        } catch (error) {
            setError('Failed to submit return. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-full max-w-2xl mx-4 max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-4 border-b sticky top-0 bg-white">
                    <h2 className="text-xl font-semibold">Return Items</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X className="h-5 w-5" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="p-6">
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Order Number*
                            </label>
                            <input
                                type="text"
                                value={formData.orderNumber}
                                onChange={handleOrderNumberChange}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                            {isLoading && (
                                <p className="text-gray-500 text-sm mt-1">Loading order details...</p>
                            )}
                            {error && (
                                <p className="text-red-500 text-sm mt-1">{error}</p>
                            )}
                        </div>

                        {orderDetails && (
                            <>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Return Reason
                                    </label>
                                    <textarea
                                        name="reason"
                                        value={formData.reason}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            reason: e.target.value
                                        }))}
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        rows="2"
                                    />
                                </div>

                                <div className="space-y-4">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Select Items to Return
                                    </label>

                                    <div className="space-y-2">
                                        {formData.items.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`space-y-2 p-3 border rounded-lg ${item.quantity <= 0 ? 'bg-gray-50 opacity-60' : ''}`}
                                            >
                                                <div className="flex items-center space-x-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={item.selected}
                                                        onChange={(e) => handleItemSelection(index, 'selected', e.target.checked)}
                                                        className={`h-4 w-4 ${item.quantity <= 0
                                                            ? 'text-gray-300 cursor-not-allowed'
                                                            : 'text-blue-600 cursor-pointer'
                                                            }`}
                                                        disabled={item.quantity <= 0}
                                                    />
                                                    <div className="flex-1">
                                                        <p className="font-medium">{item.itemName}</p>
                                                        <div className="flex flex-col gap-1">
                                                            <p className="text-sm text-gray-500">
                                                                Available Qty: {item.quantity}
                                                                {item.previouslyReturned > 0 && (
                                                                    <span className="ml-2 text-yellow-600">
                                                                        ({item.previouslyReturned} previously returned)
                                                                    </span>
                                                                )}
                                                                {' '}| Price: GHS {item.price}
                                                            </p>
                                                            {item.quantity <= 0 && (
                                                                <p className="text-sm text-red-500">
                                                                    No items available for return
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {item.selected && item.quantity > 0 && (
                                                        <input
                                                            type="number"
                                                            min="1"
                                                            max={item.quantity}
                                                            value={item.returnQuantity}
                                                            onChange={(e) => handleItemSelection(
                                                                index,
                                                                'returnQuantity',
                                                                parseInt(e.target.value) || 0
                                                            )}
                                                            className="w-20 px-2 py-1 border rounded-lg"
                                                            placeholder="Qty"
                                                        />
                                                    )}
                                                </div>

                                                {item.selected && item.quantity > 0 && (
                                                    <div className="mt-3 p-3 bg-gray-50 rounded-lg border border-gray-200">
                                                        <div className="flex items-center justify-between mb-2">
                                                            <p className="text-sm font-medium text-gray-700">
                                                                Stock Decision
                                                            </p>
                                                            <span className="text-xs text-gray-500">
                                                                Choose what to do with returned items
                                                            </span>
                                                        </div>
                                                        <div className="grid grid-cols-3 gap-2">
                                                            <button
                                                                type="button"
                                                                onClick={() => handleItemStockDecision(index, 'restock')}
                                                                className={`p-2 rounded-lg text-sm font-medium text-center transition-colors
                            ${item.stockDecision === 'restock'
                                                                        ? 'bg-green-100 text-green-700 border-2 border-green-500'
                                                                        : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'}`}
                                                            >
                                                                Return to Stock
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => handleItemStockDecision(index, 'discard')}
                                                                className={`p-2 rounded-lg text-sm font-medium text-center transition-colors
                            ${item.stockDecision === 'discard'
                                                                        ? 'bg-red-100 text-red-700 border-2 border-red-500'
                                                                        : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'}`}
                                                            >
                                                                Discard Item
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => handleItemStockDecision(index, 'pending')}
                                                                className={`p-2 rounded-lg text-sm font-medium text-center transition-colors
                            ${item.stockDecision === 'pending'
                                                                        ? 'bg-yellow-100 text-yellow-700 border-2 border-yellow-500'
                                                                        : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'}`}
                                                            >
                                                                Decide Later
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="space-y-3 pt-4 border-t">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Refund Options
                                    </label>
                                    <div className="flex space-x-4">
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="refundType"
                                                checked={formData.refundType === 'full'}
                                                onChange={() => handleRefundTypeChange('full')}
                                                className="mr-2"
                                            />
                                            Full Refund
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="refundType"
                                                checked={formData.refundType === 'partial'}
                                                onChange={() => handleRefundTypeChange('partial')}
                                                className="mr-2"
                                            />
                                            Partial Refund
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="refundType"
                                                checked={formData.refundType === 'none'}
                                                onChange={() => handleRefundTypeChange('none')}
                                                className="mr-2"
                                            />
                                            No Refund
                                        </label>
                                    </div>

                                    {formData.refundType === 'partial' && (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Refund Amount (GHS)
                                            </label>
                                            <input
                                                type="number"
                                                min="0"
                                                max={maxRefundAmount}
                                                step="0.01"
                                                value={customRefundAmount}
                                                onChange={(e) => handleCustomRefundChange(e.target.value)}
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                            <p className="text-sm text-gray-500 mt-1">
                                                Maximum refund amount: GHS {maxRefundAmount.toFixed(2)}
                                            </p>
                                        </div>
                                    )}

                                    <div className="text-right text-sm font-medium text-gray-700">
                                        Total Refund Amount: GHS {calculateTotalRefund().toFixed(2)}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="mt-6 flex justify-end space-x-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 border rounded-lg text-gray-700 hover:bg-gray-50"
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed min-w-[100px]"
                            disabled={isLoading || isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit Return'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReturnModal;