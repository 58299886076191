import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase-config';
import { signOut } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs, getFirestore, updateDoc } from 'firebase/firestore';
import { Loader2, LogOut, Copy, Key } from 'lucide-react';
import logo from '../../assets/hotKioskLogocolor.png';

function JoinStore() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [activationCode, setActivationCode] = useState('');
    const [pendingStore, setPendingStore] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const userId = location.state?.userId || auth.currentUser?.uid;

    useEffect(() => {
        checkPendingStatus();
        getOrGenerateActivationCode();
    }, []);

    const checkPendingStatus = async () => {
        try {
            const userDoc = await getDoc(doc(db, 'Users', userId));

            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.approval === 'pending' && userData.storeId) {
                    const storeDoc = await getDoc(doc(db, 'Stores', userData.storeId));
                    if (storeDoc.exists()) {
                        setPendingStore({
                            id: storeDoc.id,
                            ...storeDoc.data()
                        });
                    }
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error checking pending status:', error);
            setError('Failed to check store status');
            setIsLoading(false);
        }
    };

    const getOrGenerateActivationCode = async () => {
        try {
            const userDoc = await getDoc(doc(db, 'Users', userId));

            if (userDoc.exists()) {
                let code = userDoc.data().activationCode;
                if (!code) {
                    code = generateCode();
                    await updateDoc(doc(db, 'Users', userId), {
                        activationCode: code
                    });
                }
                setActivationCode(code);
            }
        } catch (error) {
            console.error('Error with activation code:', error);
            setError('Failed to generate activation code');
        }
    };

    const generateCode = () => {
        const chars = 'abcdefghijkmnpqrstuvwxyz23456789';
        let code = '';
        for (let i = 0; i < 6; i++) {
            code += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return code;
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            setError('Failed to log out');
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(activationCode);
        // You might want to add a toast notification here
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <div className="bg-white border-b">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <div className="flex items-center space-x-3">
                            <img src={logo} alt="Hot Kiosk Logo" className="h-12 w-auto" />
                            <div className="h-4 w-px bg-gray-200" />
                            <h1 className="text-lg font-medium text-gray-900">Join Store</h1>
                        </div>
                        <button
                            onClick={handleLogout}
                            className="flex items-center text-gray-500 hover:text-gray-700"
                        >
                            <LogOut className="h-5 w-5 mr-2" />
                            <span className="text-sm font-medium">Logout</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="max-w-3xl mx-auto">
                    {error && (
                        <div className="mb-8 p-4 bg-red-50 rounded-lg border border-red-200 text-red-700 text-sm">
                            {error}
                        </div>
                    )}

                    {pendingStore ? (
                        <div className="bg-white rounded-2xl p-8 shadow-sm">
                            <div className="text-center">
                                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 text-blue-600 mb-6">
                                    <Loader2 className="h-8 w-8 animate-spin" />
                                </div>
                                <h2 className="text-2xl font-semibold text-gray-900 mb-4">Request Pending</h2>
                                <p className="text-gray-600 mb-4">
                                    Your request to join {pendingStore.name} is pending approval from the store owner.
                                </p>
                                <p className="text-sm text-gray-500">
                                    You'll be notified once your request is approved.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="bg-white rounded-2xl p-8 shadow-sm">
                            <div className="text-center">
                                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 text-blue-600 mb-6">
                                    <Key className="h-8 w-8" />
                                </div>
                                <h2 className="text-2xl font-semibold text-gray-900 mb-4">Your Activation Code</h2>
                                <div className="flex items-center justify-center space-x-4 mb-8">
                                    <code className="text-3xl font-mono bg-gray-100 px-4 py-2 rounded-lg">
                                        {activationCode}
                                    </code>
                                    <button
                                        onClick={copyToClipboard}
                                        className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                                    >
                                        <Copy className="h-5 w-5" />
                                    </button>
                                </div>
                                <p className="text-gray-600 mb-8">
                                    Share this code with your store owner to be added to their store.
                                </p>
                                <div className="bg-blue-50 rounded-lg p-4">
                                    <p className="text-sm text-blue-700">
                                        Once the store owner adds you, you'll be able to access the store dashboard.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default JoinStore;