import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { CheckCircle, XCircle } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, query, where, getDocs, updateDoc, deleteDoc, setDoc, deleteField, serverTimestamp, getDoc } from 'firebase/firestore';

const StoreManagement = () => {
    const [activeTab, setActiveTab] = useState('store');
    const [loading, setLoading] = useState(false);
    const [activationCode, setActivationCode] = useState('');
    const [showPermissions, setShowPermissions] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', isError: false });
    const [linkedUsers, setLinkedUsers] = useState([]);
    const [isCustomType, setIsCustomType] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        type: '',
        customType: ''
    });

    const storeTypes = [
        'Provisions',
        'Electricals',
        'Tools & Equipment',
        'Clothes & Materials',
        'Fashion, Bags & Accessories',
        'General Goods',
        'Wholesale',
        'Other'
    ];

    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        loadStoreData();
        loadStoreKeepers();
    }, []);


    const loadStoreKeepers = async () => {
        setLoading(true);
        try {
            const user = auth.currentUser;
            if (!user) return;

            const userDocRef = doc(db, 'Users', user.uid);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const storeId = userData.storeId;

                const keepersQuery = query(
                    collection(db, 'Users'),
                    where('storeId', '==', storeId),
                    where('accessLevel', '==', 2)
                );

                const keepersSnapshot = await getDocs(keepersQuery);
                const keepersData = keepersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().displayName || 'Unknown',
                    email: doc.data().email || 'No email'
                }));

                setLinkedUsers(keepersData);
            }
        } catch (error) {
            console.error('Error loading store keepers:', error);
            showNotification(true, 'Error loading store keepers');
        } finally {
            setLoading(false);
        }
    };

    const loadStoreData = async () => {
        setLoading(true);
        try {
            const user = auth.currentUser;
            if (!user) return;

            const userDocRef = doc(db, 'Users', user.uid);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const storeId = userData.storeId;

                const storeDocRef = doc(db, 'Stores', storeId);
                const storeDocSnap = await getDoc(storeDocRef);

                if (storeDocSnap.exists()) {
                    const storeData = storeDocSnap.data();
                    setFormData({
                        name: storeData.name || '',
                        type: storeData.type || '',
                        customType: storeData.customType || ''
                    });
                    setIsCustomType(storeData.type === 'custom');
                }
            }
        } catch (error) {
            console.error('Error loading store data:', error);
            showNotification(true, 'Error loading store data');
        } finally {
            setLoading(false);
        }
    };

    const handleTypeChange = (e) => {
        const selectedType = e.target.value;
        setIsCustomType(selectedType === 'custom');
        setFormData(prev => ({
            ...prev,
            type: selectedType,
            customType: selectedType === 'custom' ? prev.customType : ''
        }));
    };

    const handleStoreSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const user = auth.currentUser;
            if (!user) throw new Error('No authenticated user');

            const userDoc = await getDoc(doc(db, 'Users', user.uid));
            if (!userDoc.exists()) throw new Error('User document not found');

            const storeId = userDoc.data().storeId;

            await updateDoc(doc(db, 'Stores', storeId), {
                name: formData.name,
                type: isCustomType ? 'custom' : formData.type,
                customType: isCustomType ? formData.customType : '',
                updatedAt: serverTimestamp()
            });

            showNotification(false, 'Store details updated successfully');
        } catch (error) {
            showNotification(true, 'Error updating store details');
        } finally {
            setLoading(false);
        }
    };

    const handleLinkUser = async () => {
        if (!activationCode.trim()) return;
        setLoading(true);

        try {
            const user = auth.currentUser;
            if (!user) throw new Error('No authenticated user');

            // Fixed: Get user document correctly
            const userDocRef = doc(db, 'Users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            const storeId = userDocSnap.data().storeId;

            // Find user with activation code
            const userQuery = query(
                collection(db, 'Users'),
                where('activationCode', '==', activationCode.trim())
            );
            const userSnapshot = await getDocs(userQuery);

            if (userSnapshot.empty) {
                throw new Error('Invalid activation code');
            }

            const newUserDoc = userSnapshot.docs[0];
            const newUserId = newUserDoc.id;
            const newUserData = newUserDoc.data();

            if (newUserData.storeId) {
                throw new Error('User is already linked to a store');
            }

            // Fixed: Check existing membership
            const membershipDocRef = doc(db, 'StoreUsers', newUserId);
            const membershipDocSnap = await getDoc(membershipDocRef);

            if (membershipDocSnap.exists()) {
                throw new Error('User is already a member of a store');
            }

            // Set StoreUsers document
            await setDoc(membershipDocRef, {
                accessLevel: 2,
                userId: newUserId,
                storeId: storeId,
                role: 'Manager',
                approval: 'approved',
                addedAt: serverTimestamp(),
                displayName: newUserData.displayName,
            });

            // Update Users document
            const newUserRef = doc(db, 'Users', newUserId);
            await updateDoc(newUserRef, {
                storeId: storeId,
                accessLevel: 2,
                approval: 'approved',
                activationCode: deleteField(),
            });

            // Get store restrictions
            const storeDocRef = doc(db, 'Stores', storeId);
            const storeDocSnap = await getDoc(storeDocRef);

            if (storeDocSnap.exists()) {
                const storeData = storeDocSnap.data();
                setShowPermissions(true);
            }

            setActivationCode('');
            await loadStoreKeepers();
            showNotification(false, 'Store keeper linked successfully');
        } catch (error) {
            console.error('Linking error:', error);
            showNotification(true, error.message || 'Error linking store keeper');
        } finally {
            setLoading(false);
        }
    };

    const handleUnlinkUser = async (userId, userName) => {
        if (!window.confirm(`Are you sure you want to unlink ${userName}?`)) return;
        setLoading(true);

        try {
            // Update Users document
            await updateDoc(doc(db, 'Users', userId), {
                storeId: deleteField(),
                accessLevel: 1,
                approval: deleteField(),
            });

            // Delete StoreUsers document
            await deleteDoc(doc(db, 'StoreUsers', userId));

            await loadStoreKeepers();
            showNotification(false, 'Store keeper unlinked successfully');
        } catch (error) {
            showNotification(true, 'Error unlinking store keeper');
        } finally {
            setLoading(false);
        }
    };

    const showNotification = (isError, message) => {
        setAlert({ show: true, message, isError });
        setTimeout(() => setAlert({ show: false, message: '', isError: false }), 5000);
    };

    return (
        <div className="max-w-4xl">
            {alert.show && (
                <div className={`mb-4 p-4 rounded ${alert.isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                    {alert.message}
                </div>
            )}

            <div className="border-b mb-6">
                <div className="flex space-x-8">
                    <button
                        onClick={() => setActiveTab('store')}
                        className={`py-2 px-1 -mb-px ${activeTab === 'store'
                            ? 'border-b-2 border-orange-500 text-orange-600'
                            : 'text-gray-500 hover:text-gray-700'}`}
                    >
                        Store Details
                    </button>
                    <button
                        onClick={() => setActiveTab('users')}
                        className={`py-2 px-1 -mb-px ${activeTab === 'users'
                            ? 'border-b-2 border-orange-500 text-orange-600'
                            : 'text-gray-500 hover:text-gray-700'}`}
                    >
                        Linked User Accounts
                    </button>
                </div>
            </div>

            {activeTab === 'store' && (
                <div className="bg-white border p-6 rounded-lg">
                    <h2 className="text-xl font-semibold mb-6">Store Settings</h2>
                    <form onSubmit={handleStoreSubmit} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Store Name
                            </label>
                            <input
                                type="text"
                                required
                                minLength={3}
                                value={formData.name}
                                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                placeholder="e.g., City Electronics"
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Store Type
                            </label>
                            <select
                                required
                                value={formData.type}
                                onChange={handleTypeChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                            >
                                <option value="">Select a store type</option>
                                {storeTypes.map((type) => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                                <option value="custom">Enter custom type</option>
                            </select>
                        </div>

                        {isCustomType && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Custom Store Type
                                </label>
                                <input
                                    type="text"
                                    required
                                    value={formData.customType}
                                    onChange={(e) => setFormData(prev => ({ ...prev, customType: e.target.value }))}
                                    placeholder="Enter your store type"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                />
                            </div>
                        )}

                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-orange-700 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                            {loading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </form>
                </div>
            )}

            {activeTab === 'users' && (
                <div className="bg-white border p-6 rounded-lg">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold">Link New Store Staff</h2>
                    </div>
                    <div className="mb-6">
                        <div className="flex gap-4">
                            <input
                                type="text"
                                value={activationCode}
                                onChange={(e) => setActivationCode(e.target.value)}
                                placeholder="Enter Activation Code"
                                className="flex-1 p-2 border rounded focus:outline-none focus:border-orange-500"
                                disabled={loading}
                            />
                            <button
                                onClick={handleLinkUser}
                                disabled={loading || !activationCode.trim()}
                                className="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700 transition-colors disabled:bg-gray-400"
                            >
                                {loading ? 'Linking...' : 'Link Store Staff Member'}
                            </button>
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="min-w-full">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Email</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {linkedUsers.map((user) => (
                                    <tr key={user.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                                        <td className="px-6 py-4 whitespace-nowrap font-mono text-sm">{user.id}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button
                                                onClick={() => handleUnlinkUser(user.id, user.name)}
                                                className="text-red-600 hover:text-red-800"
                                                disabled={loading}
                                            >
                                                Unlink
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {showPermissions && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-xl max-w-md w-full">
                        <h3 className="text-xl font-semibold text-gray-900 mb-6">Store Keeper Added</h3>

                        <div className="space-y-6">
                            <div>
                                <h4 className="text-lg font-medium text-gray-900 mb-4">Sales Permissions:</h4>
                                <div className="space-y-3">
                                    <div className="flex items-center">
                                        <CheckCircle className="w-5 h-5 text-emerald-500 mr-3 flex-shrink-0" />
                                        <span className="text-gray-700">Make/Process sales transactions</span>
                                    </div>
                                    <div className="flex items-center">
                                        <XCircle className="w-5 h-5 text-red-500 mr-3 flex-shrink-0" />
                                        <span className="text-gray-700">Delete/Cancel sales</span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h4 className="text-lg font-medium text-gray-900 mb-4">Inventory Permissions:</h4>
                                <div className="space-y-3">
                                    <div className="flex items-center">
                                        <XCircle className="w-5 h-5 text-red-500 mr-3 flex-shrink-0" />
                                        <span className="text-gray-700">Add new inventory items</span>
                                    </div>
                                    <div className="flex items-center">
                                        <XCircle className="w-5 h-5 text-red-500 mr-3 flex-shrink-0" />
                                        <span className="text-gray-700">Change inventory item quantity</span>
                                    </div>
                                    <div className="flex items-center">
                                        <XCircle className="w-5 h-5 text-red-500 mr-3 flex-shrink-0" />
                                        <span className="text-gray-700">Change inventory item prices</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 flex justify-end">
                            <button
                                onClick={() => setShowPermissions(false)}
                                className="bg-gray-900 text-white px-6 py-2.5 rounded-lg hover:bg-gray-800 transition-colors duration-200"
                            >
                                Got It
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StoreManagement;