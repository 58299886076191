import React, { useState } from 'react';
import { X, Loader2 } from 'lucide-react';
import { collection, addDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const AddSupplierModal = ({ onClose, onSuccess }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [submitError, setSubmitError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        category: '',
        address: '',
        notes: '',
        status: 'active', // Default status for new suppliers
        createdAt: new Date(),
    });

    const db = getFirestore();
    const auth = getAuth();
    const categories = ['Electronics', 'Clothing', 'Food', 'Stationery', 'Furniture', 'Other'];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (validationErrors[name]) {
            setValidationErrors(prev => ({ ...prev, [name]: null }));
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = 'Supplier name is required';
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required';
        }
        if (!formData.category) {
            errors.category = 'Category is required';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        setIsSubmitting(true);
        setSubmitError(null);

        try {
            const storeId = localStorage.getItem('storeId');
            if (!storeId) {
                throw new Error('Store ID not found');
            }

            // Add the supplier document to Firestore
            const supplierRef = await addDoc(
                collection(db, 'Stores', storeId, 'Suppliers'),
                {
                    ...formData,
                    createdBy: auth.currentUser.uid,
                    createdAt: new Date(),
                    updatedAt: new Date()
                }
            );

            // Initialize supplier stats
            await addDoc(
                collection(db, 'Stores', storeId, 'SupplierStats'),
                {
                    supplierId: supplierRef.id,
                    totalOrders: 0,
                    totalPurchased: 0,
                    lastDelivery: null,
                    createdAt: new Date(),
                    updatedAt: new Date()
                }
            );

            await onSuccess({
                id: supplierRef.id,
                ...formData
            });
            onClose();
        } catch (error) {
            console.error('Error adding supplier:', error);
            setSubmitError(error.message || 'Failed to add supplier. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            onClick={handleBackdropClick}
        >
            <div
                className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-between items-center p-6 border-b">
                    <h2 className="text-2xl font-semibold">Add New Supplier</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-100"
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="p-6">
                    <div className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Supplier Name*
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none
                                        ${validationErrors.name ? 'border-red-500' : 'border-gray-300'}`}
                                />
                                {validationErrors.name && (
                                    <p className="text-red-500 text-sm mt-1">{validationErrors.name}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Phone Number*
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none
                                        ${validationErrors.phone ? 'border-red-500' : 'border-gray-300'}`}
                                    placeholder="+233 XX XXX XXXX"
                                />
                                {validationErrors.phone && (
                                    <p className="text-red-500 text-sm mt-1">{validationErrors.phone}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Category*
                                </label>
                                <select
                                    name="category"
                                    value={formData.category}
                                    onChange={handleInputChange}
                                    className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none bg-white
                                        ${validationErrors.category ? 'border-red-500' : 'border-gray-300'}`}
                                >
                                    <option value="">Select a category</option>
                                    {categories.map(category => (
                                        <option key={category} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                                {validationErrors.category && (
                                    <p className="text-red-500 text-sm mt-1">{validationErrors.category}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Additional Notes
                            </label>
                            <textarea
                                name="notes"
                                value={formData.notes}
                                onChange={handleInputChange}
                                rows={3}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none"
                                placeholder="Add any additional notes about the supplier..."
                            />
                        </div>
                    </div>

                    <div className="flex justify-end space-x-4 border-t mt-6 pt-6">
                        {submitError && (
                            <div className="flex-1 text-red-500 text-sm">
                                {submitError}
                            </div>
                        )}
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-6 py-2 border border-gray-300 rounded-lg text-gray-600 hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-200"
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-200 disabled:bg-blue-300"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <span className="flex items-center">
                                    <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                                    Saving...
                                </span>
                            ) : (
                                'Add Supplier'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSupplierModal;